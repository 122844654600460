import React, { useEffect, useState } from "react";
import ReactFlow, {
  Controls,
  ReactFlowProps,
  useZoomPanHelper,
  isNode,
} from "react-flow-renderer";
import styled from "styled-components";
import type {
  ReactFlowNode,
  Elements,
} from "../types";
import { SearchMatchCtx } from "../SearchMatchCtx";
import { nodeTypes, SelectedSearchNodeClsnm } from "../__nodeTypes";
import { useGraphVisualisation } from "@components/GraphVisualisation/hooks/useGraphVisualisation";
import find from "lodash/find";
import useEvent from "react-use-event-hook";
import GraphNode from "../components/GraphNode";
import GraphPallet from "../components/GraphPallet";
import { RuleGraphFullReleaseProps } from "../index";
import { LayoutIcon } from "@icons";
import { isMac } from "@components/kdb";

// -- styled components

const StyledControls = styled(Controls)`
  bottom: 10px;
  top: inherit;
`;

const Wrap = styled.div`
  flex-grow: 1;

  .${SelectedSearchNodeClsnm} {
    /* background-color: indianred; */
    outline: 2px solid indianred;
    /* border: radius: 5px; */
  }
`;

const RelayoutBtnContainer = styled.div`
  position: absolute;
  bottom: 118px;
  left: 9px;
  z-index: 100;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 4px;
  padding-left: 6px;
  background-color: white;
  svg {
    padding-top: 2px;
    padding-left: 2px;
    width: 20px;
    height: 20px;
  }
`;

// -- types

type RuleGraphFullReleaseCoreProps = RuleGraphFullReleaseProps & {
  elements: Elements;
  relayoutGraph?: () => void;
  onRepositionNode?: (nodeId: string, x: number, y: number) => void;
};

// -- component

const RuleGraphFullReleaseCore = (props: RuleGraphFullReleaseCoreProps) => {

  const {
    //rawGraph,
    refitKey,
    onNodeClick,
    onNodeContextClick,
    onNodeCtrlClick,
    //showGoalToolbar,
    //datesFilter,
    //setDatesFilter,
    elements,
    relayoutGraph,
    onRepositionNode,
  } = props;
  const {
    selectedNode,
    selectedNodeId,
    graphVizType,
  } = useGraphVisualisation();
  const elementsRef = React.useRef<typeof elements>(elements);
  const ignoreNextClick = React.useRef<boolean>(false);
  if (elementsRef.current !== elements) {
    elementsRef.current = elements;
  }
  const { setCenter, fitView } = useZoomPanHelper();
  const fitViewRef = React.useRef(fitView);
  if (fitViewRef.current !== fitView) {
    fitViewRef.current = fitView;
  }
  let nodeComponents = nodeTypes; // i.e. react component used to render each graph node
  if (graphVizType === "classic-beta") {
    nodeComponents = {
      ...(nodeTypes as any),
      intermediate: GraphNode,
      goal: GraphNode,
      source: GraphNode,
      byValue: GraphNode, // used on the debugger view when nodes have a value
    };
  }

  // -- graph navigation

  const centerSelected = useEvent(() => {
    if (!selectedNodeId) return;
    // @ts-ignore
    let element = find(elementsRef.current, ['id', selectedNodeId]);
    console.log("selected", elementsRef.current, selectedNode, element);
    if (!element) return;
    // @ts-ignore
    const x = element.position.x + element.data.width / 2;
    // @ts-ignore
    const y = element.position.y + element.data.height / 2;
    setCenter(x, y, 1, 20);
  });

  useEffect(() => {
    // Find this node in the graph and zoom to it
    if (!selectedNodeId) return;

    // @ts-ignore
    let element = find(elementsRef.current, ['id', selectedNodeId]);
    console.log(selectedNodeId, element);
    if (!element) return;
    // @ts-ignore
    const x = element.position.x + element.data.width / 2;
    // @ts-ignore
    const y = element.position.y + element.data.height / 2;
    setCenter(x, y, 1);
  }, [selectedNodeId, refitKey])

  // graph changed (i.e. we re-run compact mode) -> fit view
  React.useLayoutEffect(() => {
    setTimeout(() => {
      fitViewRef.current();
      centerSelected();
    }, 20)

  }, [refitKey]);

  // -- graph search

  const [idMatchedBySearch, setIdMatchedBySearchRaw] = React.useState("");
  const idMatchedBySearchRef = React.useRef(idMatchedBySearch);
  if (idMatchedBySearchRef.current !== idMatchedBySearch) {
    idMatchedBySearchRef.current = idMatchedBySearch;
  }

  // -- interaction handlers

  const onNodeClickHandler = React.useCallback<NonNullable<ReactFlowProps["onElementClick"]>>((_, elementRaw) => {

    if (!isNode(elementRaw)) return;

    const targetId = (_?.target as any).id || "";
    const parentId = (_?.target as any)?.parentElement?.id || "";
    const parentParentId = (_?.target as any)?.parentElement?.parentElement?.id || "";
    const srcDomId = targetId || parentId || parentParentId;
    const isClickOnIgnoreEle = srcDomId.includes("graph_ignoreclick_");
    if (isClickOnIgnoreEle) {
      ignoreNextClick.current = true;
      return;
    }

    if (ignoreNextClick.current) {
      ignoreNextClick.current = false;
      return;
    }

    const element = elementRaw as ReactFlowNode;
    const isModKeyPressed = isMac() ? _.metaKey : _.ctrlKey;

    if ( isModKeyPressed && onNodeCtrlClick) {
      onNodeCtrlClick(element.data.node.path || element.data.node.id);
    } else {
      onNodeClick(element.data.node.path || element.data.node.id, element.data.node);
    }
  }, [onNodeClick, setCenter, onNodeContextClick, onNodeCtrlClick]);

  // -- rendering

  const renderRelayoutButton = () => {

    if (!relayoutGraph) {
      return (null);
    }

    return (
      <RelayoutBtnContainer
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          relayoutGraph();
        }}
      >
        <LayoutIcon />
      </RelayoutBtnContainer>
    );
  };

  return (
    <SearchMatchCtx.Provider value={idMatchedBySearch}>
      <Wrap>
        <ReactFlow
          style={{width: "100%"}}
          nodesConnectable={false}
          minZoom={0.01}
          nodesDraggable={true}
          elements={elements}
          nodeTypes={nodeComponents as any}
          onElementClick={onNodeClickHandler}
          snapToGrid={false}
          onNodeDragStop={onRepositionNode ? (e, p) => {
            if (p && p.position && p.id) {
              onRepositionNode(p.id, p.position.x, p.position.y);
            }
          } : undefined}
        >
          <StyledControls />
          <GraphPallet />
          {renderRelayoutButton()}
        </ReactFlow>
      </Wrap>
    </SearchMatchCtx.Provider>
  );
};
RuleGraphFullReleaseCore.displayName = "components/RuleGraphFullRelease/Core";

export default (RuleGraphFullReleaseCore);


// ---------------------------------

// const getElsMatchingSearch = (search: string, els: Array<ReactFlowNode | ReactFlowEdge>): typeof els => {
//   if (search === "") return [];

//   const regex = new RegExp(escapeRegExp(search), "i");

//   return els.filter(el => Boolean(el.data.label.match(regex)));
// };

// const [matchedCount, setMatchedCount] = React.useState(0);
// const [search, setSearchRaw] = React.useState("");

// ===================================================================================

/*const parsedGraph = React.useMemo(() => (
  parseRawGraph(rawGraph, datesFilter)
), [ rawGraph, datesFilter ]);
*/

// ===================================================================================

/*const setIdMatchedAndCenter: (n: ReactFlowNode | ReactFlowEdge | null) => unknown = React.useCallback(n => {
  setIdMatchedBySearchRaw(n === null ? "" : n.id);
  if (n !== null) onNodeClick(n.data.node);

  if (n === null || !isNode(n)) return;

  setTimeout(() => {
    const x = n.position.x + n.data.width / 2;
    const y = n.position.y + n.data.height / 2;
    const zoom = 0.9;
    setCenter(x, y, zoom);
  }, 60);
}, [setCenter, onNodeClick]);
const debouncedSelectFirstMatchingSearch = React.useMemo(
  () => debounce<(search: string) => unknown >(searchInner => {
    const els = getElsMatchingSearch(searchInner, elementsRef.current);
    setMatchedCount(els.length);
    const [first] = els;

    setIdMatchedAndCenter(first || null);
  }, 650),
  [setIdMatchedAndCenter],
);

const setSearch = React.useCallback((v: string, reset?: true) => {
  setSearchRaw(v);

  if (reset) {
    setIdMatchedAndCenter(null);
    setMatchedCount(0);
  } else debouncedSelectFirstMatchingSearch(v);
}, [debouncedSelectFirstMatchingSearch, setIdMatchedAndCenter]);


const centerNextMatch = React.useCallback(() => {
  const els = getElsMatchingSearch(search, elementsRef.current);
  if (els.length === 0) return void setIdMatchedAndCenter(null);

  const idMatchedBySearchInner = idMatchedBySearchRef.current;
  const nextMatch = els.reduce< null | typeof els[ 0 ] >(
    (a, it, i, arr) => {
      if (a !== null) return a;

      return (it.id === idMatchedBySearchInner) && arr[ i + 1 ] || a;
    },
    null,
  );

  setIdMatchedAndCenter(nextMatch || els[ 0 ]);
}, [search, setIdMatchedAndCenter]);
*/

// ===================================================================================

/*const datesFilterMinMax = React.useMemo(() => (
  DatesFilterNS.deriveMinMaxFromNodeValues((rawGraph.nodes || []).reduce(
    (a, n) => a.concat(n.value.derived as any),
    [],
  ))
), [rawGraph]);
const hideDatesFilterControl = React.useMemo(() => {
  const someValueIsTemporal = (rawGraph.nodes || []).some(it => isTemporal((it as any)?.value?.derived));

  return someValueIsTemporal === false || undefined;
}, [rawGraph]);
 */

/* Now at a higher level - leaving here to check if I need anything from it
<GraphToolbar
  {...{
    graph: parsedGraph,
    showGoals: showGoalToolbar,
    onNodeClick,
    searchReactNode: <SearchBar
      {...{
        search,
        setSearch,
        matchedElementsCount: matchedCount,
        centerNextMatch,
      }}
    />,
    datesFilterReactNode: <DatesFilterNS._
      {...{
        set: setDatesFilter || console.log,
        value: datesFilter,
        hide: hideDatesFilterControl,
        minMax: datesFilterMinMax,
      }}
    />,
  }}
/>*/
