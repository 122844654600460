import React from "react";
import { graphlib } from "dagre";
import get from "lodash/get";
import { CaseViewContact, CaseViewContactProps } from '@components/CaseViewContacts'
import styled from "styled-components";
import { EmptyContent, scrollableMixin } from "@common";
import { GraphNode } from "@packages/commons";
import { ContactServiceItemT, contactsService } from "services";
import { useParsedGraph } from "@pages/models/release/GraphContext";

export type ExtractContactsDataArg = {
  session: any;
  fullGraph: graphlib.Graph;
};

export const extractContactsData = (arg: ExtractContactsDataArg): CaseViewContactProps[] => {
  const { fullGraph, session } = arg;

  const contactIdsRaw = get(session, 'contacts', []);
  const contactIds = Array.isArray(contactIdsRaw) && contactIdsRaw.every(it => typeof it === 'string')
    ? contactIdsRaw
    : [];

  const contactPathsRaw = get(session, 'contactPaths', {});
  const contactPaths = typeof contactPathsRaw === 'object' && contactPathsRaw !== null
    ? contactPathsRaw
    : {};

  const nodes = fullGraph.nodes().map(id => fullGraph.node(id)) as unknown as GraphNode[];

  const contactProps = contactIds.reduce<CaseViewContactProps[]>(
    (a, id) => {
      const maybePath = contactPaths[id];

      if (maybePath) a.push({
        id,
        name: id,
        data: nodes
          .filter(it => it.path && it.path.indexOf(maybePath) === 0 && !it.hidden && !it.identifier)
          .map(it => ({ id: it.id, label: String(it.description), value: it.value || it.input })),
        otherData: [],
        otherCases: { perPage: 0, total: 0 },
      });

      return a;
    },
    [],
  );

  return contactProps;
};

/** for background and padding that won't disappear when we scroll */
const Wrap = styled.div`
  display: flex;
  padding: 0.75rem 0;
  background-color: #FBFBFB;
  flex-grow: 1;
  overflow: auto;
`;

const Holder = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  padding: 0 1.75rem;
  flex-grow: 1;
  align-items: flex-start;

  ${scrollableMixin};

  >* {
    width: calc(50% - 1.25rem/2);
  }
`;

type OterhDataState = (
  | { type: 'empty' }
  | { type: 'loading' }
  | { type: 'loaded'; contacts: ContactServiceItemT[]; }
);

const mergeWithOtherData = (
  contacts: CaseViewContactProps[],
  contactsBE: ContactServiceItemT[]
): typeof contacts => contacts.map(it => {
  const matchedBEItem = contactsBE.find(inner => inner.id === it.id);
  if (matchedBEItem === undefined) return it;

  const alreadyPresentKeys = it.data.reduce<Record<string, true>>(
    (a, it) => {
      a[it.label] = true;

      return a;
    },
    {}
  );

  const otherDataKeys = Object.keys(matchedBEItem).filter(key => alreadyPresentKeys[key] !== true);

  return {
    ...it,
    otherData: otherDataKeys.map(key => ({
      id: key,
      label: key,
      value: matchedBEItem[key] as CaseViewContactProps['otherData'][0]['value'],
    })),
  };
});

const useContacts = (session: any) => {
  const graph = useParsedGraph();
  const contacts = extractContactsData({ fullGraph: graph as any, session });
  return contacts;
};

export type ContactsPanelProps = {
  session: any;
};

export const ContactsPanel: React.FC<ContactsPanelProps> = ({ session }) => {
  const contacts = useContacts(session);
  const [otherData, setOtherData] = React.useState<OterhDataState>({ type: 'empty' });
  const ids = contacts.map(it => it.id).sort();

  React.useEffect(() => {
    (async () => {
      // no ids - no need to query BE
      if (ids.length < 1) return;

      setOtherData({ type: 'loading' });
      /**
       * we have at least one id here
       * We want to utilize .getListservice method to get all contacts in one go,\
       * but there is a small trick we need to do: if there is only one id - we \
       * need to send it twice, as otherwise BE rverts to GET and that returns a\
       * single element instaed of an array
       */
      const finalIdsArr = ids.length > 1 ? ids : ids.concat(ids);

      const { data } = await contactsService.getList({ id: finalIdsArr });

      setOtherData({ type: "loaded", contacts: data });
    })();
  }, ids);

  const mergedWithOtherData = React.useMemo(() => {
    if (otherData.type !== 'loaded') return contacts;

    return mergeWithOtherData(contacts, otherData.contacts);
  }, [contacts, otherData])

  console.log("contacts", mergedWithOtherData);

  return (
    <Wrap>
      <Holder>
        {
          contacts.length === 0
            ? <EmptyContent messages={['No contacts']} />
            : (
              mergedWithOtherData.map(
                it => <CaseViewContact key={it.id} otherDataLoading={otherData.type === 'loading'} {...it} />
              )
            )
        }
      </Holder>
    </Wrap>
  );
}
