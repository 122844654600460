import { QueryClientProvider } from "react-query";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ErrorBoundary } from "react-error-boundary";
import smoothscroll from "smoothscroll-polyfill";
import { ImminentlyCore, RouteManager } from "@imminently/imminently_platform";
import { PersistGate } from "redux-persist/integration/react";

import DecisivelyLayout from "./layouts/DecisivelyLayout";
import { Modal } from "./layouts/Modal";
import { AsyncLayout } from "layouts/AsyncRoute";
import CenteredLayout from "layouts/CenteredLayout";
import ErrorPage from "@pages/Error";
import { ImmiNotifications } from "@common/notifications";

// global imports
import routes from "routes";
import theme from "theme";
import { Styling } from "styling";
import { config } from "config";
import { global } from "global";
import { globalStore } from "store";
import { authProvider, dataProvider, i18nProvider } from "providers";
import { LoadingDots } from "@icons";
import { ThemeProvider } from "@emotion/react";
import { EncloseSetValueFromTestCasesCtxComp, WithTestCasesRootCtx } from "@components/TestCasesTree/RootCtx";
import { ToastContainer } from "react-toastify";
import { initErrorReporting } from "./ErrorReporting";
import { ModalProvider, ModalRenderer } from "@modals/context";

const setFavicon = (src) => {
  var link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = src;
};

const setTitle = (title) => {
  document.title = title;
};

// kick off the polyfill!
smoothscroll.polyfill();

if (config.APP_TITLE) {
  setTitle(config.APP_TITLE);
}
if (config.APP_FAVICON) {
  setFavicon(config.APP_FAVICON);
}

const layouts = {
  decisively: <DecisivelyLayout />,
  async: <AsyncLayout />,
  centered: <CenteredLayout />,
};

export const AppProviders = ({ children }) => {
  return (
    <QueryClientProvider client={global.queryClient}>
      <DndProvider backend={HTML5Backend}>
        <WithTestCasesRootCtx>
          <ModalProvider>
            <ImminentlyCore
              store={globalStore.store}
              history={globalStore.history}
              config={config ?? { dataProvider, authProvider }}
              theme={theme}
              i18nProvider={i18nProvider}
              modal={<Modal />}
              notification={<ImmiNotifications />}
              styling={Styling}
            >
              <ErrorBoundary FallbackComponent={ErrorPage}>
                <ThemeProvider theme={theme}>
                  <PersistGate
                    loading={<LoadingDots />}
                    persistor={globalStore.persistor}
                  >
                    {children}
                  </PersistGate>
                  <ToastContainer position="bottom-right" />
                  <ModalRenderer />
                </ThemeProvider>
                <EncloseSetValueFromTestCasesCtxComp />
              </ErrorBoundary>
            </ImminentlyCore>
          </ModalProvider>
        </WithTestCasesRootCtx>
      </DndProvider>
    </QueryClientProvider>
  );
};

export const App = () => {
  return (
    <AppProviders>
      <RouteManager
        forceLogin
        routes={routes}
        layouts={layouts}
        unauthenticatedDefault="/signin"
      />
    </AppProviders>
  );
};
