import { produce } from 'immer';

import type { Modal, ModalContent } from './types';

export const holder = new class {
  __modals: Modal[] = [];

  get modals() {
    return this.__modals;
  }

  getTopMostModal = (ms: Modal[]): null | Modal => {
    return ms[ms.length - 1] || null;
  }

  pushModal(m: Modal) {
    this.__modals = this.__modals.concat(m);
  }

  popModal() {
    this.__modals = this.__modals.slice(0, -1);
  }
  // TODO: Update Types
  pushContents(c: ModalContent) {
    this.__modals = produce(this.__modals, d => {
      const topMostModal: any = this.getTopMostModal(d);
      if (topMostModal === null) return;

      topMostModal.contents.push(c);
    });
  }
  // TODO: Update Types
  popContents() {
    this.__modals = produce(this.__modals, d => {
      const topMostModal: any = this.getTopMostModal(d);
      if (topMostModal === null) return;

      topMostModal.contents.pop();
    });
  }

  setModals(recipe: (s: Modal[]) => typeof s | undefined | void) {
    this.__modals = produce(this.__modals, recipe);
  }
}();
