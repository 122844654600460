/* eslint-disable camelcase */

import { getNodeValue } from "@common";
import dagre from "dagre";
import produce from "immer";
import find from "lodash/find";
import groupBy from "lodash/groupBy";
import set from "lodash/set";
import moment from "moment";
import { v4 as uuid } from "uuid";

// ===================================================================================
// @deprecated
const getExample = (node_type) => {
  switch (node_type) {
    case "boolean":
      return true;
    case "text":
      return "Text";
    case "date":
      return moment().format("YYYY-MM-DD");
    case "timeofday":
      return moment().format("HH:mm:ss");
    case "datetime":
      return moment().format("YYYY-MM-DD HH:mm:ss");
    case "number":
    case "currency":
      return Math.ceil(Math.random() * 100);
    case "auto":
    case "temporal_number":
      // ^ This is broken
      return "string";
    default:
      console.log("unknown type", node_type);
      return "string";
  }
};

// ===================================================================================

const { graphlib } = dagre;
if (graphlib === undefined) {
  throw new Error("Graphlib is undefined here");
}

// ===================================================================================

/**
  @typedef NodeType
  @type {
    | 'boolean'
    | 'text'
    | 'date'
    | 'timeofday'
    | 'datetime'
    | 'number'
    | 'currency'
    | 'auto'
    | 'temporal_number'
  }
 */

/**
  @typedef Node
  @type {{ type: NodeType; description?: string; id: string }}
*/

/** @type { (node: any, graph: any, seen: string[] ) => Node[] } */
export const gatherInputs = (node, graph, seen = []) => {
  if (!seen || !node || !graph || seen.indexOf(node.id) !== -1) return [];

  seen.push(node.id); // To prevent rule loops
  const children = graph.successors(node.id);
  if (!Array.isArray(children) || children.length === 0) {
    return node.value || node.conditions || node.rows ? [] : [node];
  }

  return children.reduce((a, cId) => a.concat(gatherInputs(graph.node(cId), graph, seen)), []);
};

/**
 * @typedef DebugPayload
 * @type {{ mode: 'batch'; data: { [ key: string ]: any[] } }}
 */

/**
 *
 * @returns {DebugPayload | {}}
 */
export const generatePayload = (goal, useDesc, release, rawGraph, useChildren = false) => {
  if (!goal || !release) return {};
  let graph;
  try {
    if (rawGraph._nodes)
      graph = rawGraph; // It's already converted
    else graph = graphlib.json.read(rawGraph);
    if (!graph) throw "error";
  } catch (error) {
    return {};
  }
  const root = graph.node(goal);
  if (!root) return {};
  let inputs = [];
  if (useChildren) {
    // We actually build the inputs from the direct children,
    // rather than from the input nodes. We are likely in the graph testing area

    const children = graph.successors(goal);
    if (children) {
      inputs = children
        .map((c) => {
          const n = graph.node(c);
          if (n) return n;
        })
        .filter((x) => x); // Filter in the unlikely event a node doesn't generate properly
    }
  } else {
    // Find all the input nodes for this goal
    const seen = [];
    const nodeLoop = (node) => {
      if (seen.indexOf(node.id) > -1) return;

      seen.push(node.id);

      const children = graph.successors(node.id);
      if (children && children.length > 0) {
        for (const c of children) {
          const child = graph.node(c);
          if (child) nodeLoop(child);
        }
      } else {
        // No children - I'm likely an input
        // TODO: edge case for predefined nodes
        if (node.value || node.conditions || node.rows) return;

        inputs.push(node);
      }
    };
    nodeLoop(root);
  }

  // let grouped = groupBy(inputs, 'entity')
  const request = {
    mode: "batch",
    data: {},
  };
  const added = [];
  const recurseToGlobal = (entity, rel, incomplete = []) => {
    if (rel.source === "global") {
      if (added.indexOf(rel.name) === -1) {
        // We haven't added this rel yet
        request.data[rel.name] = [
          {
            // '@id': uuid(),
            "@id": "1",
          },
        ];
        added.push(rel.name);
      }

      let path = `data.${rel.name}.0.`;
      for (let i = incomplete.length - 1; i >= 0; i--) {
        if (added.indexOf(incomplete[i]) === -1) {
          // We haven't added this yet
          set(request, `${path}${incomplete[i]}`, [
            {
              // '@id': uuid(),
              "@id": "1",
            },
          ]);
          added.push(incomplete[i]);
        }

        path = `${path}${incomplete[i]}.0.`;
        // get
      }

      if (added.indexOf(entity) === -1) {
        set(request, `${path}${entity}`, [
          {
            // '@id': uuid(),
            "@id": "1",
          },
        ]);
        added.push(entity);
      }
      if (rel.name !== entity) path = `${path}${entity}.0.`;

      return path;
    }

    // I'm at a sub-entity
    const parent = find(release.relationships, { target: rel.source });
    // error
    if (!parent) return false;
    if (entity !== rel.name) incomplete.push(rel.name);

    return recurseToGlobal(entity, parent, incomplete);
  };
  const getPathToGlobal = (node) => {
    if (node.entity === "global") return "";

    const rel = find(release.relationships, { name: node.entity });
    // error - can't find relationship
    if (!rel) return false;

    const path = recurseToGlobal(node.entity, rel, []);
    return path;
  };
  for (const node of inputs) {
    //console.log('processing input', node)
    const id = useDesc ? node.description : node.id;
    const val = getNodeValue(node);
    const example = typeof val === "undefined" ? null : val; //getExample(node.type);
    if (node.entity === "global") {
      set(request, ["data", id], example);
    } else {
      // Connected to an entity
      const path = getPathToGlobal(node);
      //console.log('entity', path, id, example)
      set(
        request,
        String(path)
          .split(".")
          .filter((it) => it !== "")
          .concat(id),
        example,
      );
    }
  }
  //console.log('req', request)
  return request;
};

/** @typedef { import( '@decisively-io/interview-sdk/dist/controls' ).Control } Control */

/** @typedef { import( '@decisively-io/interview-react-material' ).types.IEntity } IEntity */
/** @typedef { import( '@decisively-io/interview-react-material' ).types.NonNestedControl } NonNestedControl */

/** @type { ( input: Node ) => NonNestedControl } */
const inputToNonNestedControl = (it) => {
  const common = {
    id: uuid(),
    attribute: it.id,
    label: it.description,
  };

  /** @type { Control } */
  const control = (() => {
    switch (it.type) {
      case "boolean":
        return { type: "boolean", ...common };
      case "currency":
        return { type: "currency", ...common };
      case "date":
        return { type: "date", ...common };
      case "datetime":
        return { type: "datetime", ...common };
      case "timeofday":
        return { type: "time", ...common };
      case "number":
        return { type: "text", variation: { type: "number" }, ...common };
      default:
        return { type: "text", ...common };
    }
  })();

  return control;
};

/** @type { ( goalId: string, release: any, rawGraph: any ) => Control[] } */
export const generateTemplates = (goalId, release, rawGraph) => {
  let graph;
  try {
    graph = graphlib.json.read(rawGraph);
  } catch (error) {
    return [];
  }
  const rootN = graph.node(goalId);

  // Find all the input nodes for this goal
  const inputsWithRepeats = rootN ? gatherInputs(rootN, graph) : [];

  const inputIdToInput = produce(/** @type {{ [ inputId: string ]: Node }} */ ({}), (draft) => {
    for (const input of inputsWithRepeats) {
      // eslint-disable-next-line no-param-reassign
      draft[input.id] = input;
    }
    return draft;
  });
  const inputs = Object.values(inputIdToInput);

  const inputsByEntity = groupBy(inputs, "entity");
  const entityNames = Object.keys(inputsByEntity);

  const entityControls = entityNames.reduce(
    (a, entityName) => {
      const inputsForEntity = inputsByEntity[entityName];

      /** @type { IEntity } */
      const control = {
        entity: entityName,
        id: uuid(),
        type: "entity",
        label: entityName,
        template: inputsForEntity.map((it) => inputToNonNestedControl(it)),
      };

      return a.concat(control);
    },
    /** @type { IEntity[] } */ ([]),
  );

  const controls = entityControls.reduce(
    (a, it) => a.concat(it.entity === "global" ? it.template : [it]),
    /** @type { Control[] } */ ([]),
  );

  return controls;
};
