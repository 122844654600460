// Core modal file using custom modal package (@modals/context)
// contains core useful components
import { PropsWithChildren, ReactNode, useState, createContext, useContext, useMemo } from "react";
import { ModalProps, useModal, createUseModal } from "@modals/context";
import { Box, BoxProps, Dialog, DialogContent as MuiDialogContent, DialogContentProps, DialogProps, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Flex } from "@components/meta";
import { CloseIcon, EnlargeIcon, ShrinkIcon } from "@icons";
import { ParsedGraphContextProvider } from "@pages/models/release/GraphContext";
import styled from "styled-components";
// import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle } from "@components/radix/Dialog/Dialog";

// copy of components from @imminently/react/ui/core/dialog backported into mui 4

export const DialogTitle: React.FC<BoxProps> = styled(Box)({
  padding: "2rem 2rem 0 2rem",
  display: "flex",
  flexFlow: "row nowrap",
  alignItems: "center",
  justifyContent: "space-between",
});

export const DialogContent: React.FC<DialogContentProps> = styled(MuiDialogContent)({
  padding: "0 2rem",
});

export const DialogClose = () => {
  const { close } = useModalContext();
  return (
    <Tooltip title="Close" placement="left">
      <IconButton aria-label='close' onClick={close}>
        <CloseIcon />
      </IconButton>
    </Tooltip>
  );
};

export const DialogResize = () => {
  const { fullScreen, setFullScreen } = useModalContext();
  const handleExpand = () => setFullScreen(!fullScreen);
  return (
    <Tooltip title={fullScreen ? "Shrink" : "Expand"} placement='left'>
      <IconButton aria-label='expand' onClick={handleExpand}>
        {fullScreen ? <ShrinkIcon /> : <EnlargeIcon />}
      </IconButton>
    </Tooltip>
  );
};

export interface ModalData {
  title?: ReactNode;
  /** A complete header override component */
  header?: ReactNode;
  allowResize: boolean;
  maxWidth: "xs" | "sm" | "md" | "lg" | "xl" | false;
  fullScreen: boolean;
};

const defaultModalData: ModalData = {
  allowResize: true,
  maxWidth: "sm",
  fullScreen: false,
};

const useModalDataContext = (data: Partial<ModalData>) => {
  const { popModal: close } = useModal();
  const [context, setContext] = useState<ModalData>(() => ({
    ...defaultModalData,
    ...data
  }));

  return useMemo(() => ({
    ...context,
    close,
    setFullScreen: (fullScreen: boolean) => setContext({ ...context, fullScreen }),
    setTitle: (title: ReactNode) => setContext({ ...context, title }),
    setHeader: (header: ReactNode) => setContext({ ...context, header }),
    setAllowResize: (allowResize: boolean) => setContext({ ...context, allowResize }),
    setMaxWidth: (maxWidth: "xs" | "sm" | "md" | "lg" | "xl" | false) => setContext({ ...context, maxWidth }),
  }), [context]);
};

type ModalDataContext = ReturnType<typeof useModalDataContext>;

const ModalContext = createContext<ModalDataContext>(undefined as unknown as ModalDataContext);

export const ModalProvider = ({ children, data }: { children: ReactNode; data: Partial<ModalData> }) => {
  const context = useModalDataContext(data);

  return (
    <ModalContext.Provider value={context}>
      <ParsedGraphContextProvider>
        {children}
      </ParsedGraphContextProvider>
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const value = useContext(ModalContext);
  if (!value) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }
  return value;
};

export type BasicModalProps = PropsWithChildren & Partial<ModalData> & {
  dialog?: Omit<DialogProps, 'open' | 'onClose' | 'maxWidth' | 'fullScreen'>;
};

export const BasicModal = ({ props }: ModalProps<BasicModalProps>) => {
  const { children, dialog = {}, ...data } = props;
  return (
    <ModalProvider data={data}>
      <ModalContext.Consumer>
        {
          ({ title, header, allowResize, maxWidth, fullScreen }) => (
            <Dialog {...dialog} open onClose={close} maxWidth={fullScreen ? false : (maxWidth ?? "sm")} fullScreen={fullScreen}>
              {
                header !== undefined
                  ? header
                  : (
                    <DialogTitle>
                      {typeof title === "string" ? <Typography variant="h2">{title}</Typography> : title}
                      <Flex>
                        {allowResize ? <DialogResize /> : null}
                        <DialogClose />
                      </Flex>
                    </DialogTitle>
                  )
              }
              <DialogContent style={{ padding: 0 }}>
                {children}
              </DialogContent>
            </Dialog>
          )
        }
      </ModalContext.Consumer>
    </ModalProvider >
  );
};

// TODO not working :'(
// export const RadixBasicModal = ({ props }: ModalProps<BasicModalProps>) => {
//   const { popModal: close } = useModal();
//   const [fullScreen, setFullScreen] = useState(false);
//   const { title, header, allowResize, maxWidth, children } = props;

//   const onOpenChange = (open: boolean) => {
//     if (!open) {
//       close();
//     }
//   };

//   return (
//     <Dialog open onOpenChange={onOpenChange}>
//       <DialogContent>
//         {
//           // header !== undefined
//           //   ? header
//           //   : (
//           <DialogHeader style={{ padding: "1rem 1rem 0 1rem" }}>
//             <DialogTitle>
//               {typeof title === "string" ? <Text variant="h4">{title}</Text> : title}
//               {/* title */}
//             </DialogTitle>
//             <Flex>
//               {/* {
//                 allowResize
//                   ? <ModalResizeButton fullScreen={fullScreen} setFullScreen={setFullScreen} />
//                   : null
//               } */}
//               {/* <ModalCloseButton /> */}
//               <DialogClose />
//             </Flex>
//           </DialogHeader>
//           // )
//         }
//         {children}
//       </DialogContent>
//     </Dialog>
//   );
// };

export const useBasicModal = createUseModal(BasicModal);



