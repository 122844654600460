import React, { useState,  } from 'react';
import { SigmaContainer, useLoadGraph, useSigma } from "@react-sigma/core";
import { graphlib } from 'dagre';
import GraphController from './GraphController';
import { useRelease } from '@pages/models/release/ReleaseContext';
import randomColor from 'randomcolor';
import styled from "styled-components";
import FilterPanel from './FilterPanel';
import { omit } from 'lodash';

const StyledSigmaContainer = styled(SigmaContainer)`
  height: 100%;
  width: 100%;
  .sigma-container {
    height: 100%;
    width: 100%;
  }
`;

const Panels = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 350px;
  max-height: calc(100vh - 2 * var(--stage-padding));
  overflow-y: auto;
  padding: var(--stage-padding);
  scrollbar-width: thin;
`


const RuleGraphSigma = ({ rawGraph, onNodeClick, focusNode, onDebugScreen }) => {
  const graph = graphlib.json.read(rawGraph);
  const release = useRelease();

  const [filtersState, setFiltersState] = useState({
    entities: {},
    //tags: {},
    documents: {}
  });
  const [dataReady, setDataReady] = useState(false);

  let entities = {
    global: {
      "color": randomColor({ seed: 'global'}),
      "entity": 'global',
      entityName: 'Global'
    }
  }
  if (!rawGraph || !release) return null;
  (release.relationships || []).forEach(rel => {
    entities[rel.name] = {
      color: randomColor({ seed: rel.name}),
      entity: rel.name,
      entityName: rel.textSingular
    }
  })
  /*
  <FilterPanel title="Tags" nodeKey="tags" filterKey="tags" filters={filtersState} toggle={(tag) => {
              setFiltersState((filters) => ({
                ...filters,
                tags: filters.tags[tag]
                  ? omit(filters.tags, tag)
                  : { ...filters.tags, [tag]: true },
              }));
            }} orphanLabel="Untagged" />*/


  return (<>
    <StyledSigmaContainer>
      <GraphController graph={graph} entities={entities} setDataReady={setDataReady} setFiltersState={setFiltersState} filtersState={filtersState} onNodeClick={onNodeClick} />
      { dataReady &&
        <>
          <Panels>
            {/* <SearchField /> */}
            <FilterPanel title="Entities" nodeKey="entity" filterKey="entities" filters={filtersState} toggle={(entity) => {
              setFiltersState((filters) => ({
                ...filters,
                entities: filters.entities[entity]
                  ? omit(filters.entities, entity)
                  : { ...filters.entities, [entity]: true },
              }));
            }} />
            <FilterPanel title="Documents" nodeKey="document" filterKey="documents" filters={filtersState} toggle={(doc) => {
              setFiltersState((filters) => ({
                ...filters,
                documents: filters.documents[doc]
                  ? omit(filters.documents, doc)
                  : { ...filters.documents, [doc]: true },
              }));
            }} orphanLabel="Input Attributes" />

          </Panels>
        </>
      }
    </StyledSigmaContainer>
  </>);
}

export default RuleGraphSigma;