import { useMemo } from "react";
import RuleGraphProvider from "@components/GraphVisualisation/providers/RuleGraphProvider";
import MainExplanation from "@components/GraphVisualisation/components/AttributeInfo/AttributeExplanation";
import { useFullReleaseStrict } from "@common/hooks_useFullRelease";
import { GraphNode, ParsedRuleGraph } from "@packages/commons";
import { parseRawGraph } from "@components/RuleGraph_FullRelease/__parseRawGraph";

/** A basic wrapper which auto provides the required contexts */
export const AttributeExplanation = ({ node }: { node: GraphNode }) => {
  const release = useFullReleaseStrict();

  const graph = useMemo<ParsedRuleGraph>(() => parseRawGraph(release?.rule_graph), [release?.rule_graph]);

  return (
    <RuleGraphProvider graph={graph}>
      <MainExplanation attr={node} />
    </RuleGraphProvider>
  );
};