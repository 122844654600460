import styled from "styled-components"
import { DataLabel, DataValue } from './Texts';
import { Node } from "@pages/decisions/DecisionDashboard/Reports/Details/UnifiedTimeline";
import React from "react";


const StyledTable = styled.table`
  width: 100%;
  table-layout: fixed;

  td {
    vertical-align: top;
  }

  td:nth-of-type(1) {
    width: 25%;
  }
  td:nth-of-type(2) {
    padding-left: 2.75rem;
    width: 75%;
  }

  tr:not(:last-of-type) td {
    padding-bottom: 0.25rem;
  }
`;



export type TableProps = {
  className?: string;
  items: Array<{ id: string; label: string; value?: Node[ 'value' ]; }>
}

const processItemValue = ( v: TableProps[ 'items' ][0][ 'value' ] ): React.ReactNode => {
  if(typeof v === 'string' || typeof v === 'number') return String(v);
  if(typeof v === 'boolean') return v ? 'True' : 'False';
  if(v === undefined) return 'Unknown';
  if(v === null) return 'Uncertain';

  console.warn('1uGogJF8P1 | Temporal values are not supported');;
  return null;
}

export const Table: React.FC< TableProps > = p => {
  const { items, className } = p;

  return (
    <StyledTable className={className}>
      <tbody>
        { items.map(it => (
          <tr key={it.id}>
            <td>
              <DataLabel>{it.label}</DataLabel>
            </td>
            <td>
              <DataValue>{processItemValue(it.value)}</DataValue>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}
