/* eslint-disable react/no-unused-prop-types,camelcase */
import React from "react";
import { PrimaryButton, SecondaryButton } from "@components";
import { INTERVIEW_PROVIDER, scrollableMixin } from "@common";
import styled from "styled-components";
import { Controls as InterviewControls } from "@decisively-io/interview-react-material";
import { LoadingDotsJSX } from "@icons";
import { Control, OptionsControl } from "@decisively-io/interview-sdk";
import { RawRuleGraph } from "@packages/commons";

const DefaultFooterWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export type CompactControlsDefaultFooterProps = {
  secondaryAction: {
    label: React.ReactNode;
    onClick: () => unknown;
    hidden?: boolean;
    disabled?: boolean;
  };
  primaryAction: {
    label: React.ReactNode;
    showLoadingDots?: boolean;
  };
}
export const CompactControlsDefaultFooter: React.FC< CompactControlsDefaultFooterProps > = p => {
  const {
    secondaryAction: secondary,
    primaryAction: primary,
  } = p;

  return (
    <DefaultFooterWrap>
      {
        secondary.hidden ? <div /> : (
          <SecondaryButton onClick={secondary.onClick} disabled={secondary.disabled}>
            { secondary.label }
          </SecondaryButton>
        )
      }

      <PrimaryButton disabled={primary.showLoadingDots} type='submit'>
        { primary.showLoadingDots ? LoadingDotsJSX : primary.label}
      </PrimaryButton>
    </DefaultFooterWrap>
  );
};
CompactControlsDefaultFooter.displayName = "components/CompactControls/DefaultFooter";

// ===================================================================================


const Body = styled.div`
  ${ scrollableMixin };
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;

  &.hidden { display: none }

  .MuiFormControl-marginNormal { margin: 0; }
  .MuiInputBase-root { margin-bottom: 0.5rem; }
  .MuiFormHelperText-root { display: none; }
  .MuiTypography-body1 { font-size: 0.875rem; }
  .MuiTypography-h5{ margin-bottom: 0.25rem; }
  .MuiGrid-root{ margin-bottom: 0; }
`;


export const Comps = {
  ">body": Body,
  ">footer": Footer,
};

export interface CompactControlsProps {
  controls: Control[];
  FooterJSX: JSX.Element;
  onSubmit: React.FormEventHandler< HTMLFormElement >;
  className?: string;
}

export const CompactControls: React.FC< CompactControlsProps > = React.memo(p => {
  const { controls, className, FooterJSX, onSubmit } = p;
  return (
    <Form className={className} onSubmit={onSubmit}>
      <Body>
        <InterviewControls interviewProvider={INTERVIEW_PROVIDER} controls={controls} />
      </Body>

      <Footer>{ FooterJSX }</Footer>
    </Form>

  );
});
CompactControls.displayName = "components/CompactControls";


// ===================================================================================


type MergeWithEnumsArg = {
  controls: CompactControlsProps[ "controls" ];
  ruleGraph: RawRuleGraph;
  enums: Array<{ id: string; options: any }>
}

function mergeWithEnums(arg: MergeWithEnumsArg): MergeWithEnumsArg[ "controls" ] {
  const { controls, ruleGraph, enums } = arg;

  if (Boolean(ruleGraph) === false) return controls;

  const mapped = controls.map< typeof controls[ 0 ] >(it => {
    if (it.type === "entity") {
      return {
        ...it,
        template: mergeWithEnums({ controls: it.template, ruleGraph, enums }) as typeof it.template,
      };
    }

    if (it.type !== "text") {
      return it;
    }

    const n = (ruleGraph.nodes as any[]).find(n => n.v === it.attribute);
    if (!n) return it;

    const { enum: enumId } = n.value;
    if (!enumId) return it;

    const maybeEnum = enums.find(it => it.id === enumId);
    if (!maybeEnum) return it;

    const augmentedControl: OptionsControl = {
      type: "options",
      attribute: it.attribute,
      enum_id: enumId,
      id: it.id,
      options: maybeEnum.options,
      label: it.label,
      labelLength: it.labelLength,
      default: it.default,
      value: it.value,
    };

    return augmentedControl;
  });

  return mapped;
}

// ===================================================================================

export type PrepareControlsArg = MergeWithEnumsArg;

export const prepareControls = (arg: PrepareControlsArg): PrepareControlsArg[ "controls" ] => {
  const { enums, ruleGraph } = arg;

  return (
    [arg.controls]
      // labelLength: 75
      .map(controls => controls.map(it => {
        if (it.type === "image" || it.type === "typography") {
          return it;
        }

        if (it.type === "entity") {
          return {
            ...it,
            template: it.template.map(t => {
              if (t.type === "image" || t.type === "typography") return t;

              return { ...t, labelLength: 75 };
            }),
          };
        }

        return { ...it, labelLength: 75 };
      }))
      // merge with enums
      .map(controls => mergeWithEnums({ controls, enums, ruleGraph }))
      // allow manual in IDate
      .map(controls => controls.map(it => {
        if (it.type === "date") {
          return { ...it, allowManual: true as const };
        }
        if (it.type !== "entity") return it;

        return {
          ...it,
          template: it.template.map(t => {
            if (t.type !== "date") return t;

            return { ...t, allowManual: true as const };
          }),
        };
      }))
  )[ 0 ];
};
