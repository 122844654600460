import { Flex, Stack } from "@components";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { AnalyticCard, StatusCircle, SessionTable, TaskList, useFlowTasks } from "./components";
import { hexToHSL, system } from "theme";
import { useTranslation } from "react-i18next";
import { useSessionTaskModal } from "./SessionTaskModal";
import { FlowTask } from "services";
import { useSessionModal } from "./SessionModal";

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border-top: 1px solid ${p => p.theme.palette.divider};
  overflow: auto;
  flex-grow: 1;
  position: relative;

  #active-sessions {
    flex: 2;
  }

  #active-tasks {
    flex: 1;
    min-width: 28rem;
    padding: 1rem;
    gap: 1rem;
    border-left: 1px solid ${p => p.theme.palette.divider};
  }
`;

const TaskStatusLabel = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  border: 1px solid ${p => p.theme.palette.divider};
  background-color: ${props => props.theme.palette.background.default};
  display: flex;
  align-items: center;
  flex: 1;
  gap: 0.5rem;
  flex-flow: row nowrap;

  > span.num {
    font-size: 1.25rem;
    font-weight: 600;
  }

  > .MuiTypography-root {
    flex: 1;
    font-size: 0.875rem; // 14px
  }
`;

const FlowTaskList = () => {
  const { data: tasks, query, total, loadMore } = useFlowTasks();
  const openTaskModal = useSessionTaskModal();
  const { isLoading } = query;
  const canLoad = !query.isError && !query.isFetching && (query.hasNextPage ?? false);
  return (
    <TaskList
      tasks={tasks as FlowTask[]}
      total={total}
      isLoading={isLoading}
      canLoad={canLoad}
      loadMore={loadMore}
      onTaskClick={openTaskModal}
      style={{ flex: 1 }}
    />
  );
};

export const Sessions = () => {
  const { t } = useTranslation();
  const openSessionModal = useSessionModal();

  return (
    <Container>
      <Stack id="active-sessions">
        <Flex style={{ gap: "1rem", padding: "1rem" }}>
          <AnalyticCard title="Active sessions" value={27} data={[0, 3, 1, 5, 7]} />
          <AnalyticCard title="Errors" critical value={4} data={[0, 3, 3, 3, 5, 7]} />
        </Flex>
        <SessionTable onSessionClick={openSessionModal} />
      </Stack>
      <Stack id="active-tasks">
        <Flex style={{ gap: "1rem" }}>
          <TaskStatusLabel>
            <StatusCircle color={hexToHSL(system.purpleSlate, 80)} />
            <span className="num">8</span>
            <Typography>{t('tasks.status.in-progress')}</Typography>
          </TaskStatusLabel>
          <TaskStatusLabel>
            <StatusCircle color={hexToHSL(system.trafficGreen, 50)} />
            <span className="num">4</span>
            <Typography>{t('tasks.status.complete')}</Typography>
          </TaskStatusLabel>
        </Flex>
        <FlowTaskList />
      </Stack>
    </Container>
  );
};
