import type { Item } from "@components/DottedTree/ctx";
import type { LeftProps, Item as LeftItem } from './Left';
import type { Item as RightItem } from './Right';
import type { TwoPaneDottedTreeProps } from './Comp'


const findItemById = (item: Item< unknown >, id: string): typeof item | null => {
  if( item.id === id ) return item;

  return item.children.reduce< typeof item | null  >(
    ( a, child ) => {
      if ( a !== null ) return a;

      return findItemById( child, id );
    },
    null,
  );
}

export type GatherTwoPaneItemsArg = {
  item: Item< unknown >;
  generalItemToRightTreeItem: ( item: GatherTwoPaneItemsArg[ 'item' ] ) => RightItem;
  generalItemToLeftItem: ( item: GatherTwoPaneItemsArg[ 'item' ] ) => LeftItem;
};
export type GatherTwoPaneItemsRtrn = {
  leftItems: LeftProps[ 'items' ];
  idToRightItems: TwoPaneDottedTreeProps[ 'idToRightItems' ];
};

type GatherTwoPaneItemsRecursivelyArg = {
  item: GatherTwoPaneItemsArg[ 'item' ];
  parentId: GatherTwoPaneItemsRecursivelyArg[ 'item' ][ 'id' ] | null;
  acc: GatherTwoPaneItemsRtrn;
  generalItemToRightTreeItem: GatherTwoPaneItemsArg[ 'generalItemToRightTreeItem' ]
  generalItemToLeftItem: GatherTwoPaneItemsArg[ 'generalItemToLeftItem' ]
};
const gatherTwoPaneItemsRecursively = ( arg: GatherTwoPaneItemsRecursivelyArg ): GatherTwoPaneItemsRecursivelyArg[ 'acc' ] => {
  const {
    item,
    parentId,
    acc,
    generalItemToRightTreeItem,
    generalItemToLeftItem,
  } = arg;

  // if this is a leaf item - try to add id to "idToRightItems"
  if( item.children.length === 0 ) {
    /**
     * this shouldn't really happen, as the only time when parentId\
     * should be null is when we jus start from the very root, but\
     * just for completeness we will say that in this case just return\
     * acc
     */
    if( parentId === null ) return acc;

    const items = acc.idToRightItems[ parentId ] || [];
    items.push( generalItemToRightTreeItem( item ) );

    acc.idToRightItems[ parentId ] = items;

    return acc;
  }

  /**
   * this is not a leaf node, so it goes into the left tree,\
   * but if parentId is null, it goes directly at the root level
   */
  if( parentId === null ) {
    acc.leftItems.push( generalItemToLeftItem( item ) );
  } else {
    (() => {
      /**
       * if parentId is not null, and this is not a leaf node (because\
       * if it was we would never get here), then we should find this \
       * item's parent in leftTree nodes and add this item to that parent
       */
      if ( acc.leftItems.length === 0 ) return;

      const parent = findItemById( acc.leftItems[ 0 ], parentId );
      if( parent === null ) return;

      parent.children.push( generalItemToLeftItem( item ) );
    })();
  }

  /**
   * both for root left tree item, or intermediate left tree item we\
   * should recursively run further and try gathering children in\
   * accumulator
   */
  for( const child of item.children ) {
    gatherTwoPaneItemsRecursively({
      item: child,
      acc,
      generalItemToLeftItem,
      generalItemToRightTreeItem,
      parentId: item.id,
    });
  }

  return acc;
}

export const gatherTwoPaneItems = ( arg: GatherTwoPaneItemsArg ): GatherTwoPaneItemsRtrn => {
  const { generalItemToLeftItem, generalItemToRightTreeItem, item } = arg;
  const acc: GatherTwoPaneItemsRtrn = {
    idToRightItems: {},
    leftItems: [],
  };

  return gatherTwoPaneItemsRecursively({
    acc,
    item,
    parentId: null,
    generalItemToLeftItem,
    generalItemToRightTreeItem,
  });
}
