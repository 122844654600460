import React, { useMemo } from 'react';
import { Label } from '@components';
import { useTheme } from '@material-ui/core';
import styled from 'styled-components';
import { roundToMaxDigitsAfterDot } from '@common';
import { TempTimeline } from '@components/TemporalValue/TempTimeline';
import { AttributeValue } from '@common/graph/AttributeValue';
import { GraphNode } from '@packages/commons';

const isTemporal = (value: any) => typeof value === 'object' && value !== null && 'temporal' in value;

const StyledTempTimeline = styled(TempTimeline)`
  height: 1.5rem;
`;

type ReportValueProps = { value: any; temporalWidth?: number; title?: string; };

// TODO replace with this when ready
export const _ReportValue: React.FC<ReportValueProps> = ({ value, temporalWidth, title }) => {
  return <AttributeValue node={{ derived: value } as GraphNode} />;
};

// eslint-disable-next-line react/require-default-props, react/no-unused-prop-types
export const ReportValue: React.FC<ReportValueProps> = p => {
  const { value, temporalWidth, title } = p;
  const theme = useTheme() as any;

  const labelColor = useMemo(() => {
    if(typeof value === 'boolean') {
      return value ? theme.labels.public : theme.labels.Author;
    }

    if(String(value).toLowerCase() === 'fail') {
      return theme.labels.Author;
    }
    
    if (typeof value === 'string' && value !== 'Entity' && value !== 'Undefined' && value !== 'uncertain') {
      return theme.labels.public;
    }

    return theme.palette.secondary.light;
  }, [value, theme]);

  if(isTemporal(value)) {
    return <StyledTempTimeline value={value} width={temporalWidth || undefined} title={title} />;
  }

  try {
    const parsed = JSON.parse(value);

    if(parsed.temporal) {
      return <StyledTempTimeline value={parsed} width={temporalWidth || undefined} title={title} />;
    }
  } catch { /** */ }

  return (
    <Label color={labelColor}>
      {
        typeof value === 'number'
          ? roundToMaxDigitsAfterDot(value)
          : String(value).replace(/^./, c => c.toUpperCase())
      }
    </Label>
  );
};
