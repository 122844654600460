import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const Wrap = styled.div`
  display: flex;
  gap: 0.25rem;
  align-self: flex-start;
  height: 4rem;
  align-items: center;
`;

const ExpandIconButton = styled(IconButton)`
  border: 1px solid #E0DBDB;

  &.expanded {
    border: 1px solid #0A0A0A;
    background-color: #F9F9F9;
  }
`;

export type ControlsProps = {
  className?: string;
  expanded: boolean;
  toggleExpanded: () => unknown;
};

export const Controls: React.FC<ControlsProps> = p => {
  const { expanded, toggleExpanded, className } = p;

  return (
    <Wrap className={className}>
      <ExpandIconButton
        className={expanded ? 'expanded' : undefined}
        onClick={toggleExpanded}
      >
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ExpandIconButton>
    </Wrap>
  );
};