/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import styled from 'styled-components';
import Editor from 'react-simple-code-editor';
// @ts-ignore
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism.css';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { SourcecodeIcon } from '@icons';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import copy from 'copy-to-clipboard';
import FileCopy from '@material-ui/icons/FileCopy';
import { scrollableMixin } from './scrollbar';
import set from 'lodash/set';
import { useTranslation } from 'react-i18next';

const StyledPopover = withStyles(theme => ({
  root: {
    '& .MuiPopover-paper': {
      color: theme.palette.error.main,
      padding: '0.25rem 0.5rem',
    },
  },
}))(Popover);


const Wrap = styled.div`
  position: relative;
  height: 100%;
`;

const InnerWrap = styled.div`
  height: 100%;
  ${ scrollableMixin };

  .editor {
    #codeArea {
      outline: none;
      padding-left: 60px !important;
    }
    pre {
      padding-left: 60px !important;
    }

    .editorLineNumber {
      position: absolute;
      left: 0;
      color: #cccccc;
      text-align: right;
      width: 50px;
      font-weight: 100;
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0.5rem;
  z-index: 1;

  &.hidden { display: none; }
`;


const highlightHandler = (code: any) => highlight(code, languages.json)
  .split('\n')
  .map((line: any, i: number) => `<span class='editorLineNumber'>${ i + 1 }</span>${ line }`)
  .join('\n');


const CopyToCbdBtn: React.FC< { v: string; withBeautify?: boolean } > = (() => {
  const StyledIconBtn = styled(IconButton)`
    position: absolute;
    top: 0;
    right: 0.5rem;
    z-index: 1;

    &.withBeautify { right: 2.5rem; }
  `;

  const CopyToCbdBtn: React.FC< { v: string; withBeautify?: boolean } > = React.memo(({ v, withBeautify }) => {
    const [tooltipActive, setTooltipActive] = React.useState(false);
    const onCopy = React.useCallback(() => {
      copy(v);
      setTooltipActive(true);
    }, [v]);
    const handleTooltipClose = React.useCallback(() => (
      setTooltipActive(false)
    ), []);

    return (
      <Tooltip
        open={tooltipActive}
        title='Copied to clipboard!'
        leaveDelay={800}
        onClose={handleTooltipClose}
      >
        <StyledIconBtn
          onClick={onCopy}
          className={withBeautify ? 'withBeautify' : undefined}
        >
          <FileCopy />
        </StyledIconBtn>
      </Tooltip>
    );
  });
  CopyToCbdBtn.displayName = 'JsonEditorV2/CopyToCbdBtn';

  return CopyToCbdBtn;
})();

export interface JsonEditorV2Props {
  v: string;
  onChange?: (v: string) => unknown;
  className?: string;
  disabled?: boolean;
  withCopy?: true;
  readOnly?: boolean;
  useDescription?: boolean;
  graph?: any;
}

const defaultOnChange = () => { /** */ };


export const JsonEditorV2: React.FC< JsonEditorV2Props > = React.memo(({ v, onChange, className, disabled, withCopy, readOnly, useDescription, graph }) => {
  const ref = React.useRef< any >(null);

  const [error, setError] = React.useState('');
  const [popoverEl, setPopoverEl] = React.useState< any >(null);
  const { t } = useTranslation();
  const setErrAndPop = React.useCallback((v: string) => {
    setError(v);
    setPopoverEl(ref.current);
  }, []);
  const clearError = React.useCallback(() => setError(''), []);

  const beautify = React.useCallback(() => {
    if(!onChange) return;

    try {
      const parsed = JSON.parse(v);
      console.log('change', parsed, v)
      onChange(JSON.stringify(parsed, null, 2));
    } catch (e) {
      console.log('e', e)
      if(!(e instanceof Error)) throw e;

      const position = e.message.match(/position (\d+)/);
      const meaningfulError = e.message.match(/^([\s\S]+) in JSON/);

      if(position === null || meaningfulError === null) {
        setErrAndPop(e.message);

        return;
      }

      const n = Number(position[ 1 ]);
      const errorLineNumber = v.split('').reduce(
        (a, it, i) => (i >= n || it !== '\n' ? a : a + 1),
        1,
      );

      setErrAndPop(`${ meaningfulError[ 1 ] } on line ${ errorLineNumber }`);
    }
  }, [v, onChange, setErrAndPop]);

  if (useDescription && graph) {
    // change this to use the descriptions in the graph instead of GUIDs
    try {
      let output = JSON.parse(v);
      let newData = {};
      const recurse = (attributes, path) => {
        let dupes: any = [];
        for (const a of attributes) {
          if (a[0] === '@id') set(newData, [...path, "@id"], a[1])
          else if (Array.isArray(a[1])) {
            // This is the array of instances for an entity
            a[1].forEach((i, index) => {
              recurse(Object.entries(i), [...path, a[0], index])
            })
          } else {
            let node = graph.node(a[0]);
            if (node) {
              let label = node.description;
              if (dupes.indexOf(node.description) !== -1) {
                // An attribute of this name was already used, this means it's in a name space
                // Use the file path to this attribute instead
                label = `${node.definedIn}/${node.description}`
              } else dupes.push(node.description);

              set(newData, [...path, label], a[1]);
            } else {
              // Keep whatever it was
              set(newData, [...path,a[0]], a[1])
            }
          }
        }
      }
      recurse(Object.entries(output.data), '');
      v = JSON.stringify({data: newData}, null, 2)
    } catch (error) {
      console.log('Error converting value to graph descriptions', error);
      // Do nothing - revert to using the string provided
    }
   }
  return (
    <Wrap className={className}>
      { withCopy && <CopyToCbdBtn v={v} withBeautify={Boolean(onChange)} /> }

      <Tooltip title={t('beautify')} placement='left'>
        <StyledIconButton
          onClick={beautify}
          ref={ref}
          className={onChange === undefined ? 'hidden' : undefined}
        >
          <SourcecodeIcon />
        </StyledIconButton>
      </Tooltip>

      <StyledPopover
        open={Boolean(error)}
        anchorEl={popoverEl}
        onClose={clearError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        { error }
      </StyledPopover>

      <InnerWrap>
        <Editor
          value={v || ''}
          onValueChange={onChange || defaultOnChange}
          highlight={highlightHandler}
          className='editor'
          textareaId='codeArea'
          disabled={disabled}
          readOnly={readOnly}
        />
      </InnerWrap>
    </Wrap>
  );
});
JsonEditorV2.displayName = 'common/JsonEditorV2';
