import React, { useEffect, useState } from 'react';
import { useLoadGraph, useSigma, useRegisterEvents } from "@react-sigma/core";
import {MultiDirectedGraph} from "graphology";
import { mapValues, keyBy, constant, forEach } from 'lodash';
import { useWorkerLayoutForceAtlas2 } from "@react-sigma/layout-forceatlas2";
import { useGraphVisualisation } from '@components/GraphVisualisation/hooks/useGraphVisualisation';

const GraphController = ({graph, entities, onNodeClick, filtersState, setFiltersState, setDataReady}) => {
  const sigma = useSigma();
  const sigmaGraph = sigma.getGraph();
  const { selectedNode, debug } = useGraphVisualisation();
  const [ highlighted, setHighlighted ] = useState();
  const loadGraph = useLoadGraph({
    multi: true
  });
  const registerEvents = useRegisterEvents();

  const { start, kill, stop, isRunning } = useWorkerLayoutForceAtlas2({
    settings: {
      "barnesHutOptimize": true,
      "strongGravityMode": true,
      "gravity": 0.05,
      "scalingRatio": 10,
      "slowDown": 8.967973179662934
    }
  });


  /**
   * Initialize here settings that require to know the graph and/or the sigma
   * instance:
   */
  useEffect(() => {

    registerEvents({
      clickNode({ node }) {
        let graphNode = graph.node(node);
        if (onNodeClick && graphNode) {
          onNodeClick(node, graphNode)
        }
      }
    });
  }, [registerEvents]);

  const highlightNode = (node) => {
    if (highlighted) {
      // There was a node highlighted - reset it
      sigmaGraph.setNodeAttribute(highlighted, "highlighted", false)
    }
    if (!node || !node.id) {
      return;
    }
    try {
      sigmaGraph.setNodeAttribute(node.id, "highlighted", true)
      const displayData = sigma.getNodeDisplayData(node.id);
      if (displayData) {
        sigma.getCamera().animate({
          ...displayData, ratio: 0.05
        }, {
          duration: 600
        })
      }
      setHighlighted(node.id);
    } catch (error) {
      // Sigma graph and the ids can get out of sync temporarily causing errors. ignore
    }
  }
  useEffect(() =>{
   if (!isRunning) {
    highlightNode(selectedNode);
   }
  }, [selectedNode, isRunning])

  useEffect(() => {
    // start FA2
    try {
      start();
      setTimeout(() => {
        stop();
        if (selectedNode) {
          highlightNode(selectedNode);
        }
      }, 2000); // We have to stop otherwise for very large graphs forceAtlas never stops rendering

      return () => {
        // Kill FA2 on unmount
        //kill();
      };
    } catch (error) {
      // Do nothing - likely layout was killed before start
    }
  }, [start, kill]);

  useEffect(() => {
    const sigmaGraph = new MultiDirectedGraph();
    let documents = {};
    //let tags = {};
    // Need to get all the edge sizes before looping so that we can determine an appropriate size for nodes (they can't be bigger than 20)
    let max = 1;
    let MAX_NODE_SIZE = 30;
    let MIN_NODE_SIZE = 3;

    graph.nodes().forEach(id => {
      let node = graph.node(id);
      if(!node) return;
     let edges = graph.neighbors(id).length;
      if (edges > max) max = edges;
      if (node.definedIn) documents[node.definedIn] = true;
      /*if (node.tags) {
        node.tags.forEach(tag => {
          tags[tag] = true;
        })
      }*/
    });

    graph.nodes().forEach(id => {
      let node = graph.node(id);
      if(!node) return;
      let colour = entities[node.entity] ? entities[node.entity].color : 'grey';
      if (debug) {
        if (node.hidden) return;
        let val = typeof node.input !== 'undefined' ? node.input : node.derived;
        if (val === false || val === false) colour = 'red';
        else if (typeof val === 'undefined' || val === null) colour = 'grey';
        else {
          // TODO: colour temporal values differently?
          colour = 'green';
        }
      }
      sigmaGraph.addNode(id, {
        x:Math.random(),
        y:Math.random(),
        size: Math.ceil((graph.neighbors(id).length / max * (MAX_NODE_SIZE - MIN_NODE_SIZE)),0) + MIN_NODE_SIZE,
        label: node.description,
        color: colour,
        entity: node.entity,
        //tags: node.tags,
        document: node.definedIn
      });
    })
    graph.edges().forEach(edge => {
      // We check if the nodes exists because we may have a hidden node we reference but we are in a debug state - and thus can't connect
      if (sigmaGraph.hasNode(edge.v) && sigmaGraph.hasNode(edge.w) && !sigmaGraph.hasEdge(edge.v, edge.w)) {
        sigmaGraph.addEdgeWithKey(`${edge.v}->${edge.w}`, edge.v, edge.w)
      }
    })
   /* const sensibleSettings = forceAtlas2.inferSettings(graph);
const fa2Layout = new FA2Layout(graph, {
  settings: sensibleSettings,
});*/
//forceAtlas2.assign(graph, {iterations: 50})
    //graph.addNode("first", { x: 0, y: 0, size: 15, label: "My first node", color: "#FA4F40" });
    loadGraph(sigmaGraph);
    setFiltersState({
      entities: mapValues(keyBy(entities, "entity"), constant(true)),
      documents,
      //tags: tags//mapValues(keyBy(dataset.tags, "key"), constant(true)),
    });
    setDataReady(true);
  }, [loadGraph]);

  // Adjust based on filter
  useEffect(() => {
    const { entities, documents } = filtersState;
    sigmaGraph.forEachNode((node, { entity, document }) => {
      sigmaGraph.setNodeAttribute(node, "hidden", !entities[entity] || (document && !documents[document]))
      /*if (tags) {
        forEach(tags, (tag, index) => {
          if (filterTags[tag] === true) {
            return false; // Atleast one of the tags contained in this attribute is still true - so still show
          }
          if (index === (tags.length - 1)) {
            // Last tag. All tags are turned off - so hide this node
            sigmaGraph.setNodeAttribute(node, "hidden", true);
          }
        })
      }*/
    });
  }, [filtersState])
  return null;
}

export default GraphController;
