import React from 'react';
import styled from 'styled-components';
import { withMenu } from '@components/input';
import { MenudotsIcon } from '@icons';
import { Menu } from '@components/menu';
import IconButton from '@material-ui/core/IconButton';


const StyledIconButton = styled(IconButton)`
  width: 1.5rem;
  height: 1.5rem;
`;
const MoreMenu = withMenu(
  p => (
    <StyledIconButton {...p}>
      <MenudotsIcon />
    </StyledIconButton>
  ),
  {}
) as any;



const Wrap = styled.div`
  border-radius: 0.25rem 0.25rem 0 0;
  border: 1px solid #E5E5E5;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;
export const blockClss = {
  topRow: 'topRow_TPRZ39b',
  content: 'content_aLct87x',
}

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 2rem;
  border-bottom: 1px solid #A3A3A3;
`;
const Content = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;


export type BlockProps = React.PropsWithChildren<{
  className?: string;
  topRowNode: React.ReactNode;
}>;
export const Block: React.FC< BlockProps > = p => {
  const { className, topRowNode, children } = p;

  return (
    <Wrap className={className}>
      <TopRow className={blockClss.topRow}>
        { topRowNode }
      </TopRow>

      <Content className={blockClss.content}>
        { children }
      </Content>
    </Wrap>
  );
}


//# region BlockWithTopCtxMenu

const BlockWithTopCtxMenuWrap = styled( Block )`
`;

export type BlockWithTopCtxMenuProps = React.PropsWithChildren<{
  className?: string;
  ctxMenuItems: Array<{ name: string; onClick: () => unknown }>;
  startNode: React.ReactNode;
  hideCtxMenu?: boolean;
}>;
export const BlockWithTopCtxMenu: React.FC< BlockWithTopCtxMenuProps > = p => {
  const { className, ctxMenuItems, children, startNode, hideCtxMenu } = p;
  const [ctxMenuOpen, setCtxMneuOpen] = React.useState(false);

  return (
    <BlockWithTopCtxMenuWrap
      className={className}
      topRowNode={(
        <>
          { startNode }

          { hideCtxMenu ? null : (
            <MoreMenu open={ctxMenuOpen} setOpen={setCtxMneuOpen}>
              <Menu width="9rem" small items={ctxMenuItems} />
            </MoreMenu>
          ) }
        </>
      )}
    >
      { children }
    </BlockWithTopCtxMenuWrap>
  );
}

//# endregion
