import type { GraphNode } from "@packages/commons";
import { useParsedGraph } from "@pages/models/release/GraphContext";
import { MentionCombobox } from "@udecode/plate";
import React from "react";
import {
  Item,
  type MentionItem,
  comboboxStyles,
  createMentionPluginFactory,
  filter,
  getMentionOnSelectItem,
} from "./common";

const ATTRIBUTE_TRIGGER_CHAR = "@";

const useGraphAttributes = () => {
  const graph = useParsedGraph();

  const attributes: MentionItem[] = React.useMemo(() => {
    if (graph === null) return [];

    return graph.nodes().map((nodeId) => {
      const { id, description } = graph.node(nodeId) as unknown as GraphNode;
      const desc = description || id;
      const item = {
        key: id,
        text: desc,
        value: desc,
      };
      return item;
    });
  }, [graph]);

  return attributes;
};

export const AttributeCombobox = () => {
  const attributes = useGraphAttributes();

  return (
    <MentionCombobox
      items={attributes}
      pluginKey={ATTRIBUTE_TRIGGER_CHAR}
      onRenderItem={Item}
      onSelectItem={getMentionOnSelectItem({ key: ATTRIBUTE_TRIGGER_CHAR })}
      filter={filter}
      styles={comboboxStyles}
    />
  );
};

export const createAttributesPlugin = createMentionPluginFactory({
  key: "attributes",
  trigger: ATTRIBUTE_TRIGGER_CHAR,
});
