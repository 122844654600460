import React, { useMemo } from 'react';
import { RuleGraphFullRelease } from "@components/RuleGraph_FullRelease";
import { useGraphVisualisation } from "../hooks/useGraphVisualisation";
import { graphlib } from 'dagre';
import { dequal } from 'dequal';

/**
 * TODO: should refactor into a single unified graph for debug and non-debug graph. but will wait until new designs are done
 */

const MemoisedGraph = React.memo(({ graph, debug, triggerFocus, setSelectedNodeId, setRestrictedViewRootPath }) => {
    // inefficient as this writes back to json version - but will leave refactor to later
    return (
    <RuleGraphFullRelease
      onNodeClick={setSelectedNodeId}
      rawGraph={graph instanceof graphlib.Graph ? graphlib.json.write(graph): graph}
      typeFunc={debug ? 'byValue' : undefined}
      showHidden={!debug}
      onNodeContextClick={triggerFocus}
      onNodeCtrlClick={setRestrictedViewRootPath}
    />
  )
}, (prevProps, nextProps) => {
  // This ensures we re-render only on graph changes (it needs a deep compare)
  if (dequal(prevProps, nextProps)) return true;
  else return false;
});
const Classic = () => {
  const { graph, setSelectedNodeId, triggerFocus, debug, graphVizType, setRestrictedViewRootPath } = useGraphVisualisation();
  if (!graph) return null;
  return (
    <MemoisedGraph
      key={graphVizType}
      graph={graph}
      triggerFocus={triggerFocus}
      debug={debug}
      setSelectedNodeId={setSelectedNodeId}
      setRestrictedViewRootPath={setRestrictedViewRootPath}
    />
  )
}

export default Classic;