import { useFullRelease } from '@common/hooks_useFullRelease';
import { GraphCore } from '@components/GraphVisualisation/GraphViz';
import RuleGraphProvider from '@components/GraphVisualisation/providers/RuleGraphProvider';
import { useParsedGraph } from '@pages/models/release/GraphContext';
import ReleaseContext from '@pages/models/release/ReleaseContext';

export const VisualisationsPanel = ({ session }: { session: any }) => {
  const release = useFullRelease();
  const graph = useParsedGraph();
  const goalNode = graph.node(session.goal);
  const goal = goalNode ? goalNode.path || goalNode.id : session.goal;

  return (
    <RuleGraphProvider
      graph={graph}
      goal={goal}
      debug={true}
      hasNebula={true}
      hasData={true}
      hasDecisionReport={true}
    >
      {/* TODO remove me - legacy support */}
      <ReleaseContext.Provider value={release}>
        <GraphCore />
      </ReleaseContext.Provider>
    </RuleGraphProvider>
  );
};