/* eslint-disable no-multi-spaces */
import React                                from "react";
import RestoreIcon                          from "@material-ui/icons/Restore";
import MuiAlert                             from "@material-ui/lab/Alert";
// import LabelIcon                            from "@material-ui/icons/Label";
import InputIcon                            from "@material-ui/icons/Input";
import ListIcon                             from "@material-ui/icons/List";
import RadioButtonCheckedIcon               from "@material-ui/icons/RadioButtonChecked";
import PeopleAltIcon                        from "@material-ui/icons/PeopleAlt";
import DateRangeIcon                        from "@material-ui/icons/DateRange";
import EventIcon                            from "@material-ui/icons/Event";
import ScheduleIcon                         from "@material-ui/icons/Schedule";
import CheckBoxIcon                         from "@material-ui/icons/CheckBox";
import MonetizationOnIcon                   from "@material-ui/icons/MonetizationOn";
import ImageIcon                            from "@material-ui/icons/Image";
import BorderColorIcon                      from "@material-ui/icons/BorderColor";
import BorderStyleIcon                      from "@material-ui/icons/BorderStyle";
import HelpIcon                             from "@material-ui/icons/Help";
import CloseIcon                            from "@material-ui/icons/Close";
import WebIcon                              from "@material-ui/icons/Web";
import DragIndicatorIcon                    from "@material-ui/icons/DragIndicator";
import MoreVertIcon                         from "@material-ui/icons/MoreVert";
import EditIcon                             from "@material-ui/icons/Edit";
import PictureInPictureAltIcon              from "@material-ui/icons/PictureInPictureAlt";
import CategoryIcon                         from "@material-ui/icons/Category";
import RepeatIcon                           from "@material-ui/icons/Repeat";
import CallSplitIcon                        from "@material-ui/icons/CallSplit";
import BrokenImageIcon                      from "@material-ui/icons/BrokenImage";
import FlagIcon                             from "@material-ui/icons/Flag";
import FunctionsIcon                        from "@material-ui/icons/Functions";
import FileCopyIcon                         from "@material-ui/icons/FileCopy";
import NotificationsIcon                             from "@material-ui/icons/Notifications";
import NotificationsNoneIcon                             from "@material-ui/icons/NotificationsNone";
import NotificationsImportantIcon                             from "@material-ui/icons/NotificationImportant";
import LibraryBooksIcon                     from "@material-ui/icons/LibraryBooksOutlined";
import FlipToBackIcon                     from "@material-ui/icons/FlipToBack";
import { SvgIconTypeMap }                   from "@material-ui/core";
import { OverridableComponent }             from "@material-ui/core/OverridableComponent";
import GenerativeChatIcon from "@material-ui/icons/Assistant";
import { ArrowDownRight }                   from "./arrows";
import { ReactComponent as LoadingDotsSVG } from "./loading.svg";
import { ReactComponent as SmallAddSVG }    from "./smladd.svg";
import { ReactComponent as CloneIconSvg }   from "./clone.svg";

export type MuiIcon = OverridableComponent<SvgIconTypeMap>;

export * from "./logo";
export * from "./generated";

export const LoadingDots    = LoadingDotsSVG;
export const LoadingDotsJSX = React.createElement(LoadingDotsSVG);
export const SmallAdd       = SmallAddSVG;
export const CloneIcon      = CloneIconSvg;

export {
  RestoreIcon,
  MuiAlert,
  // LabelIcon,
  InputIcon,
  ListIcon,
  RadioButtonCheckedIcon,
  PeopleAltIcon,
  DateRangeIcon,
  EventIcon,
  ScheduleIcon,
  CheckBoxIcon,
  MonetizationOnIcon,
  ImageIcon,
  BorderColorIcon,
  BorderStyleIcon,
  HelpIcon,
  CloseIcon,  // TODO
  WebIcon,
  ArrowDownRight,
  DragIndicatorIcon,
  MoreVertIcon,
  PictureInPictureAltIcon,
  EditIcon,
  CategoryIcon,
  RepeatIcon,
  CallSplitIcon,
  NotificationsIcon,
  NotificationsImportantIcon,
  NotificationsNoneIcon,
  BrokenImageIcon,
  FlagIcon,
  FlipToBackIcon,
  FunctionsIcon,
  FileCopyIcon,
  LibraryBooksIcon,
  GenerativeChatIcon
};
