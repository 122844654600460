import React from "react";
import clsx from "clsx";
import styled from "styled-components";
import { getContainedBy, getIndexForNode, getSCTheme } from "@common";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useTheme } from "@material-ui/core";
import { Label } from "@components";
import {
  constantColorClasses as valueColorClasses,
} from "@components/TemporalValue/TempTimeline";
import { ValueRender } from "./ValueRender";
import RemoveIcon from "@material-ui/icons/Remove";
import { forEach } from "lodash";
import Tooltip from "@material-ui/core/Tooltip";

const Wrap = styled.div`
  padding: 0.5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
  border: 2px solid transparent;

  :hover {
    background-color: ${p => (getSCTheme(p).palette.secondary).hover};
  }

  &.isSearchMatch {
    border-color: ${p => p.theme.labels.Author};
      /* background-color: ${p => p.theme.palette.secondary.hover}; */
  }

  &.greyedOut {
    color: grey;
  }
`;

const NameTypography = styled(Typography)`
  font-family: 'Libre Baskerville';
  white-space: nowrap;
`;
const CollapseIconButton = styled(IconButton)`
  padding: 0;

  &.hidden {
    display: none;
  }
`;

const ReportNode = (props) => {

  const {
    node: {
      children, expanded, node, type, title,
    }, isSearchMatch, onClick, isSearchFocus, toggleChildrenVisibility, graph
  } = props;

  if (type === "special") {
    return (
      <Box paddingLeft={"0rem"} display="flex" justifyContent="space-between" >
        <Typography style={{opacity: 0.7}}>{title}</Typography>
      </Box>
    );
  }

  if (!node) return null;
  const hasAtLeastOneNonHiddenChild = React.useMemo(
    () => (Array.isArray(children) ? children : []).some(it => it.node?.hidden !== true),
    [ children ],
  );
  const showExpandLess = expanded || hasAtLeastOneNonHiddenChild === false;
  const theme = useTheme();
  const value = typeof node.derived === "undefined" ? node.input : node.derived;
  let isUnknownOrUncertain = false;
  let status;
  if (typeof value === "undefined") {
    status = "Unknown";
    isUnknownOrUncertain = true;
  } else if (value === null) {
    status = "Uncertain";
    isUnknownOrUncertain = true;
  } else if (value === false) status = "Not satisfied";
  else if (value.temporal) {
    let atleastOneTrue = false;
    let atleastOneFalse = false;
    forEach(value.temporal.ranges, r => {
      if (r.v === false) {
        atleastOneFalse = true;
      } else if (typeof r.v !== "undefined" && r.v !== null) {
        atleastOneTrue = true;
      }
    });
    if (atleastOneTrue) status = "Satisfied at times";
    else if (atleastOneFalse) status = "Not satisfied at times";
    else {
      status = "Uncertain at times";
      isUnknownOrUncertain = true;
    }
  } else status = "Satisfied";
  const labelProps = React.useMemo(() => {
    if (status === "Unknown") {
      return { className: valueColorClasses.undefined };
    }

    if (status === "Uncertain" || status === "Uncertain at times") {
      return { className: valueColorClasses.null };
    }

    return {
      color: theme.labels[
        status === "Satisfied" ? "All members" : (
          (status === "Satisfied at times" || status === "Not satisfied at times")
            ? "Developer"
            : "Author"
        )
        ],
    };
  }, [ theme, status ]);

  const toggleExpanded = React.useCallback(() => {
    if (!toggleChildrenVisibility || hasAtLeastOneNonHiddenChild === false) return;
    return toggleChildrenVisibility(props);
  }, [ props, toggleChildrenVisibility, hasAtLeastOneNonHiddenChild ]);
  
  let indexInfo = getIndexForNode(node, graph);
  
  const tooltipContent = <>
    <b>{node.description}</b>
    <div>
      Entity: {node.entity}
    </div>
    {node.index && <div>
      Index: {node.index}
    </div>}
    {indexInfo && indexInfo.hint && <div>
      Identifier: {indexInfo.value}
    </div>}
    {node.path && getContainedBy(node.path, graph)}
  </>

  return (    
    <Wrap className={clsx(isSearchMatch && isSearchFocus && "isSearchMatch", isUnknownOrUncertain && "greyedOut")}>
      <CollapseIconButton
        onClick={toggleExpanded}
      >
        {children.length > 0 ? (showExpandLess ? <ExpandLessIcon/> : <ExpandMoreIcon/>) : <RemoveIcon/>}
      </CollapseIconButton>
      <Box
        display="flex"
        flexDirection="column"
        gridGap="0.25rem"
        onClick={() => onClick(node)}
      >
        <Tooltip interactive={true} enterDelay={500} placement={"bottom-start"} title={tooltipContent}>
          <Box display="flex" gridGap="0.5rem">
            <NameTypography>{node.description}</NameTypography>
            {isUnknownOrUncertain ? null : <Label {...labelProps}>{status}</Label>}
          </Box>
        </Tooltip>
        <ValueRender node={node}/>

      </Box>
    </Wrap>
  );
};
export default ReportNode;
