import React, { useState, useRef, useEffect } from 'react';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AnimateHeight from "react-animate-height";
import styled from "styled-components";

const Container = styled.div`
  background: white;
  padding: 5px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);

  .panel:not(:last-child) {
    margin-bottom: 0.5em;
  }
  .panel h2 button {
    float: right;
    background: white;
    border: 1px solid black;
    border-radius: var(--border-radius);
    font-size: 1.2em;
    height: 1em;
    width: 1em;
    text-align: center;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .panel h2 button:hover {
    opacity: var(--hover-opacity);
  }
`
const DURATION = 300;

const Panel = ({title, children, initiallyDeployed}) => {
  const [isDeployed, setIsDeployed] = useState(initiallyDeployed || false);

  const dom = useRef(null);

  useEffect(() => {
    if (isDeployed)
      setTimeout(() => {
        if (dom.current) dom.current.parentElement.scrollTo({ top: dom.current.offsetTop - 5, behavior: "smooth" });
      }, DURATION);
  }, [isDeployed]);

  return (
    <Container ref={dom}>
      <h2>
        {title}{" "}
        <button type="button" onClick={() => setIsDeployed((v) => !v)}>
          {isDeployed ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </button>
      </h2>
      <AnimateHeight duration={DURATION} height={isDeployed ? "auto" : 0}>
        {children}
      </AnimateHeight>
    </Container>
  )
}
export default Panel;