import React from 'react';

/** @deprecated DO NOT USE - superseded by `components/GraphVisualisation/RuleGraphProvider` */
const AttributeContext = React.createContext();

/** @deprecated DO NOT USE - superseded by `useGraphVisualisation` */
export const useAttribute = () => {
  const context = React.useContext(AttributeContext)
  if (context === undefined) {
    throw new Error('useAttribute must be used within a AttributeContext')
  }
  return context
}

export default AttributeContext;
