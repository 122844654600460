import { DragHandleDots2Icon } from "@radix-ui/react-icons";
import React from "react";
import * as ResizablePrimitive from "react-resizable-panels";
import styled from "styled-components";

const PanelGroup = styled(ResizablePrimitive.PanelGroup)`
  display: flex;
  height: 100%;
  width: 100%;

  [data-panel-group-direction="vertical"] {
    flex-direction: column;
  }
`;

const ResizablePanelGroup = React.forwardRef<
  React.ElementRef<typeof ResizablePrimitive.PanelGroup>,
  React.ComponentPropsWithoutRef<typeof ResizablePrimitive.PanelGroup>
>((props, ref) => (
  <PanelGroup ref={ref} {...props} />
));

const ResizablePanel = ResizablePrimitive.Panel;

const PanelHandle = styled(ResizablePrimitive.PanelResizeHandle)`
  position: relative;
  display: flex;
  width: 1px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.palette.background.border};

  ::after {
    content: "";
    position: absolute;
    inset: 0;
    left: 50%;
    width: 0.25rem;
    transform: translateX(-50%);
  }

  :focus-visible {
    outline: none;
    --tw-ring-inset: 1px;
    --tw-ring-offset-width: 1px;
    --tw-ring-color: ${props => props.theme.palette.primary.main};
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }

  &[data-panel-group-direction="vertical"] {
    height: 1px;
    width: 100%;

    > div {
      transform: rotate(90deg);
    }

    ::after {
      left: 0;
      height: 0.25rem;
      width: 100%;
      transform: translateY(-50%), translateX(0);
    }
  }

  .resizeHandle {
    z-index: 10;
    display: flex;
    height: 1rem;
    width: 0.75rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.125rem;
    border: 1px solid ${props => props.theme.palette.background.border};

    > svg {
      height: 0.625rem;
      width: 0.625rem;
    }
  }
`;

const ResizableHandle = React.forwardRef<
  React.ElementRef<typeof ResizablePrimitive.PanelResizeHandle>,
  React.ComponentPropsWithoutRef<typeof ResizablePrimitive.PanelResizeHandle> & { withHandle?: boolean }
>(({ withHandle, ...props }, ref) => (
  // @ts-ignore ref should be fine
  <PanelHandle ref={ref} {...props}>
    {withHandle && (
      <div className="resizeHandle">
        <DragHandleDots2Icon />
      </div>
    )}
  </PanelHandle>
));

export { ResizablePanelGroup, ResizablePanel, ResizableHandle };
