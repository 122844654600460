import React from "react";
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { DownloadIcon, LoadingDots } from "@icons";
import { fileUploadService } from "services";
import { useNotification } from "@common/notifications";

const StyledDownloadIcon = styled(DownloadIcon)`
  height: 1rem;
`

const StyledLoadingDots = styled(LoadingDots)`
  height: 1rem;
`

const uuidRegex = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}'
const dataRefRegex = new RegExp(`^data:id=(${uuidRegex});base64,([-A-Za-z0-9+/=]*)$`)

export const DownloadFileButton = ({ reference, hideName, className = undefined }) => {

  const [_, fileId, fileNameB64] = reference?.match(dataRefRegex);
  const fileName = atob(fileNameB64);

  const [isDownloading, setIsDownloading] = React.useState(false);
  const notify = useNotification();

  const handleClick = async (e: React.MouseEvent< HTMLButtonElement >) => {
    e.stopPropagation();
    setIsDownloading(true);
    let response;
    try {
      response = await fileUploadService.getOne(fileId);
    } catch (error: any) {
      console.log(error);
      setIsDownloading(false);
      notify.error(`Error downloading the file: details: ${error.message}`);
      return;
    };
    if (!response || !response.data) {
      setIsDownloading(false);
      notify.error(`Error downloading the file: no data from server`);
    }
    const [prefix, fileData] = response.data.split(';base64,');
    const mimeType = prefix.split(';')[0].replace('data:', '');
    const buffer = Buffer.from(fileData, 'base64');
    const blob = new Blob([buffer], { type: mimeType });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    setIsDownloading(false);
  };

  return (
    <IconButton
      onClick={handleClick}
      disabled={isDownloading}
      size="small"
      className={className}
    >
      {isDownloading ? <StyledLoadingDots /> : <StyledDownloadIcon />}
      {hideName ? null : fileName}
    </IconButton>
  )
}
