import RuleGraphSigma from "@components/RuleGraphSigma/RuleGraphSigma";
import { useGraphVisualisation } from "../hooks/useGraphVisualisation";
import { graphlib } from 'dagre';

const Nebula = () => {
  const { graph, setSelectedNodeId } = useGraphVisualisation();
  if (!graph) return null;
  return (
    <RuleGraphSigma
      rawGraph={graphlib.json.write(graph)}
      onNodeClick={setSelectedNodeId}
    />
  );

}
export default Nebula;