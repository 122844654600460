import { TaskStatus, TaskStatusEnum } from "@packages/commons";
import { Phase, PhaseTask } from "./types";

export const isRelevantTask = (t: PhaseTask): boolean => t.relevant;
export const getRelevantTasks = (tasks: PhaseTask[]): PhaseTask[] => tasks.filter(isRelevantTask);
export const getActiveTasks = (tasks: PhaseTask[]): PhaseTask[] => tasks.filter(t => t.status !== TaskStatusEnum.Approved);

export const getPhaseStatus = (p: Phase): TaskStatus => {
  if (p.tasks.length === 0) return TaskStatusEnum.NotStarted;
  const tasks = getRelevantTasks(p.tasks);
  // if any task is in progress, the phase is in progress
  if (tasks.some(t => t.status === TaskStatusEnum.InProgress)) return TaskStatusEnum.InProgress;
  // if all tasks are approved, the phase is approved
  if (tasks.every(t => t.status === TaskStatusEnum.Approved)) return TaskStatusEnum.Approved;
  // otherwise the phase is not started
  return TaskStatusEnum.NotStarted;
};
