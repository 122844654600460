import clsx from 'clsx';
import React from 'react';
import styled from 'styled-components';

import { scrollableMixin } from '@common';

import { Controls, ControlsProps } from './Controls';
import { PhaseColumn } from './PhaseColumn';
import { Phase } from './types';

const Container = styled.div`
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-radius: 0.25rem;
  display: flex;
  gap: 0.5rem;

  &.expanded {
    border: 1px solid #0A0A0A;
    max-height: 350px;
  }
`;

const Content = styled.div`
  display: flex;
  gap: 1rem;
  flex-grow: 1;

  ${scrollableMixin};
  &::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
  }
`;

export type PhasePanelProps = (
  & Pick<ControlsProps, 'expanded' | 'toggleExpanded'>
  & {
    phases: Phase[];
  } & React.HTMLAttributes<HTMLDivElement>
);

export const PhasePanel: React.FC<PhasePanelProps> = p => {
  const {
    className,
    expanded,
    toggleExpanded,
    phases,
    ...props
  } = p;

  const finalClassName = clsx(className, expanded && 'expanded');

  return (
    <Container className={finalClassName} {...props}>
      <Content className={className}>
        {
          phases.map((phase, i) => (
            <PhaseColumn key={phase.id} phase={phase} expanded={expanded} />
          ))
        }
      </Content>
      <Controls expanded={expanded} toggleExpanded={toggleExpanded} />
    </Container>
  );
};