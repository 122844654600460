import { ModalProps } from "./types";
import { useModal } from "./modals";

export type ICreateModal = <T>(component: React.FC<ModalProps<T>>) => () => (props: T) => void;

/** A utility factory to help with modal creation */
export const createUseModal: ICreateModal = (component) => () => {
  const { pushModal, popModal } = useModal();
  return (props) => {
    pushModal({ component, props: { ...props, close: popModal } })
  };
};