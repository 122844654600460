import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { holder } from "./holder";

import type { Modal, ModalContent, UseModal } from './types';

/**
 * basically State serves only as indicator for rerender, as all\
 * modal contents reside inside saga.holder
 */
export type State = {
  _renderKey: number;
  updateMountKey: () => void;
};

const initialState = {
  _renderKey: 1,
  updateMountKey: null
};

const ModalContext = createContext<State>(initialState as unknown as State);

// TODO: Update Types
export const ModalProvider = ({ children }: {children: any}) => {
  const [state, setState] = useState(1);

  const updateMountKey = useCallback(() => {
    const nextKey = Math.random();
    setState(nextKey);
  }, [setState]);

  const value = useMemo(() => ({
    _renderKey: state,
    updateMountKey
  }), [state, updateMountKey]);

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): UseModal => {
  const { updateMountKey } = useContext(ModalContext);

  const popModal = useCallback(() => {
    holder.popModal();
    updateMountKey();
  }, []);

  const pushModal = useCallback((modal: Modal) => {
    holder.pushModal({ props: {}, contents: [], ...modal });
    updateMountKey();
  }, []);

  const popContents = useCallback(() => {
    holder.popContents();
    updateMountKey();
  }, []);

  const pushContents = useCallback((content: ModalContent) => {
    holder.pushContents({ props: {}, ...content });
    updateMountKey();
  }, []);

  const clearModals = useCallback(() => {
    holder.setModals(() => []);
    updateMountKey();
  }, []);

  return { popContents, pushContents, popModal, pushModal, clearModals };
};

export const ModalRenderer = React.memo(() => {
  /** just use it for rerender when renderKey changes */
  void useContext(ModalContext);

  return (
    <>
      {holder.modals.map(({ component: Component, props, contents }, i: number) => (
        <React.Fragment key={i}>
          {/* @ts-ignore - contents are allowed to be undefined */}
          <Component props={props} contents={contents} />
        </React.Fragment>
      ))}
    </>
  );
});