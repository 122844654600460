// @ts-nocheck
import React from 'react';
import { isTemporal } from '@pages/decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { TempTimeline } from '@components/TemporalValue/TempTimeline';
import styled from 'styled-components';
import { roundToMaxDigitsAfterDot } from '@common';
import NodeValue from '@components/GraphVisualisation/components/AttributeInfo/NodeValue';

const StyledTempTimeline = styled(TempTimeline)`
  height: 100%;
`;

const ValueHeading = styled(Typography)`font-weight: 700;`;

export const ValueRender = React.memo(({ node, onClick }) => {
  const value = typeof node.derived === 'undefined' ? node.input : node.derived;

  const timelineValueClick = React.useCallback(e => {
    e.stopPropagation();
    if(!isTemporal(value)) return;

    if (onClick) onClick(node);
  }, [value, node, onClick]);

  const singleRender = () => {
    if (typeof value === 'undefined') return 'Unknown';
    else if (value === null) return 'Uncertain';
    else if (typeof value === 'number') return roundToMaxDigitsAfterDot(value);
    else return String(value);
  }
  return isTemporal(value)
    ? (
      <Box onClick={timelineValueClick}>
        <NodeValue node={node} />
      </Box>
    )
    : (
      <Box display='flex' gridGap='0.25rem' alignItems='center'>
        <ValueHeading variant='h6'>Value:</ValueHeading>
        <Typography>
          <NodeValue node={node} />
        </Typography>
      </Box>
    );
});
