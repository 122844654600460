import moment from 'moment';
import { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useScope } from 'redux/scope';
import { FlowTask, flowTasksService } from 'services';
import styled from 'styled-components';
import { hexToHSL, system } from 'theme';

import { EmptyContent } from '@common';
import { useScrollable } from '@common/hooks/useScrollable';
import { priorityColor, statusColor } from '@common/tasks/taskUtils';
import { Flex, Label, Stack } from '@components';
import { AssigneeGroup } from '@components/forms/FormikControls';
import { Sort } from '@imminently/immi-query';
import { Tooltip, Typography } from '@material-ui/core';

import { ShortID, StatusCircle } from './utils';

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.palette.background.default};
  border: 1px solid ${props => props.theme.palette.divider};
  border-radius: 0.5rem;
  /* height: calc(100% - 4rem); */
  overflow: hidden;

  #header {
    gap: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 1.5rem 1rem 1rem 1rem;
    
    border-bottom: 1px solid ${props => props.theme.palette.divider};

    >.MuiTypography-root {
      font-size: 1.125rem; // 18px
      font-weight: 600;
    }
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  height: 4rem;
  padding: 0 1rem;
  cursor: pointer;

  &[data-even="true"] {
    background-color: ${props => props.theme.palette.background.default};
  }
  &[data-even="false"] {
    background-color: ${props => props.theme.palette.background.light};
  }
  &:hover {
    background-color: ${props => props.theme.palette.background.hover};
  }

  &[aria-selected="true"] {
    border: 1px solid ${props => props.theme.palette.background.border};
    border-left: 4px solid ${props => props.theme.palette.primary.main};
    background-color: ${props => props.theme.palette.background.hover};
  }
`;

const SessionID = styled(ShortID)`
  color: ${p => p.theme.palette.secondary.dark};
  text-transform: uppercase;
  font-weight: 600;
`;

type TaskListItemProps = {
  task: FlowTask;
  index: number;
  onClick: (task: FlowTask) => void;
  active?: boolean;
};

const TaskListItem = ({ task, index, active = false, onClick }: TaskListItemProps) => {
  const { t } = useTranslation();
  // date in format 24 June, 2024, using moment.js to format
  const dueDate = task.dueDate ? moment(task.dueDate).format("DD MMM, YYYY") : '';
  return (
    <ListItem data-even={index % 2 === 0} aria-selected={active} onClick={() => onClick(task)}>
      <Tooltip title={t(`tasks.status.${task.status}`)} placement="left">
        <StatusCircle color={hexToHSL(statusColor[task.status] ?? "#ff00ff", 80)} />
      </Tooltip>
      <Stack flex={1}>
        <Typography>{task.title ?? task.name}</Typography>
        <Typography variant="caption">Session: <SessionID value={task.session} prefix="#" variant="caption" /></Typography>
      </Stack>
      <AssigneeGroup users={task.assigneesData} emptyText={null} />
      <Typography variant="caption">{dueDate}</Typography>
      <Flex minWidth="4rem" justifyContent="flex-end">
        <Label color={hexToHSL(priorityColor[task.priority] ?? "#ff00ff", 90)}>
          {t(`tasks.priority.${task.priority}`)}
        </Label>
      </Flex>
    </ListItem>
  );
};

/** Hook for list of flow tasks, scoped to current workspace/project */
export const useFlowTasks = (filter: any = {}) => {
  const { workspace, project } = useScope();
  return flowTasksService.useInfiniteResources({
    perPage: 10,
    filter: {
      type: "flow",
      sort: { field: "created", order: "desc" } as Sort,
      status: ["notstarted", "inprogress", "not-started", "in-progress", "review", "blocked"],
      workspace,
      model: project,
      ...filter,
    },
  });
};

export type TaskListProps = ComponentPropsWithoutRef<typeof ListContainer> & {
  tasks: FlowTask[];
  isLoading?: boolean;
  total?: number;
  loadMore?: () => void;
  canLoad?: boolean;
  onTaskClick: (task: FlowTask) => void;
  /** Active task id */
  activeId?: string;
};

// empty function that does nothing
const noop = () => { };

export const TaskList = ({ tasks, isLoading = false, total, loadMore = noop, canLoad = false, onTaskClick, activeId, ...props }: TaskListProps) => {
  const { t } = useTranslation();
  const scrollLoad = useScrollable(loadMore, canLoad, { autoScroll: true });

  return (
    <ListContainer {...props}>
      <Flex id="header">
        <Typography>{t('tasks.tasks')}</Typography>
        <Label color={system.orangeFiesta}>{total ?? tasks.length} {t('tasks.tasks').toLocaleLowerCase()}</Label>
      </Flex>
      {
        isLoading
          ? <EmptyContent messages={[t('loading_with_dots')]} img={null} />
          : tasks.length === 0
            ? <EmptyContent messages={[t('tasks.no_tasks')]} />
            : (
              <Stack {...scrollLoad}>
                {
                  tasks.length > 0
                    ? tasks.map((task, index) => (
                      <TaskListItem key={task.id} active={task.id === activeId} index={index} task={task as FlowTask} onClick={onTaskClick} />
                    ))
                    : null
                }
              </Stack>
            )
      }
    </ListContainer>
  );
};