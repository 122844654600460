import { FormProvider, useForm } from "@decisively-io/interview-react-material";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import React from "react";
import { PrimaryButton } from "@components/buttons";
import { CompactControls, CompactControlsDefaultFooter } from "@components/CompactControls";
import { deriveDefaultControlsValue, type RenderableControl } from "@decisively-io/interview-sdk";


const controls: RenderableControl[] = [
  {
    type: 'boolean',
    attribute: 'attr1',
    id: 'id1',
    label: 'Boolean 1',
  },
  {
    type: 'text',
    attribute: 'attr2',
    id: 'id2',
    label: 'Text 2',
  },
  {
    type: 'text',
    attribute: 'attr3',
    id: 'id3',
    label: 'Text 3',
  },
  {
    type: 'text',
    attribute: 'attr4',
    id: 'id4',
    label: 'Text 4',
  },
  {
    type: 'text',
    attribute: 'attr5',
    id: 'id5',
    label: 'Text 5',
  },
];


export const JSX = React.createElement(() => {
  const [open, setOpen] = React.useState(false);
  const defaultValues = React.useMemo(() => (
    deriveDefaultControlsValue(controls)
  ), []);
  const methods = useForm({
    defaultValues,
  });
  // const { handleSubmit, getValues, reset, watch } = methods;

  return (
    <div style={{ margin: 16 }}>
      <PrimaryButton onClick={React.useCallback(() => setOpen(true), [])}>
        Show CompactControls
      </PrimaryButton>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box padding='1rem' height='15rem' display='flex'>
          <Box border='1px solid black' display='flex'>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormProvider {...methods}>
                <CompactControls
                  controls={controls}
                  onSubmit={console.log}
                  FooterJSX={(
                    <CompactControlsDefaultFooter
                      primaryAction={{ label: 'Submit' }}
                      secondaryAction={{ label: 'Save', onClick: console.log }}
                    />
                  )}
                />
              </FormProvider>
            </MuiPickersUtilsProvider>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
});
