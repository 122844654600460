import React from "react";
import debounce from "lodash/debounce";
import SearchAndSelectResultItem from "./SearchAndSelectResultItem";
import { useSigma } from "@react-sigma/core";
import SearchAndSelect from "@components/search/SearchAndSelect";
import { isStrNotNullOrBlank, isStrNullOrBlank } from "@components/util/UtilString";
import { useGraphVisualisation } from "@components/GraphVisualisation/hooks/useGraphVisualisation";
import { normalizeText } from "@packages/commons";
import { useTranslation } from "react-i18next";

export type DocReference = {
  id: string;
  fullPath: string;
}

export interface SearchResult {
  id: string;
  index: number;
  score: number;
  string: string;
  // original: GraphNode & {
  original: {
    color: string; // is a hex color
    document: string; // is a path
    documentPath: string; // is the same as above, I'm just duplicating it so it's clear
    size: number; // the size of the blob/circle
    label: string;
    entity: string;
    hidden: boolean;
    x: number;
    y: number;
  }
}

interface SearchRuleGraphNodesProps {
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SearchRuleGraphNodes = (props: SearchRuleGraphNodesProps) => {
  const {
    graph,
    setSelectedNodeId,
    setSearchText,
    searchText,
    debug,
    setRestrictedViewRootPath,
  } = useGraphVisualisation();
  /** where [] is "no results" and null is "no search performed yet" */
  const [searchResults, setSearchResults] = React.useState<SearchResult[] | null>([]);
  const allowHiddenNodesInResults = !(debug ?? false);
  const { t } = useTranslation();
  // -- search

  const searchNodes_ = (srchStr: string) => {
    if (!graph || !graph.nodes) return;
    const lcSearch = normalizeText(srchStr.toLowerCase());
    const newValues: SearchResult[] = [];
    // @ts-ignore
    graph.nodes().forEach((id: string) => {
      let node = graph.node(id);
      if ((allowHiddenNodesInResults || (!allowHiddenNodesInResults && !node.hidden)) &&
        node.description &&
        (node.description.toLowerCase().indexOf(lcSearch) > -1)
      ) {
        newValues.push({
          id: id,
          index: 0,
          score: 1,
          string: node.description,
          original: {
            ...node as any,
            documentPath: node.document,
          },
        });
      }
    });
    setSearchResults(newValues);
  };

  const searchNodesDebounced = React.useCallback(
    debounce(searchNodes_, 200), [graph]
  );

  const searchNodes = () => {
    // @ts-ignore
    if (isStrNotNullOrBlank(searchText) && graph && graph.nodes && graph.nodes().length > 0) {
      searchNodesDebounced(searchText || "");
    }
  };

  React.useEffect(() => {
    if (isStrNotNullOrBlank(searchText)) {
      searchNodes();
    } else {
      setSearchResults(null);
    }
  }, [searchText, graph]);

  // -- clear/reset

  const onClear = () => {
    setSearchText("");
    setSearchResults(null);
    setSelectedNodeId(null);
  };

  // -- navigation

  const selectNodeOnGraph = (nodeId: string) => {
    if (nodeId) {
      setSelectedNodeId(nodeId);
      let node = graph.node(nodeId)
      if (node) {
        setSelectedNodeId(nodeId);
        if (debug) {
          // TODO do we want to make this the behavior for all modes?
          setRestrictedViewRootPath(node.path || node.id || nodeId);
        }
      }
      setSearchText(""); // OR maybe we should leave the search value as-is??
      setSearchResults(null);
    }
  };

  // -- rendering

  return (
    <SearchAndSelect<SearchResult>
      // key={graphNodes?.[0]?.v ?? "no-graph"}
      appearance="default" // maybe "compact" on smaller/narrow screens?
      alignPopover="left"
      placeholder={t('debugger.find_attribute')}
      value={searchText || ""}
      onChange={setSearchText}
      onEnter={searchNodes}
      onClear={onClear}
      searchResults={searchResults}
      renderResultItem={(result, ref) => {
        // @ts-ignore
        const entityPath = result.original.path?.split("/").slice(0, -1).join("/");
        return <SearchAndSelectResultItem
          ref={ref}
          key={result.id}
          graphNodeId={result.id}
          documentFullPath={result.original.documentPath || ""}
          additionalInfo={{
            entityName: entityPath,
            matchingText: result.string,
            highlightStr: normalizeText(searchText || ""),
            badgeColor: result.original.color,
          }}
          onSelectGraphNode={selectNodeOnGraph}
        />
      }}
    />
  );
};

export default (SearchRuleGraphNodes);
