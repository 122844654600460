import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Box, Tooltip, Typography } from "@material-ui/core";
import HelpOutline from "@material-ui/icons/HelpOutline";
import { showModal } from '@imminently/imminently_platform';
import { Label } from "@components/meta";
import { colors } from "theme";
import { eigenvectorCentrality } from "./util";
import { getAttributeIcon, getGraphNodeTypeColor, getNodeType, GraphNodeType, scrollable } from "@common";
import { GraphNode } from "@packages/commons";
import { VersionhistoryIcon } from "@icons";
import { useFullRelease } from "@common/hooks_useFullRelease";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Statbar = styled(Flex)`
  padding: 0.5rem 2rem;
  border-bottom: 1px solid ${p => p.theme.palette.background.border};
  border-top: 1px solid ${p => p.theme.palette.background.border};
`;

const Stat = styled(Label)`
  display: inline-flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid ${p => p.theme.palette.background.border};
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 1rem;
  /* width: 1rem; */
  height: 1rem;
  padding: 0 0.25rem;
`;

const AttributeContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  align-items: center;
  border: 1px solid ${p => p.theme.palette.background.border};
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.palette.background.hover};
  }

  #icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    border: 1px solid ${p => p.theme.palette.background.border};
    padding: 0.5rem;
  }

  /* svg {
    width: 2rem;
    height: 2rem;
  } */
`;

const useGraphStats = (graph: any, debug: boolean = false) => {
  let counts = {
    total: 0,
    input: 0,
    derived: 0,
    goals: 0
  };
  let interestingNodes: any[] = [];

  if (!graph) return { counts, interestingNodes };

  let sinks = graph.sinks();
  let sources = graph.sources();

  graph.nodes().forEach(n => {
    let node = graph.node(n);
    if (node.fk) return;
    if (debug && node.hidden) return;
    if (sinks.indexOf(n) > -1) {
      // Some inputs are actually derived - if they have a value
      if (node.value || node.conditions || node.rows) counts.derived = counts.derived + 1;
      else counts.input = counts.input + 1;
    } else if (sources.indexOf(n) > -1) {
      counts.goals = counts.goals + 1;
    } else {
      counts.derived = counts.derived + 1;
    }
    counts.total = counts.total + 1; // always increase total
  });

  let eigens = eigenvectorCentrality(graph);

  // Sort them
  let data = Array.from(eigens).sort((a, b) => {
    if (a[1] > b[1]) return -1;
    else if (a[1] === b[1]) return 0;
    return 1;
  });

  // Loop through the vectors and return the top 10 inputs
  let count = 0;
  let i = 0;
  while (count < 10 && i < data.length) {
    let info = data[i];
    if (!info) {
      count = 11;
      break;
    }
    if (sinks.indexOf(info[0]) > -1) {
      // it's an input node
      let node = graph.node(info[0]);
      if (node) {
        interestingNodes.push(node);
        count++;
      }
    }
    i++;
  };

  return { counts, interestingNodes };
};

type StatChipProps = {
  label: ReactNode;
  value: number;
  color: string;
};

const StatChip = ({ label, value, color }: StatChipProps) => (
  <Stat color={color}>
    <span>{label}</span>
    <Count>{value}</Count>
  </Stat>
);

const Attribute = ({ node, graph }: { node: GraphNode, graph: any }) => {
  const type = getNodeType(graph, node.id);
  return (
    <AttributeContainer>
      <div id="icon" style={{ backgroundColor: getGraphNodeTypeColor(type) }}>
        {getAttributeIcon(node.type)}
      </div>
      <Typography>{node.description}</Typography>
    </AttributeContainer>
  );
};

export const GraphStatistics = ({ graph, debug = false }) => {
  const release = useFullRelease();
  const { t } = useTranslation();
  const { counts, interestingNodes } = useGraphStats(graph, debug);

  if (!graph) return <Typography>No graph available</Typography>;

  const lastUpdated = release ? moment(release.lastModified).format('DD/MM/YYYY - HH:mm:ss') : null;


  const helpTooltip = t('debugger.stats_help_tooltip');

  return (
    <FlexCol style={{ padding: "1rem 0 0 0", gap: 0 }}>
      <Statbar>
        <StatChip label={<span style={{ color: "white" }}>{t('debugger.stats_total_attributes')}</span>} value={counts.total} color={colors.kindaBlack} />
        <StatChip label={t('debugger.stats_inputs')} value={counts.input} color={getGraphNodeTypeColor(GraphNodeType.INPUT)} />
        <StatChip label={t('debugger.stats_derived')} value={counts.derived} color={getGraphNodeTypeColor(GraphNodeType.DERIVED)} />
        <StatChip label={t('debugger.stats_goals')} value={counts.goals} color={getGraphNodeTypeColor(GraphNodeType.GOAL)} />
      </Statbar>

      <Flex style={{ padding: "3rem 0 0.5rem 0", alignItems: "flex-start" }}>
        <FlexCol style={{ flex: 1, paddingLeft: "2rem", gap: "0.5rem" }}>
          <Flex>
            <Typography variant="h4">{t('debugger.important_nodes')}</Typography>
            <Tooltip title={helpTooltip}>
              <HelpOutline style={{ width: "1rem", height: "1rem" }} />
            </Tooltip>
          </Flex>
          <Typography>{t('debugger.important_nodes_description')}</Typography>
        </FlexCol>

        <FlexCol style={{ flex: 1, maxHeight: 300, paddingRight: "1rem", gap: "0.5rem" }} className={scrollable}>
          {
            interestingNodes.map(node => (
              <Attribute key={node.id} node={node} graph={graph} />
            ))
          }
        </FlexCol>
      </Flex>
      {
        lastUpdated ? (
          <Flex style={{ justifyContent: "flex-end", gap: "0.5rem", color: colors.reallyGrey, padding: "0.5rem 2rem 1rem 2rem" }}>
            <VersionhistoryIcon />
            <Typography variant="caption">{t('updated_at')}: {lastUpdated}</Typography>
          </Flex>
        ) : null
      }
    </FlexCol>
  );
};

export const useGraphStatistics = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (graph: any) => {
    dispatch(showModal({
      open: true,
      title: t('debugger.graph_stats_title'),
      maxWidth: 'md',
      style: { padding: 0 }, // we want control of padding on the body
    }, <GraphStatistics graph={graph} />
    ));
  };
};