/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  DropdownMenu,
  Menu,
} from "@components";
import { scrollableMixin } from "@common/scrollbar";
import styled from "styled-components";
import { GraphVizType } from "../../../providers/RuleGraphProvider";
import { useGraphVisualisation } from "../../../hooks/useGraphVisualisation";
import { useTranslation } from "react-i18next";

interface GraphTypeSelectorProps {

}

const StyledMenu = styled(Menu)`
  .menu-items {
    ${scrollableMixin};

    li { flex-shrink: 0; }
  }
`;


const GraphTypeSelector = (props: GraphTypeSelectorProps) => {
  const { t } = useTranslation();
  const {graphVizType, setGraphVizType, hasClassic, hasNebula, hasData, hasDecisionReport } = useGraphVisualisation();

  const selectType = (selectedOpt: { name: string, value: GraphVizType }) => {

    if (selectedOpt) {
      const { value } = selectedOpt;
      setGraphVizType(value);
    }
  };

  let options = [] as any;
  if (hasClassic) {
   /* options.push({
      name: "Classic",
      value: "classic",
    });*/
    options.push({
      name: t('debugger.rule_graph'),
      value: "classic-beta",
    });
  }
  if (hasNebula) options.push({
    name: t('debugger.nebula'),
    value: "nebula",
  });
  if (hasData) options.push({
    name: t('debugger.data'),
    value: 'data'
  });
  if (hasDecisionReport) options.push({
    name: t('debugger.decision_report'),
    value: 'decisionreport'
  });
  const currLabel = (() => {

    if (graphVizType) {
      const found = options.find(opt => opt.value === graphVizType);
      if (found) {
        return found.name;
      }
    }

    return t('debugger.graph_type');
  })();


  return (
    <DropdownMenu
      style={{ width: "10rem" }}
      value={currLabel}
    >
      {({ close }) => {
        const onClick = item => {
          close();
          selectType(item);
        };
        return (
          <StyledMenu
            {...{
              // search: "Search graph types...",
              items: options,
              width: "20rem",
              // height: "14rem",
              onOptionClick: onClick,
            } as any}
          />
        );
      }}
    </DropdownMenu>
  );
};

export default (GraphTypeSelector);