import React, { useMemo, useState, useEffect } from 'react';
import Panel from './Panel';
import {useSigma } from "@react-sigma/core";
import { sortBy, values, keyBy, mapValues } from "lodash";
import styled from "styled-components";
import randomColor from 'randomcolor';

const ListItem = styled.li`
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"]:not(:checked) + label {
    color: #666
  }
  input[type="checkbox"]:not(:checked) + label .circle {
    background-color: white !important;
  }
  label {
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
  label:hover {
    opacity: 0.7;
  }
  label .circle {
    flex-shrink: 0;
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    border-radius: 1.2em;
    vertical-align: middle;
    box-sizing: border-box;
    background-color: #666;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 0.2em;
    transition: all ease-out 300ms;
    border: 3px solid #666
  }
  label .node-label {
    flex-grow: 1;
  }
  label .bar {
    position: relative;
    background: #ccc;
    height: 3px;
    margin-bottom: 0.2em;
  }
  label .bar .inside-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #666;
    transition: all ease-out 300ms;
  }
`
const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  max-height: 250px;
  overflow: auto;
`
const FilterPanel = ({toggle, title, filters, filterKey, nodeKey, orphanLabel}) => {

  const sigma = useSigma();
  const graph = sigma.getGraph();

  const { nodesPerFilter, sorted } = useMemo(() => {
    let index = {};
    graph.forEachNode((_, node) => (index[node[nodeKey]] = (index[node[nodeKey]] || 0) + 1));

    return {
      nodesPerFilter: index,
      sorted: sortBy(Object.keys(index), (key) => -index[key])
    }
  }, []);

  const maxNodes = useMemo(() => Math.max(...values(nodesPerFilter)), [nodesPerFilter]);
  
  const [visibleNodes, setVisibleNodes] = useState(nodesPerFilter);
  useEffect(() => {
    // To ensure the graphology instance has up to data "hidden" values for
    // nodes, we wait for next frame before reindexing. This won't matter in the
    // UX, because of the visible nodes bar width transition.
    requestAnimationFrame(() => {
      const index = {};
      graph.forEachNode((_, node) => {
        if (node.hidden) return; // Not visible
        if (!node[nodeKey]) index['undefined'] = (index['undefined'] || 0) + 1;
       else index[node[nodeKey]] = (index[node[nodeKey]] || 0) + 1
      });
      setVisibleNodes(index);
    });
  }, [filters]);
  return (
    <Panel title={title}>
      <StyledList>
        {sorted.map((item) => {
          const nodesCount = nodesPerFilter[item];
          const visibleNodesCount = visibleNodes[item] || 0;
          let label = item;
          if (item === 'undefined') {
            label = orphanLabel || 'Undefined';
          }
          return (
            <ListItem
              key={item}
              title={`${nodesCount} items${nodesCount > 1 ? "s" : ""}${
                visibleNodesCount !== nodesCount ? ` (only ${visibleNodesCount} visible)` : ""
              }`}
            >
              <input
                type="checkbox"
                checked={filters[filterKey][item] || false}
                onChange={() => toggle(item)}
                id={`entity-${item}`}
              />
              <label htmlFor={`entity-${item}`}>
                <span className="circle" style={{ background: randomColor({seed: item}), borderColor: randomColor({seed: item}) }} />{" "}
                <div className="node-label">
                  <span>{label}</span>
                  <div className="bar" style={{ width: (100 * nodesCount) / maxNodes + "%" }}>
                    <div
                      className="inside-bar"
                      style={{
                        width: (100 * visibleNodesCount) / nodesCount + "%",
                      }}
                    />
                  </div>
                </div>
              </label>
            </ListItem>
          );
        })}
      </StyledList>
    </Panel>
  )
}
export default FilterPanel;