import React, { forwardRef } from "react";
import cn from "clsx";
import escapeRegExp from "lodash/escapeRegExp";
import DocumentIcon from "@material-ui/icons/Description";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import Tooltip from "@material-ui/core/Tooltip";
import FolderIcon from "@material-ui/icons/Folder";
//import { Badge } from "../../badge/Badge";

import stl from "./SearchAndSelectResultItem.module.scss";
//import { isStrNullOrBlank } from "../../util/UtilString";
import { Badge } from "@components/badge/Badge";
import { isStrNotNullOrBlank, isStrNullOrBlank } from "@components/util/UtilString";

interface SearchAndSelectResultItemProps {
  graphNodeId: string;
  documentFullPath: string;
  onSelectGraphNode: (graphNodeId: string) => void;
  additionalInfo?: {
    entityName?: string;
    matchingText?: string;
    highlightStr?: string;
    badgeColor?: string;
  };
}

const SearchAndSelectResultItem = forwardRef<HTMLDivElement, SearchAndSelectResultItemProps>((props, ref) => {
  const docFullPath = props.documentFullPath;
  const docName = docFullPath.substring(docFullPath.lastIndexOf("/") + 1);
  const docPathSansFileName = docFullPath.substring(0, docFullPath.lastIndexOf("/"));

  /**
   * Wraps any matching text in a span with a class name
   */
  const highlightText = (text: string, highlight: string) => {
    const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, "gi"));
    return (<>{parts.map((part, i) => (
      <span
        key={i}
        className={cn(
          { [stl.highlight]: part.toLowerCase() === highlight.toLowerCase() },
        )}
      >
        {part}
      </span>
    ))}</>);
  };

  // -- rendering

  const resultContainer = (
    documentNameOnly: string,
    documentFullPath: string,
    additionalInfo?: {
      entityName?: string;
      matchingText?: string;
      applyHighlight?: boolean;
      highlightStr?: string;
    },
  ) => {

    return (
      <div
        className={stl.containerResult}
        onClick={() => props.onSelectGraphNode(props.graphNodeId)}
      >
        {
          isStrNullOrBlank(documentFullPath)
            ?
            null
            :
            <>
              <div
                className={stl.documentName}
              >
                <DocumentIcon
                  fontSize="small"
                />
                {documentNameOnly}
              </div>
              <div
                className={stl.documentPath}
              >
                <FolderIcon />
                {documentFullPath}
              </div>
            </>
        }

        <Tooltip disableFocusListener title={additionalInfo?.matchingText || "" }>
          <div
            className={cn(stl.attributeValue, stl.clamp3Lines)}
          >
            {
              highlightText(
                additionalInfo?.matchingText || "",
                additionalInfo?.highlightStr || "",
              )
            }
          </div>
        </Tooltip>
        <div
          className={stl.badgeRow}
        >
          {
            (additionalInfo?.entityName)
              ?
              <Badge
                labelType="primary"
                bgColorOverride={props.additionalInfo?.badgeColor}
              >
                {additionalInfo.entityName}
              </Badge>
              :
              null
          }
        </div>
      </div>
    );
  };

  // const actionsContainer = () => {
  //   return (
  //     <div
  //       className={stl.containerActions}
  //     >
  //       <div
  //         className={stl.iconBtnRoundBorder}
  //         onClick={() => props.onSelectGraphNode(props.graphNodeId)}
  //       >
  //         <BubbleChartIcon />
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div ref={ref} className={stl.container}>
      {resultContainer(
        docName,
        docPathSansFileName,
        props.additionalInfo,
      )}
      {/* {actionsContainer()} */}
    </div>
  );
});

export default (SearchAndSelectResultItem);
