import { useGraphVisualisation } from '@components/GraphVisualisation/hooks/useGraphVisualisation';
import styled from 'styled-components'
import DecisionReport from '@components/DecisionReport/DecisionReport';

const Wrap = styled.div`
  width: 100%;
  height: 100%;
`;

const Report = () => {
  const { graph, report, goal } = useGraphVisualisation();

  if (!report) null;
  return (
    <Wrap>
      <DecisionReport graph={graph} goal={goal} />
    </Wrap>
  )
}

export default Report;