import { createTheme } from "@imminently/imminently_platform";
import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';

// hex color string to hsl color string
export const hexToHSL = (H, light) => {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length === 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length === 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin;
  let h = 0,
    s = 0,
    l = 0;
  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;
  h = Math.round(h * 60);
  if (h < 0) h += 360;
  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = light ?? +(l * 100).toFixed(1);
  return "hsl(" + h + "," + s + "%," + l + "%)";
};

export const colors = {
  clearlyWhite: "#FFFFFF",
  kindaBlack: "#0A0A0A",
  nearlyBlack: "#363636",
  ultimateGrey: "#767676",
  reallyGrey: "#A3A3A3",
  moreGrey: "#E5E5E5",
  hoverGrey: "#F3F3F3",
  sublteGrey: "#F9F9F9",
  prayingMantis: "#70F058",
  prayingMantis85: "#86F271",
  prayingMantis10: "#F1FEEE",
};

export const system = {
  pinkLace: "#FFE2FE",
  redBlush: "#FFEEEE",
  orangeFiesta: "#FFF2D2",
  yellowBanana: "#FEFFDB",
  greenTea: "#CBFED0",
  blueLavendar: "#ECF0FE",
  purpleSlate: "#D9D9FB",
  lilacLatte: "#F6EDFF",
  trafficRed: "#E52917",
  trafficRed85: "#E9493A",
  trafficRed10: "#FCEAE8",
  trafficAmber: "#F2A918",
  trafficAmber85: "#F4B63B",
  trafficAmber10: "#FEF6E8",
  trafficGreen: "#4FB540",
  trafficGreen85: "#6DC15E",
  trafficGreen10: "#E8F6E8",
};

// new colour palette for graph based visuals
// PROBLEM - we have our primary green, the green for boolean true, and then Pass/Satisfied, and now also "Completed" tasks
// TODO this is placeholder until a discussion with fred can confirm the colours
export const graph = {
  pass: { // booleans? greens
    light: "#DBF4E9",
    main: "#C4EDDA",
    darkText: "#027A48", // this is more like it
    dark: "#A7FDB0", // not really dark, more fluorescent
  },
  fail: { // booleans? reds
    light: "#FDE3E1",
    main: "#FBD0CD",
    darkText: "#F4736C", // this is more like it
    dark: "#FFE5A6", // why is this orange?
  },
  certain: { // other values, known/certain? blues
    light: "#E0EEFE",
    main: "#CBE3FE",
    dark: "#AABCFB",
  },
  unknownAllTime: {
    light: "#F8F8F8",
    main: "#F0F0F0",
    dark: "",
  },
  uncertain: {
    light: "#E2E2E2",
    main: "#C8C8C8",
    dark: "",
  },
  // goals are using orange?
  goal: {
    bg: "#FFF2D2",
    icon: "#FFE5A6",
  },
  // green is derived
  derived: {
    bg: "#CBFED0",
    icon: "#A7FDB0",
  },
  // blue is input
  input: {
    bg: "#D8E0FD",
    icon: "#AABCFB",
  },
};

const boxShadow = "0px 0px 1rem rgba(10, 10, 10, 0.15)";

const typography = {
  useNextVariants: true,
  fontFamily: [
    "Montserrat",
    "Nunito",
    "-apple-system",
    "BlinkMacSystemFont",
    "\"Segoe UI\"",
    "Roboto",
    "\"Helvetica Neue\"",
    "Arial",
    "sans-serif",
    "\"Apple Color Emoji\"",
    "\"Segoe UI Emoji\"",
    "\"Segoe UI Symbol\"",
  ].join(","),
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  h1: {
    fontSize: "2rem",
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h2: {
    fontSize: "1.75rem",
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h4: {
    fontSize: "1.25rem",
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h5: {
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h6: {
    fontSize: "0.75rem",
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: "uppercase",
  },
  body1: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  button: {
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: 1.5,
    textTransform: "none",
  },
  caption: {
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 1.5,
  },
};

const palette = {
  secondary: {
    main: colors.kindaBlack,
    dark: colors.ultimateGrey,
    hover: colors.hoverGrey,
    light: colors.moreGrey,
    contrastText: colors.clearlyWhite,
  },
  primary: {
    main: colors.prayingMantis,
    light: colors.prayingMantis85,
    contrastText: colors.kindaBlack,
  },
  background: {
    default: colors.clearlyWhite,
    light: colors.sublteGrey,
    dark: colors.nearlyBlack,
    border: colors.moreGrey,
    hover: colors.hoverGrey,
    darkBorder: colors.reallyGrey,
    contrastText: colors.kindaBlack,
  },
  error: {
    light: system.trafficRed10,
    main: system.trafficRed,
    dark: system.trafficRed85, // TODO this doesn't really make sense
    contrastText: colors.clearlyWhite,
  },
  warning: {
    light: system.trafficAmber10,
    main: system.trafficAmber,
    dark: system.trafficAmber85, // TODO this doesn't really make sense
    contrastText: colors.kindaBlack,
  },
  success: {
    light: system.trafficGreen10,
    main: system.trafficGreen,
    dark: system.trafficGreen85, // TODO this doesn't really make sense
    contrastText: colors.kindaBlack,
  },
  core: colors,
};

const header = {
  color: colors.clearlyWhite,
  background: colors.kindaBlack,
};

const sidebar = {
  color: colors.reallyGrey,
  light: colors.clearlyWhite,
  background: colors.kindaBlack,
  hover: colors.hoverGrey,
};

const labels = {
  Custom: system.redBlush,
  "All members": system.greenTea,
  public: system.greenTea,
  "Invite only": system.purpleSlate,
  private: system.purpleSlate,
  Developer: system.orangeFiesta,
  Author: system.pinkLace,
  Reviewer: system.yellowBanana,
  Admin: system.blueLavendar,
  Current: system.greenTea,
  Draft: system.purpleSlate,
  Numeric: system.purpleSlate,
  error: system.trafficRed85,
  step: system.trafficRed85,
  layout: system.purpleSlate,
  widget: system.trafficAmber85,
  goal: system.pinkLace,
  active: system.greenTea,
  inactive: system.trafficRed85,
  draft: system.yellowBanana,
};

const body = {
  background: colors.sublteGrey,
  boxShadow,
};

const overrides = {
  MuiCssBaseline: {
    "@global": {
      body: {
        color: colors.kindaBlack,
      },
      "@keyframes opaquePulse": {
        "0%": {
          backgroundColor: colors.moreGrey,
        },
        "50%": {
          backgroundColor: colors.hoverGrey,
        },
        "100%": {
          backgroundColor: colors.moreGrey,
        },
      },
    },
  },
  MuiButton: {
    root: {
      maxHeight: "2.5rem",
      padding: "0.5rem 1rem",
      borderRadius: "0.5rem",
      "&.Mui-disabled": {
        color: `${colors.ultimateGrey} !important`,
      },
    },
    containedPrimary: {
      "&:hover": {
        backgroundColor: `${colors.prayingMantis85}`,
      },
    },
    containedSecondary: {
      border: "1px solid",
      color: colors.kindaBlack,
      backgroundColor: "transparent",
      "&.MuiButton-containedSecondary:hover": {
        backgroundColor: `${colors.hoverGrey}`,
      },
      "&.Mui-disabled": {
        backgroundColor: "transparent",
      },
    },
    text: {
      backgroundColor: "unset",
      padding: "0.5rem 1rem",
      "&:hover": {
        backgroundColor: colors.prayingMantis10,
      },
    },
    label: {
      whiteSpace: "nowrap",
    },
    sizeSmall: {
      height: "2rem",
      padding: "0.25rem 0.75rem",
      fontSize: "0.75rem",
    },
  },
  MuiLink: {
    button: {
      fontSize: "0.75rem",
      lineHeight: "1.5",
    },
  },
  MuiSwitch: {
    root: {
      width: 48,
      height: 24,
      padding: 0,
    },
    switchBase: {
      padding: "4px 4px",
      "&$checked": {
        transform: "translateX(24px)",
        "& + $track": {
          border: `1px solid ${colors.kindaBlack}`,
          backgroundColor: colors.kindaBlack,
          opacity: 1,
        },
        "& $thumb": {
          backgroundColor: colors.clearlyWhite,
        },
      },
    },
    thumb: {
      width: 16,
      height: 16,
      backgroundColor: colors.kindaBlack,
    },
    track: {
      borderRadius: 24 / 2,
      border: `1px solid ${colors.kindaBlack}`,
      backgroundColor: colors.clearlyWhite,
      opacity: 1,
      // transition: theme.transitions.create(["background-color", "border"]),
    },
  },
  MuiChip: {
    root: {
      color: colors.kindaBlack,
      backgroundColor: colors.moreGrey,
      height: "1.5rem",
      borderRadius: "0.75rem",
      padding: "0.25rem 0.5rem",
    },
    label: {
      fontSize: "0.75rem",
      lineHeight: "1.125rem",
      padding: "0 !important",
    },
    deleteIcon: {
      width: "1.5rem",
      height: "1.5rem",
      margin: "0 -0.25rem 0 0 !important",
    },
  },
  MuiInputBase: {
    root: {
      color: colors.kindaBlack,
      alignItems: "center",
      borderRadius: "0.5rem !important",
      "&.Mui-error": {
        color: colors.trafficRed,
      },
    },
    input: {
      opacity: 1,
      height: "auto",
      lineHeight: 1.5,
      "&:focus": {
        backgroundColor: `${colors.clearlyWhite}!important`,
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      padding: "0.5rem 1rem !important",
      backgroundColor: colors.clearlyWhite,
    },
    input: {
      padding: "0 !important",
    },
  },
  MuiInputLabel: {
    outlined: {
      transform: "translate(calc(1rem), 0.75rem) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(calc(1rem), -6px) scale(0.75)",
        // semi bold
        fontWeight: 600,
      },
    },
  },
  MuiFormControl: {
    root: {
      // color: colors.reallyGrey,
      "& .MuiFormLabel-root": {
        color: colors.kindaBlack,
      },
      "& .MuiInputLabel-root": {
        color: colors.ultimateGrey,
      },
      "& fieldset": {
        padding: "0 0.75rem !important",
        borderColor: colors.reallyGrey,
        "& legend > span": {
          padding: "0 0.125rem",
          fontWeight: 600,
        },
      },
      "&:hover": {
        color: colors.kindaBlack,
        "& .MuiInputLabel-root, .MuiInputBase-root": {
          color: colors.kindaBlack,
        },
        "& fieldset": {
          borderWidth: "2px",
          borderColor: `${colors.kindaBlack}!important`,
        },
      },
      "& .MuiFormHelperText-root": {
        margin: "0 1rem",
        fontStyle: "italic",
      },
      "& .Mui-focused": {
        color: `${colors.kindaBlack}!important`,
        "& fieldset": {
          borderColor: `${colors.kindaBlack}!important`,
        },
      },
    },
  },
  MuiInputAdornment: {
    root: {
      height: "auto",
      maxHeight: "1.5rem",
    },
  },
  MuiAutocomplete: {
    endAdornment: {
      top: "calc(50% - 1rem)",
    },
  },
  MuiPaper: {
    root: {
      color: colors.kindaBlack,
      boxShadow: "none",
    },
    rounded: {
      borderRadius: "0.5rem",
    },
  },
  MuiPopover: {
    paper: {
      boxShadow,
      transform: "translateY(0.25rem) !important",
    },
  },
  MuiSkeleton: {
    root: {
      backgroundColor: colors.moreGrey,
    },
    pulse: {
      animation: "opaquePulse 1.5s ease-in-out 0.5s infinite",
    },
  },
  MuiIconButton: {
    root: {
      color: colors.kindaBlack,
      padding: "0.25rem",
      "&:hover": {
        backgroundColor: colors.hoverGrey,
      },
    },
  },
  MuiCheckbox: {
    root: {
      color: "undefined",
    },
  },
  MuiSelect: {
    root: {
      paddingRight: "1rem !important",
    },
  },
  MuiListItem: {
    root: {
      color: colors.kindaBlack,
      "& .MuiListItemIcon-root": {
        color: colors.kindaBlack,
      },
      "&:hover": {
        backgroundColor: `${colors.hoverGrey}!important`,
      },
      "&.Mui-selected": {
        backgroundColor: colors.prayingMantis10,
      },
    },
  },
  MuiSvgIcon: {
    root: {
      width: "1.5rem",
      height: "1.5rem",
    },
  },
  MuiTypography: {
    root: {
      "&:focus-visible": {
        outline: "none",
        backgroundColor: colors.hoverGrey,
        borderRadius: "0.25rem",
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: colors.kindaBlack,
      padding: "0.25rem 0.5rem",
      borderRadius: "0.25rem",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "0.75rem",
      lineHeight: "150%",
    },
  },
  MuiCardHeader: {
    action: {
      marginTop: 0,
      marginRight: 0,
    },
  },
  MuiSnackbarContent: {
    root: {
      // override as immi platform wants to use light background
      "&[data-type=\"error\"]": {
        color: colors.kindaBlack,
      }
    }
  }
};

const theme = createTheme({
  palette,
  typography,
  header,
  sidebar,
  body,
  overrides,
  labels,
  system,
  props: {},
});

export default theme;
