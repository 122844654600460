import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlowTask, flowTasksService } from 'services';
import styled from 'styled-components';

import { EmptyContent, scrollable } from '@common';
import { Stack } from '@components';
import { PhasePanel } from '@components/InteractionsDropdown';
import { LoadingDots } from '@icons';
import { getInteractionsByPhase } from '@packages/commons';
import { useParsedGraph } from '@pages/models/release/GraphContext';

import { FlowTaskForm } from '../SessionTaskModal';
import { TaskList, useFlowTasks } from './TaskList';

const Pane = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: max-content;
  gap: 0.5rem;
  height: 100%;
  align-items: stretch;
  padding: 0.5rem;
  background-color: ${(p) => p.theme.palette.background.light};

  #interactions {
    // span 4 columns
    grid-column: span 4;
    background-color: ${(p) => p.theme.palette.background.default};
    border-radius: 0.25rem;
    border: 1px solid ${(p) => p.theme.palette.background.border};
  }

  #task-list {
    grid-column: span 1;
    border-radius: 0.25rem;
  }

  #task-form {
    grid-column: span 3;
    background-color: ${(p) => p.theme.palette.background.default};
    border-radius: 0.25rem;
    border: 1px solid ${(p) => p.theme.palette.background.border};
  }
`;

// legacy - use if tasks can be made from interaction list to save a BE call
const TaskPanel = ({ taskId }: { taskId: string; }) => {
  const { t } = useTranslation();
  const { data: task, isError, isLoading } = flowTasksService.useGetOne(taskId);
  if (isLoading) return <LoadingDots />;
  if (isError) return <EmptyContent messages={["Failed to load task"]} img="" />;
  return <FlowTaskForm task={task as FlowTask} close={() => { }} />;
};

const useInteractions = (session: any) => {
  const graph = useParsedGraph();

  // requires a goal
  if (session.goal === undefined) return [];

  // make sure we use the correct graph
  const res = getInteractionsByPhase({ ...session, graph }) as Record<string, { interactions: any[] }>;
  const getPhaseTitle = (phase: string) => phase === "_nophase" ? "Unassigned" : phase;
  // result is an object of phase names, with an array of interactions
  // convert into array of objects with phase name and interactions
  return Object.keys(res)
    .map((phase, i) => ({
      id: `phase-${i}`,
      title: getPhaseTitle(phase),
      tasks: res[phase].interactions
    }))
    .filter(p => p.tasks.length > 0)
    .map((p, i) => ({
      ...p,
      order: i + 1, // this might end up being custom order
    }));
};

export const InteractionsPanel = ({ session }: { session: any }) => {
  const [selected, setSelected] = useState<FlowTask | null>(null);
  const { data: tasks, query, total, loadMore } = useFlowTasks({ session: session.id });
  const { isLoading } = query;
  const canLoad = !query.isError && !query.isFetching && (query.hasNextPage ?? false);

  const onTaskClick = (task: FlowTask) => {
    setSelected(task);
  };

  const onTaskClose = () => {
    setSelected(null);
  };

  const [interactionsExpanded, setInteractionsExpanded] = useState(false);
  const phases = useInteractions(session);
  console.log('interactions by phase', phases);

  return (
    <Pane>
      {
        phases && phases.length > 0 ? (
          <PhasePanel
            id="interactions"
            expanded={interactionsExpanded}
            toggleExpanded={() => setInteractionsExpanded(prev => !prev)}
            phases={phases}
          />
        ) : <EmptyContent id="interactions" messages={["No phase data"]} />
      }
      <TaskList
        id="task-list"
        tasks={tasks as FlowTask[]}
        total={total}
        isLoading={isLoading}
        canLoad={canLoad}
        loadMore={loadMore}
        onTaskClick={onTaskClick}
        activeId={selected ? selected.id : undefined}
      />
      {
        selected
          ? (
            <Stack id="task-form" className={scrollable}>
              {/* make sure we assign a key, otherwise it won't re-render */}
              <FlowTaskForm key={selected.id} task={selected} close={onTaskClose} />
            </Stack>
          )
          : <EmptyContent id="task-form" messages={["No task selected"]} />
      }
    </Pane>
  );
};
