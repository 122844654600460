import { ReactNode } from 'react';
import styled from 'styled-components';

import { Tooltip, TooltipProps, Typography, TypographyProps } from '@material-ui/core';
import moment from 'moment';
import { VersionhistoryIcon } from '@icons';

// status circle, one inner, one outer, color is a prop
// using 2 pseudo elements so the parent can define the size
export const StatusCircle = styled.div`
  position: relative;
  width: 1.25rem; // 20px
  height: 1.25rem; // 20px

  &::before {
    content: "";
    display: block;
    width: 1.25rem; // 20px
    height: 1.25rem; // 20px
    border-radius: 50%;
    position: absolute;
    background-color: ${p => p.color};
    opacity: 0.3;
  }

  &::after {
    content: "";
    display: block;
    width: 0.625rem; // 10px
    height: 0.625rem; // 10px
    border-radius: 50%;
    position: absolute;
    background-color: ${p => p.color};
    top: 25%;
    left: 25%;
  }
`;

export const StatusRect = styled.div`
  position: relative;
  width: 1rem; // 16px
  height: 3.25rem; // 52px

  &::before {
    content: "";
    display: block;
    inset: 0;
    border-radius: 0.25rem;
    position: absolute;
    background-color: ${p => p.color};
    opacity: 0.3;
  }

  &::after {
    content: "";
    display: block;
    inset: 0.25rem;
    border-radius: 1px;
    position: absolute;
    background-color: ${p => p.color};
  }
`;

export type ShortIDProps = {
  value: string;
  textWidth?: number;
  prefix?: ReactNode;
  suffix?: ReactNode;
  placement?: TooltipProps['placement'];
} & TypographyProps;

export const ShortID = ({ value, textWidth = 8, placement = "right", prefix = "", suffix = "", style, ...props }: ShortIDProps) => (
  <Tooltip title={value} placement={placement}>
    <Typography {...props} style={{ cursor: "pointer", ...style }}>
      {`${prefix}${value.slice(0, textWidth)}${suffix}`}
    </Typography>
  </Tooltip>
);

export type DateTimeProps = {
  value: string;
  prefix?: ReactNode;
  hideIcon?: boolean;
} & TypographyProps;

const DateText = styled(Typography)`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  font-style: italic;
`;

export const DateTime = ({ value, prefix = "", hideIcon = false, ...props }: DateTimeProps) => {
  const time = `${prefix} ${moment(value).format("MMM DD YYYY, hh:mm a")}`;
  return (
    <DateText variant="caption" {...props}>
      {hideIcon ? null : <VersionhistoryIcon />} {time}
    </DateText>
  )
}