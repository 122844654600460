import { useGraphVisualisation } from "@components/GraphVisualisation/hooks/useGraphVisualisation";
import TabView from "@components/tabs/TabView";
import { Box, FormControlLabel, FormGroup, Checkbox, Card, CardContent, Dialog } from "@material-ui/core";
//import { TempTimeline } from '@pages/decisions/DecisionDashboard/Reports/Details/TempTimeline';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { displayNodeIndexInline, EmptyContent, getNodeLabel, getNodeValue, getSCTheme, Resizable, scrollable, useCurrentRelease } from '@common';
import { get } from "lodash";
import { END_TIME, START_TIME } from "@components/TemporalValue/TempTimeline/redux";
import Switch from "@material-ui/core";
import { useState } from "react";
import NodeValue from "./NodeValue";
import moment from "moment";
import NodeInputs from "./NodeInputs";
import { FocusGraph } from "@components/FocusGraph";
import { graphlib } from 'dagre';
import TemporalValueModal from "@components/TemporalValue/TemporalValueModal";
import { TempTimeline } from "@components/TemporalValue/TempTimeline";
import TemporalValueRender from "@components/TemporalValue";
import { AttributeValue } from "@common/graph/AttributeValue";


const TEMP_TIMELINE_WRAP_CLS = 'tempTimelineWrap';

const StyledTempTimeline = styled(TempTimeline)`
  flex-grow: 1;
  height: 2rem;
  flex-shrink: 0;
  width: auto;

  >.${TEMP_TIMELINE_WRAP_CLS} { height: 100%; }
`;

const LineIndex = styled(Typography)`
  border-radius: 50%;
  background-color: ${p => getSCTheme(p).palette.secondary.main};
  color: #fff;
  width: 1.125rem;
  height: 1.125rem;
  text-align: center;
  line-height: 1.125rem;
  flex-shrink: 0;
`;
const StyledFocusGraph = styled(FocusGraph)`
  height: 100%;
`
const LineValuesWrap = styled.div`
  margin-top: 0.75rem;

  ol {
    font-size: 0.75rem;
    font-weight: 400;
  }

  ol li {
    cursor: pointer;
    padding: 0.25rem;
  
    &:hover {
      background-color: ${p => p.theme.palette.background.hover};
      border-radius: 0.25rem;
    }

    .MuiTypography-root {
      text-decoration: underline;
    }
  
    >div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > *:last-child {
        max-width: 50%;
        text-wrap: wrap;
      }
    }
  }
`;

const Timeline = ({ inputs, showTimelineModal, graph }) => {
  const { setSelectedNodeId } = useGraphVisualisation();

  return (
    <Box
      display='flex'
      flexDirection='column'
      gridGap='0.25rem'
      className={scrollable}
      overflow='auto !important'
      height='100%'
    >
      {inputs.map((node, i) => {
        let value = getNodeValue(node);
        // The below code assumes that all values are temporal, except the backend flattens all static values out of temporal
        // So rebuild from here
        if (!get(value, 'temporal')) {
          // Rebuild
          value = {
            temporal: {
              ranges: [{
                r: {
                  start: START_TIME,
                  end: END_TIME,
                },
                v: value
              }]
            }
          }
        }

        return (
          <Box display="flex" key={i} gridGap="1rem" alignItems="center" className={TEMP_TIMELINE_WRAP_CLS} >
            <LineIndex variant='caption'>{i + 1}</LineIndex>
            <TemporalValueRender onPopUp={showTimelineModal} onClick={() => setSelectedNodeId(node.path || node.id)} node={node} value={value} />
          </Box>
        );
        return (
          useVisual ? (
            <Box display="flex" key={i} gridGap="1rem" alignItems="center" className={TEMP_TIMELINE_WRAP_CLS} >
              <LineIndex variant='caption'>{i + 1}</LineIndex>
              <StyledTempTimeline node={node} onClick={showTimelineModal} value={value} usePercentageWidth title={getNodeLabel(node)} />
            </Box>
          ) : (
            <Box key={i} gridGap="1rem" alignItems="center" className={TEMP_TIMELINE_WRAP_CLS} >
              <Box>
                <strong>{getNodeLabel(node)}</strong>
              </Box>
              <Box>
                {value.temporal.ranges.map((r, rIndex) => {
                  let prop = { node: { input: r.v }, showType: false }
                  let prefix = '';
                  if (r.r.start === START_TIME && r.r.end === END_TIME) prefix = 'Always ';
                  else if (r.r.end === END_TIME) prefix = ' and from then on ';
                  else prefix = `until ${moment(r.r.end).format('l')} `
                  return (
                    <>
                      {prefix}
                      <NodeValue {...prop} />
                    </>
                  )
                })}

              </Box>
            </Box>
          )
        )
      })}
      <LineValuesWrap>
        <Typography variant='h6'>Values</Typography>

        <ol>
          {inputs.map((node, i) => {
            return (
              <li key={i} onClick={() => { setSelectedNodeId(node.path || node.id) }}>
                <div>
                  <Typography variant="caption">
                    {node.description} {displayNodeIndexInline(node, graph, { inBracket: true })}
                  </Typography>
                  <AttributeValue node={node} showType={false} hideTemporal={true} />
                </div>
              </li>
            )
          }
          )}
        </ol>
      </LineValuesWrap>

    </Box>
  )
}

const StyledCardContent = styled(CardContent)`
  height: 100%;

  #simple-tabpanel-2 {
    height: calc(100vh - 245px);

    >div { height: 100%; }
  }
`;

export const AttributeValueTabs = () => {
  const { graph, selectedNode, setSelectedNodeId } = useGraphVisualisation();
  if (!selectedNode) return null;
  const [timelineOpen, setTimelineOpen] = useState(false);
  const [timelineNode, setTimeLineNode] = useState();

  // Get the inputs to this node
  const items = (graph.successors(selectedNode.path || selectedNode.id) || []).map(child => {
    const node = graph.node(child);
    if (node.hidden) return;
    return node;
  }).filter(x => x);

  if (items.length === 0) {
    return (
      <EmptyContent style={{ height: "100%" }} messages={["No input values to display"]} />
    );
  }

  const showTimelineModal = (node) => {
    let value = getNodeValue(node);
    if (!value || !value.temporal) {
      setSelectedNodeId(node.path || node.id)
      return; // Only show if it has temporal values

    }
    setTimeLineNode(node);
    setTimelineOpen(true)
  };

  return (
    <>
      <TabView content={[
        {
          label: 'Timeline',
          component: <Timeline inputs={items} showTimelineModal={showTimelineModal} graph={graph} />
        },
        {
          label: "List",
          component: <NodeInputs node={selectedNode} showIntro={false} />
        },
        {
          label: "Graph",
          component: <StyledFocusGraph graph={graphlib.json.write(graph)} focusNode={selectedNode} />
        }
      ]} />
      <Dialog open={timelineOpen} >
        <TemporalValueModal close={() => setTimelineOpen(false)} node={timelineNode} />
      </Dialog>
    </>
  );
};

const AttributeValues = () => {
  return (
    <Card style={{ height: '100%', overflow: 'auto' }}>
      <StyledCardContent className={scrollable}>
        <AttributeValueTabs />
      </StyledCardContent>
    </Card>
  );
};

export default AttributeValues;
