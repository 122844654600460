import React from "react";
import styled from "styled-components";

import {
  Card, CardContent, Typography
} from "@material-ui/core";


const EmptyImage = styled.img`
  width: 50%;
`;
const EmptyWrapper = styled.div`
  text-align: center;
`;

const DEFAULT_SRC = "/images/norecords.jpg";

const NoRecords = ({title, body, src = DEFAULT_SRC}) => {
  return (
    <Card>
      <CardContent>
        <EmptyWrapper>
          <Typography variant="h5">{title || "No Records"}</Typography>
          <Typography variant="body1">{body || "No records were found. If this is in error please contact support"}</Typography>
          <EmptyImage src={src} />
        </EmptyWrapper>
      </CardContent>
    </Card>
  );
};
export default NoRecords;