// @ts-nocheck Interview SDK types need major updating
import { useEffect } from "react";
import { Interview, Content, Frame, InterviewContext, Menu } from "@decisively-io/interview-react-material";
import { SessionInstance, getCurrentStep } from "@decisively-io/interview-sdk";
import { debounce, EmptyContent } from "@common";
import { useFormContext } from "react-hook-form";

const HookFormContent = ({ sendDataUpdates, ...props }: any) => {
  const { setValue } = useFormContext();
  const onDataChange = (data, name) => {
    if (sendDataUpdates) {
      console.log('onDataChange', data, name);
      setValue(`interviewData.${name}`, data[name]);
    }
  }
  return <Content {...props} onDataChange={onDataChange} />;
};

/** Custom variant of Interview that only displays the side step menu if there are steps or is not last step. */
export class SessionInterviewForm extends Interview {
  render() {
    const {
      state: {
        backDisabled,
        isSubmitting,
        nextDisabled,
        isRequestPending,
      },
      props: { controlComponents, session, interviewProvider },
      props,
      __setCurrentStep,
      __back,
      __next,
    } = this;

    if (session?.status === "error") {
      return (
        <EmptyContent
          messages={[session?.message || session?.screen?.controls?.[0]?.text || "An error has occured. Please contact support"]}
          img=""
          variant="h5"
          style={{ minHeight: 300 }}
        />
      );
    }

    if (props?.resetState?.type === "resetting" || !session?.sessionId) {
      // return <EmptyContent messages={["Loading session..."]} img="" variant="h5" style={{ minHeight: 300 }} />;
      return null;
    }

    const { steps, screen, progress, status } = session;
    const currentStep = getCurrentStep({ steps } as any);

    const menuProps: MenuProps = {
      status,
      stages: steps,
      progress,
      onClick: __setCurrentStep,
    };

    if(!steps) {
      return <EmptyContent messages={["Error invalid interview data"]} img="" variant="h5" style={{ minHeight: 300 }} />;
    }

    const singleStep = steps.length === 1;
    const lastStep = this.isLastStep && this.isLastStep(steps, screen?.id) && status === "in-progress";

    return this.renderWrapper(
      <Frame
        className="frame"
        contentJSX={
          <HookFormContent
            // use screen id as key, as it will re-render if the screen changes
            key={screen.id}
            step={currentStep}
            screen={screen}
            chOnScreenData={session.chOnScreenData}
            next={singleStep || lastStep ? undefined : __next}
            back={singleStep ? undefined : __back}
            backDisabled={isRequestPending || (this.isFirstStep && this.isFirstStep(steps, screen?.id)) || backDisabled || session.externalLoading}
            isSubmitting={isSubmitting}
            nextDisabled={isRequestPending || nextDisabled || lastStep || session.externalLoading}
            controlComponents={controlComponents}
            sendDataUpdates={singleStep || lastStep}
            interviewProvider={interviewProvider}
          />
        }
        menuJSX={singleStep ? undefined : <Menu {...menuProps} />}
      />
    );
  }
}
