import { find } from 'lodash';
import { ReactNode } from 'react';
import { colors, graph, system } from 'theme';

import { countDecimals, moment, roundToMaxDigitsAfterDot } from '@common/util';
import { Tooltip } from '@components';
import TemporalValueRender from '@components/TemporalValue';
import {
  AutogenpenIcon, AutoIcon, BooleanIcon, CalendarIcon, CalendartimeIcon, CheckboxIcon,
  CurrencyIcon, FunctionsIcon, ImplementationIcon, Level1Icon, RadiobuttonIcon, TextfieldIcon,
  TimeIcon, TypographyIcon, WarningIcon
} from '@icons';
import { GraphNode, ValueType } from '@packages/commons';

import { getValueAtDate } from './temporals';
import { GraphNodeType } from './utils';

export const getAttributeIconAsComponentType = (attribTyp: ValueType, defaultRet?: any) => {
  switch (attribTyp as string || "") {
    case "datetime": return (CalendartimeIcon);
    case "date": return (CalendarIcon);
    case "time": return (TimeIcon);
    case "text": return (TextfieldIcon);
    case "typography": return (TypographyIcon);
    case "boolean": return (CheckboxIcon);
    case "currency": return (CurrencyIcon);
    case "options": return (BooleanIcon); // badly named but it looks good
    case "entity": return (ImplementationIcon);
    case "radio": return (RadiobuttonIcon);
    case "number": return (Level1Icon);
    case "number_of_instances": return (FunctionsIcon);
    case "auto": return (AutoIcon);
    default:
      return (defaultRet || null);
  }
};

export const getAttributeIcon = (type: ValueType, defaultIcon: any = (WarningIcon)) => {
  switch (type as string ?? "") {
    case "datetime": return (CalendartimeIcon);
    case "date": return (CalendarIcon);
    case "time": return (TimeIcon);
    case "text": return (TextfieldIcon);
    case "typography": return (TypographyIcon);
    case "boolean": return (CheckboxIcon);
    case "currency": return (CurrencyIcon);
    case "options": return (BooleanIcon); // badly named but it looks good
    case "entity": return (ImplementationIcon);
    case "radio": return (RadiobuttonIcon);
    case "number": return (Level1Icon);
    case "number_of_instances": return (FunctionsIcon);
    case "auto": return (AutogenpenIcon);
    default:
      return defaultIcon;
  }
};

export const getGraphNodeTypeColor = (type: GraphNodeType) => {
  switch (type) {
    case GraphNodeType.INPUT: return system.purpleSlate;
    case GraphNodeType.DERIVED: return system.orangeFiesta;
    case GraphNodeType.GOAL: return system.greenTea;
    case GraphNodeType.IDENTIFIER: return system.redBlush;
    default:
      return "#FF00FF"; // glowing pink/purple makes it obvious
  }
};

export const getNodeValue = (node) => {
  // we want the input to override, this is what the BE does
  return typeof node.input === 'undefined' ? node.derived : node.input;
  // legacy
  // return typeof node.derived === 'undefined' ? node.input : node.derived;
};

export const NodeDisplayType = {
  /** undefined */
  UNKNOWN: 'Unknown',
  /** null */
  UNCERTAIN: 'Uncertain',
  TEMPORAL: 'Temporal',
  BOOLEAN: 'Boolean',
  STRING: 'String',
  NUMBER: 'Number',
  ENTITY: 'Entity',
  FILE: 'File',
  DATE: 'Date',
} as const;

export type TNodeDisplayType = typeof NodeDisplayType[keyof typeof NodeDisplayType];

export const getNodeDisplayData = (node: GraphNode, temporalSlice = undefined) => {
  // allow us to override the value as we might be able to cast he value
  let value = getNodeValue(node);
  // default to unknown
  let type: TNodeDisplayType = NodeDisplayType.UNKNOWN;
  let display: ReactNode = NodeDisplayType.UNKNOWN;
  let tooltip: string = "";
  let color: string = graph.unknownAllTime.dark;
  let backgroundColor: string = graph.unknownAllTime.light;
  // const icon = getAttributeIcon(node.value.type);

  // if value is undefined, the defaults are correct
  if (typeof value !== 'undefined') {
    if (value === null) {
      display = NodeDisplayType.UNCERTAIN;
      type = NodeDisplayType.UNCERTAIN;
    } else if (typeof value === 'string') {
      const valueLower = value.toLowerCase();
      if (valueLower === "true" || valueLower === "false") {
        // is actually boolean, convert it
        value = valueLower === "true";
        display = String(value);
        type = NodeDisplayType.BOOLEAN;
      } else if (node.type === "date") {
        display = value.replace(/T.*$/, "");
        type = NodeDisplayType.DATE;
      } else if (value.startsWith('data:')) {
        // normalise it into this format
        value = { fileRefs: [value] };
        display = "File"; // we won't use this, as we need the filename, but don't keep it as unknown
        type = NodeDisplayType.FILE;
      } else {
        display = value;
        type = NodeDisplayType.STRING;
      }
    } else if (typeof value === 'number') {
      display = roundToMaxDigitsAfterDot(value);
      type = NodeDisplayType.NUMBER;
      if (countDecimals(value) > 4) {
        tooltip = String(value);
      }
    } else if (value.temporal) {
      type = NodeDisplayType.TEMPORAL;
      if (temporalSlice) {
        display = getValueAtDate(value.temporal, temporalSlice);
        tooltip = `Displaying temporal value at ${moment(temporalSlice).format('YYYY-MM-DD')}`
      } else {
        display = <TemporalValueRender node={node} value={value} />;
      }
    } else if (Array.isArray(value.fileRefs)) {
      display = "File"; // we won't use this, as we need the filename, but don't keep it as unknown
      type = NodeDisplayType.FILE;
    } else if (typeof value === "boolean") {
      display = String(value);
      type = NodeDisplayType.BOOLEAN;
    } else {
      display = String(value);
      type = NodeDisplayType.ENTITY;
    }
  }

  switch (type as TNodeDisplayType) {
    case NodeDisplayType.BOOLEAN:
      color = value ? graph.pass.darkText : graph.fail.darkText;
      backgroundColor = value ? graph.pass.light : graph.fail.light;
      break;
    case NodeDisplayType.UNCERTAIN:
      color = graph.uncertain.dark;
      backgroundColor = graph.uncertain.light;
      break;
    case NodeDisplayType.UNKNOWN:
      color = graph.unknownAllTime.dark;
      backgroundColor = graph.unknownAllTime.light;
      break;
    default:
      // certain, everything else
      color = colors.kindaBlack;
      backgroundColor = graph.certain.light;
      break;
  }

  return { value, type, display, tooltip, color, backgroundColor };
};

export const getIndexForNode = (node, graph) => {
  if (!node.index || !graph) return;
  // See if there is a value for this identifier in the graph
  let identifier = find(graph._nodes, (n) => {
    if (n && n.identifier && node.entity === n.entity && node.index == n.index) {
      // Check pathing
      if (node.parent_path) {
        if (n.parent_path === node.parent_path) return true;
        else return false;
      }
      return true;
    }
    return false;
  })
  if (identifier && getNodeValue(identifier) !== undefined) {
    return {
      value: getNodeValue(identifier),
      hint: node.index,
      identifier: identifier.description
    }
  }
  return {
    value: node.index,
    identifier: identifier?.description
  }
};

export const getIndexForNodeTooltip = ({ hint, identifier }) => {
  let tooltip = `ID: ${hint}`;
  if (identifier) tooltip += ` (displayed using the value of "${identifier}")`;
  return tooltip;
};

export const displayNodeIndexInline = (node, graph, opts: any = {}) => {
  let indexInfo = getIndexForNode(node, graph);
  if (!indexInfo) return '';

  if (indexInfo.hint) {
    return (
      <Tooltip title={getIndexForNodeTooltip(indexInfo as any)}>
        <span>{opts.inBracket ? '(' : ''}{indexInfo.value}{opts.inBracket ? ')' : ''}</span>
      </Tooltip>
    )
  } else return `${opts.inBracket ? '(' : ''}${node.index}${opts.inBracket ? ')' : ''}`;
};

export const getNodeLabel = (node, indexInfo) => {
  if (node.index && indexInfo && indexInfo.hint) {

    return (
      <Tooltip title={getIndexForNodeTooltip(indexInfo)}>
        <span>{node.description} ({indexInfo.value})</span>
      </Tooltip>
    )
  } else return typeof node.index !== 'undefined' ? `${node.description} (${node.index})` : node.description;;
};

export const getContainedBy = (path: string, graph?: any) => {
  if (!path) return '';
  let els = path.split('/');
  els.pop(); // Remove the attribute
  if (els.length === 0) {
    // This shouldn't happen - the path only had the attribute
    return;
  } else if (els.length <= 2) {
    // It's an entity/index path. It's not contained by anything so return nothing
    return;
  } else {
    els.pop(); // Pop off the index
    els.pop(); // Pop off the entity
    // We have to try and work out the identifiers
    let hasIdentifier = false;
    const cleaned = els.map((el, index) => {
      if (index % 2) {
        // Check if this instance should be referenced by the identifier
        let info = getIndexForNode({
          entity: els[index - 1],
          index: el,
          parent_path: els.length > 2 ? els.slice(0, index - 2).join('/') : undefined
        }, graph)
        if (info && info.hint) {
          hasIdentifier = true;
          return info.value;
        } else return el;
      } else {
        // It's an entity
        // No need to clean these
        return el;
      }
    })

    return `Contained by: ${cleaned.join('/')} ${hasIdentifier ? `(${els.join('/')})` : ''}`;
  }
};