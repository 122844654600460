import { BackendResource } from "@imminently/immi-query";
import { Task, TaskPriority, TaskStatus } from "@packages/commons";
import { TEditableProps } from "@udecode/plate-core";
import { system, colors } from "theme";

export type SelectOption<T = any> = {
  label: string;
  value: T;
};

export const statusOptions: SelectOption<TaskStatus>[] = [
  { label: "Not Started", value: "not-started" },
  { label: "In Progress", value: "in-progress" },
  { label: "Review", value: "review" },
  { label: "Approved", value: "approved" },
  { label: "Closed", value: "closed" },
  { label: "Rejected", value: "rejected" },
  { label: "Blocked", value: "blocked" },
];

export const priorityOptions: SelectOption<TaskPriority>[] = [
  { label: "Low", value: "low" },
  { label: "Medium", value: "medium" },
  { label: "High", value: "high" },
  { label: "Urgent", value: "urgent" },
];

export const sortOptions: SelectOption[] = [
  {
    label: "Due Date",
    value: "dueDate",
  },
  {
    label: "Priority",
    value: "priority",
  },
  {
    label: "Status",
    value: "status",
  },
];

/** Maps priority to a system color */
export const priorityColor = {
  low: colors.ultimateGrey,
  medium: system.purpleSlate,
  high: system.trafficAmber85,
  urgent: system.trafficRed85,
};

/** Maps priority to an ordered number */
export const priorityNumber = {
  low: 1,
  medium: 2,
  high: 3,
  urgent: 4,
};

/** Maps task status to a system color */
export const statusColor = {
  notstarted: "#767676",
  inprogress: "#E0C4FF",
  'not-started': "#767676",
  'in-progress': "#E0C4FF",
  review: system.pinkLace,
  approved: "#60AE8C",
  complete: "#60AE8C",
  closed: colors.moreGrey,
  rejected: system.trafficRed85,
  blocked: system.trafficAmber85,
};

/** Maps task status to an ordered number */
export const statusNumber = {
  notstarted: 1,
  inprogress: 2,
  review: 3,
  approved: 4,
  closed: 5,
  rejected: 6,
  blocked: 7,
};

export type FormTask = Partial<Omit<Task, 'status' | 'priority' | 'dueDate'>> & {
  status: SelectOption;
  priority: SelectOption;
  dueDate?: Date;
};

export type FlowFormTask = Partial<Omit<Task, 'status' | 'priority' | 'dueDate'>> & {
  status: SelectOption;
  priority: SelectOption;
  dueDate?: Date;
  justification: string;
  interviewData?: any[]; // interview data
};

export const newParagraph = (text: string) => ({ type: "paragraph", children: [{ text }] });

export const createNewTask = (type: string): FormTask => ({
  type,
  title: "",
  description: [newParagraph("")],
  status: statusOptions[0],
  priority: priorityOptions[0],
  tags: [],
  assignees: [],
  assigneesData: [],
  dueDate: new Date(Date.now()),
  comments: [],
});

export const createFormTask = (task: Task, type?: string): FormTask => ({
  ...createNewTask(type ?? task.type), // add defaults
  ...task, // add overrides we have
  // convert to FE values
  description: task.description
    ? (typeof task.description === "string" ? [newParagraph(task.description)] : task.description)
    : [newParagraph("")],
  status: statusOptions.find((option) => option.value === task.status) || statusOptions[0],
  priority: priorityOptions.find((option) => option.value === task.priority) || priorityOptions[0],
  dueDate: task.dueDate ? new Date(task.dueDate) : undefined,
});

export const createFlowTask = (task: Task): FlowFormTask => ({
  ...task, // add overrides we haveassignees: [],
  assignees: [],
  assigneesData: [],
  status: statusOptions.find((option) => option.value === task.status) || statusOptions[0],
  priority: priorityOptions.find((option) => option.value === task.priority) || priorityOptions[0],
  dueDate: task.dueDate ? new Date(task.dueDate) : undefined,
  justification: "",
});

export const editableProps = (props?: TEditableProps) => ({
  autoFocus: false,
  spellCheck: false,
  ...props,
});

export const validateTask = (task: BackendResource<Task>): Task => {
  // TODO what do i even add to validate and what do i do if it fails?
  return task as Task;
}
