import { forEach } from 'lodash';
import { moment } from "@common/util";

export const isTemporal = arg => (
  typeof arg === 'object' &&
  arg !== null &&
  'temporal' in arg
);

export const getValueAtDate = (temporal, date) => {
  if (!temporal || !date || !temporal.ranges) return null;
  let value: any = null;
  let cmpdate = moment(date);
  forEach(temporal.ranges, (r => {
    if (cmpdate <= moment(r.r.end) && cmpdate >= moment(r.r.start)) {
      value = r.v;
      return false;
    }
  }))
  return value;
};