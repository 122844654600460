import React from 'react';
import styled from 'styled-components';
import { Phase } from './types';
import { PhaseHeading } from './PhaseHeading';
import { PhaseTaskCard } from './PhaseTaskCard';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 25rem;
  flex-shrink: 0;
`;

export type PhaseColumnProps = {
  phase: Phase;
  expanded: boolean;
};

export const PhaseColumn: React.FC<PhaseColumnProps> = p => {
  const { phase, expanded } = p;

  return (
    <Column key={phase.id}>
      <PhaseHeading phase={phase} />
      {
        expanded === false ? null : phase.tasks.map(task => (
          <PhaseTaskCard key={task.attribute} task={task} />
        ))
      }
    </Column>
  );
};