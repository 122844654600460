/* eslint-disable camelcase */
import React from 'react';
import {
  TableBody, TableRow, TableCell, Typography,
} from '@material-ui/core';
import NodeValue from './NodeValue';
import { useGraphVisualisation } from '@components/GraphVisualisation/hooks/useGraphVisualisation';
import { getIndexForNode, getNodeLabel } from '@common';
import { AttrLink, StyledTable, StyledTableHead } from './AttributeExplanation';
import { AttributeValue } from '@common/graph/AttributeValue';

export const NodeHistory = ({node}) => {
  return (
    <>
      <h5>Historical Value</h5>
      <span>This attribute's value changed during the course of being solved, likely due to a <em>repeat</em> function. Its values per iteration were:</span>
      <StyledTable>
        <StyledTableHead>
          <TableRow>
            <TableCell>Iteration</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {node.history.map((h, index) => (
            <TableRow key={index}>
              <TableCell>{index}</TableCell>
              <TableCell><NodeValue node={{
                derived: h
              }} showType={false}/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </>
  )
}
const NodeInputs = ({ node, showIntro = true }) => {
  const { graph, setSelectedNodeId } = useGraphVisualisation();
  if (!graph || !node) return null;

  const successors = graph.successors(node.path || node.id);
  if (successors && successors.length > 0) {

    // This has been passed with a proper graph.
    // Need to get the children of this node
    return (
      <>
        {showIntro && <Typography variant='body1'>This goal is satisified by</Typography>}
        <StyledTable>
          <StyledTableHead>
            <TableRow>
              <TableCell>Results</TableCell>
              <TableCell>Attribute</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {successors.map(c => {
              // const my_sucs = graph.successors(c);
              const sub_node = graph.node(c);

              if (sub_node.hidden) return null;

              return (
                <TableRow key={c}>
                  <TableCell><AttributeValue showType node={sub_node} /></TableCell>
                  <TableCell><AttrLink onClick={() => setSelectedNodeId(c)}>{getNodeLabel(sub_node, getIndexForNode(sub_node, graph))}</AttrLink></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </>
    );
  }
  let inputText = <span>This is an input node and is derived from user or system inputs</span>;
  if (node.history) return (
    <>
      {inputText}
      <NodeHistory node={node}/>
    </>
  );
  return inputText;

};
export default NodeInputs;
