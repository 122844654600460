/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  DropdownMenu,
  Menu,
} from "@components";
import { scrollableMixin } from "@common/scrollbar";
import styled from "styled-components";
import { useGraphVisualisation } from "../../../hooks/useGraphVisualisation";

const StyledMenu = styled(Menu)`
  .menu-items {
    ${scrollableMixin};
    li { flex-shrink: 0; }
  }
`;

const NodeLimitSelector = (props: {}) => {

  const {
    setNodeRenderLimit,
    nodeRenderLimit,
  } = useGraphVisualisation();

  const options = [100, 200, 300, 400, 600, 800, 1000].map((i) => ({
    name: i.toString(),
    value: i,
  })) as any;

  // -- func

  const selectItem = (selectedOpt: { name: string, value: string }) => {

    if (selectedOpt) {
      const { value } = selectedOpt;
      setNodeRenderLimit(Number(value));
    }
  };

  const currLabel = (() => {

    if (nodeRenderLimit) {
      return (`Max ${nodeRenderLimit}`);
    }

    return "Limit";
  })();

  // -- rendering

  return (
    <DropdownMenu
      style={{ width: "8.5rem" }}
      value={currLabel}
    >
      {({ close }) => {
        const onClick = item => {
          close();
          selectItem(item);
        };
        return (
          <StyledMenu
            {...{
              items: options,
              width: "8.5rem",
              // height: "14rem",
              onOptionClick: onClick,
            } as any}
          />
        );
      }}
    </DropdownMenu>
  );
};

export default (NodeLimitSelector);