import React, { useMemo } from 'react';
import { GraphNode, ParsedRuleGraph, RawRuleGraph } from "@packages/commons";
import { parseRawGraph } from "../../../components/RuleGraph_FullRelease/__parseRawGraph";
import { useFullRelease, useFullReleaseStrict } from '@common/hooks_useFullRelease';
import graphlib from "@dagrejs/graphlib";

// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

/**
 * @deprecated Use the useGraph hook instead
 */
// @ts-ignore
const GraphContext = React.createContext();

export const useGraph = () => {
  const context = React.useContext(GraphContext)
  if (context === undefined) {
    return null;
    // unreachable...
    throw new Error('useGraph must be used within a GraphContext')
  }
  return (context ?? null) as RawRuleGraph; // TODO not great typing!!
}

export const ParsedGraphContext = React.createContext<ParsedRuleGraph>(null as unknown as ParsedRuleGraph);

/** A graph context provider that requires a full release and provides a fully parsed graph */
export const ParsedGraphContextProvider = ({ children }) => {
  const release = useFullRelease();

  const graph = useMemo<ParsedRuleGraph>(() =>
    release ? parseRawGraph(release.rule_graph) : new graphlib.Graph(),
    [release?.rule_graph]
  );

  return (
    <ParsedGraphContext.Provider value={graph}>
      {children}
    </ParsedGraphContext.Provider>
  );
};

export const useParsedGraph = () => {
  const context = React.useContext(ParsedGraphContext);
  if (context === undefined) {
    throw new Error('useParsedGraph must be used within a ParsedGraphContext')
  }
  return context;
};

export default GraphContext;
