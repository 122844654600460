import { AutoFitGraph, Resizable } from "@common";
import React, { useEffect } from "react";
import ReactFlow, { ControlButton, Controls, isNode, ReactFlowProvider } from "react-flow-renderer";
import styled from "styled-components";
import dagre, { graphlib } from "dagre";
import { nodeTypes } from "@components/RuleGraph_FullRelease/__nodeTypes";
import { cloneDeep, find } from "lodash";
import SearchRuleGraphNodes from "@components/GraphVisualisation/components/search/SearchRuleGraphNodes";
import GoalMenu from "@pages/models/release/GoalMenu";

const StyledControls = styled(Controls)`
  bottom: 10px;
  top: inherit;
`;

const GraphBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  position: relative;
`;

const StyledFlow = styled(ReactFlow)`
  .react-flow__node {
    width: 230px;
  }
`;

const Toolbar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /* padding: 0.5rem 1rem 0.5rem 0; */
  position: absolute;
  left: 0;
  right: 0;
  z-index: 5;
  gap: 1rem;
`;
const SecondToolbar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /* padding: 0.5rem 1rem 0.5rem 0; */
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  z-index: 5;
  gap: 1rem;
`;
const MAX_DEPTH = 3;
const GRAPH_STYLES = { width: "100%" /* , minHeight: '600px' */ };
const NODE_WIDTH = 200;
const NODE_HEIGHT = 50;

function getTextMetrics(text, font) {
  // re-use canvas object for better performance
  const canvas = getTextMetrics.canvas || (getTextMetrics.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics;
}
const buildNode = (node, graph, typeFunc, labelFunc) => {
  if (!node) return;
  let metrics = getTextMetrics(node.description, "Segoe UI, Roboto");
  let type;
  if (typeFunc) type = typeFunc(node);
  else {
    if (graph.predecessors(node.id).length === 0) type = "goal";
    else if (graph.successors(node.id).length === 0) type = "source";
    else type = "intermediate";
  }
  let label = node.description;
  if (labelFunc) {
    label = labelFunc(node);
  }
  return {
    id: node.id,
    type,
    data: {
      label: label,
      id: node.id,
      node: node,
    },
    targetPosition: "top",
    sourcePosition: "bottom",
    width: NODE_WIDTH,
    height: Math.max(NODE_HEIGHT, (label.length / 12) * 25),
  };
};

const buildMore = (id, dir = "child") => {
  return {
    id: `${id}_more${dir === "parent" ? "_parent" : ""}`,
    type: "more",
    data: {
      label: "+",
      dir: dir,
      id: id,
    },
    targetPosition: dir === "child" ? "top" : "bottom",
    sourcePosition: dir === "child" ? "bottom" : "top",
    width: 20,
    height: 20,
  };
};

const layoutGraph = (graph, ignoreLayout = false) => {
  let nodes = [];
  let edges = graph.edges().map((e) => {
    return {
      id: `${e.v}-${e.w}`,
      source: e.v,
      target: e.w,
    };
  });
  if (!ignoreLayout) dagre.layout(graph);

  nodes = graph.nodes().map((id) => {
    let node = graph.node(id);
    if (!node) return;
    return {
      ...node,
      style:
        node.type === "more"
          ? {
              // weird glitch where the more nodes have to have the style set explicitly
              width: node.width,
              height: node.height,
              padding: 0,
            }
          : undefined,
      position: {
        x: node.x - node.width / 2 + Math.random() / 1000, // Bug in React Flow - we have to have a slight variation in position otherwise it won't rerender the screen in certain circumstances
        y: node.y - node.height / 4,
      },
    };
  });
  return [...nodes, ...edges];
};

const GraphExplorer = ({ focusNode, graph, onRawNodeClick }) => {
 // const graph = graphlib.json.read(raw);
  const [visibleNodes, setVisibleNodes] = React.useState(new Set());
  const [currentNodeId, setCurrentNodeId] = React.useState(focusNode.id);
  const [runOnce, setRunOnce] = React.useState(false);
  const [positionalGraph, setPositionalGraph] = React.useState();
  const [flowGraphElements, setFlowGraphElements] = React.useState([]);

  const node = graph.node(currentNodeId);

  const sidebarResizer = focusNode
    ? {
        defaultSize: "70%",
        pane1Style: { minWidth: "50%", maxWidth: "75%", paddingBottom: "2rem" },
        pane2Style: { minWidth: "25%", maxWidth: "50%", height: "100%", paddingBottom: "2rem", marginTop: "-1rem" },
      }
    : {
        defaultSize: "100%",
        pane1Style: { minWidth: "100%", maxWidth: "100%", paddingBottom: "2rem" },
      };

  useEffect(() => {
    // Build out the subset of the graph we are showing
    let newGraph = new dagre.graphlib.Graph();
    newGraph.setDefaultEdgeLabel(() => ({}));
    newGraph.setGraph({
      nodesep: 50,
      rankdir: "tight-tree",
    });

    /*// Determine which nodes we want to show.
    // By default we grab the predessors (up 2 levels) and successors (down 2 levels)
    const processChildren = (id, depth, goUp) => {
      let node = graph.node(id);
     // let textWidth = getTextMetrics(node.description, 'Roboto');
      newGraph.setNode(id, buildNode(node, graph));
      let children = goUp ? graph.predecessors(id) : graph.successors(id);

      if (goUp && graph.successors(id)) {
        // On the way up show all children
        graph.successors(id).forEach(c => {
          processChildren(c, depth, false);
          newGraph.setEdge(id, c);
        })
      }
      if (depth < MAX_DEPTH) {
        if (children.length > 0) {
          children.forEach(c => {
            processChildren(c, depth + 1, goUp);
            goUp ? newGraph.setEdge(c, id) : newGraph.setEdge(id, c);
          })
        }
      } else {
        if (children.length > 0) {
          newGraph.setNode(`${id}_more`, buildMore(id));
          goUp ? newGraph.setEdge(`${id}_more`, id): newGraph.setEdge(id, `${id}_more`)
        }
      }
    }*/
    const processChildren = (id, depth, goUp) => {
      insertNode(id, newGraph);
      if (depth < MAX_DEPTH) {
        let children = goUp ? graph.predecessors(id) : graph.successors(id);
        (children || []).forEach((c) => {
          processChildren(c, depth + 1, goUp);
        });
      }
    };
    let path = focusNode.hidden ? focusNode.id : focusNode.id;
    processChildren(path, 1);
    processChildren(path, 1, true);
    //processChildren(path, 1, true)
    dagre.layout(newGraph);
    setPositionalGraph(newGraph);
  }, []);

  useEffect(() => {
    if (positionalGraph) {
      // Update visible elements
      let visibleNodes = positionalGraph.nodes();
      setVisibleNodes(new Set(visibleNodes));
      setFlowGraphElements(layoutGraph(positionalGraph, true));
    }
  }, [positionalGraph]);

  const menuClick = (id) => {
    // See if there is a path to this node from the focus
    // The implementation of dijkstra in graphlib only parses outedges, rather than all edges (even if you change the graph to undirected)
    // Need to tell it to use nodeEdges instead to calculate
    let paths = graphlib.alg.dijkstra(graph, focusNode.id, null, function (v) {
      return graph.nodeEdges(v);
    });
    let traversal = [];
    if (paths[id].distance === Infinity) {
      // TODO: maybe still show - or tell them not possible to connect
    } else {
      // We need to traverse these paths until we get to a visible node
      const traverse = (id) => {
        if (paths[id].predecessor) {
          if (visibleNodes.has(paths[id].predecessor)) {
            // Stop processing - we got back to the graph
          } else {
            // Keep processing until we hit the visible graph
            traverse(paths[id].predecessor);
          }
          if (traversal.indexOf(paths[id].predecessor) === -1) {
            traversal.push(paths[id].predecessor);
          }
        }
      };

      traverse(id);
      let newGraph = cloneDeep(positionalGraph);

      traversal.forEach((t) => {
        insertNode(t, newGraph);
        /*// Check if this in the graph
        let posNode = newGraph.node(t);
        console.log(posNode);
        if (!posNode) {
          // Not in graph yet - we need to add it
          let parents = graph.predecessors(t);
          console.log(t, 'has', parents)
        } */
      });
      // And then add the one they requested (as traversal is just the parents)
      insertNode(id, newGraph);

      dagre.layout(newGraph);
      setPositionalGraph(newGraph);
    }
  };

  const insertNode = (id, posGraph, opts = {}) => {
    // Check if node in graph
    if (posGraph.node(id)) {
    } else {
      // Not in graph - let's add in
      let nodeDetails = buildNode(graph.node(id), graph);
      if (opts.positionalData) {
        nodeDetails.x = opts.positionalData.x;
        nodeDetails.y = opts.positionalData.y;
      }
      posGraph.setNode(id, nodeDetails);
    }
    // Having added to the graph let's connect it to it's parents (which may or may not be in the graph yet)
    let parents = graph.predecessors(id);
    let children = graph.successors(id);
    (parents || []).forEach((p) => {
      // Check if this parent node is in the graph
      let posNode = posGraph.node(p);

      if (posNode) {
        // This is in the graph - connect to it
        posGraph.setEdge(p, id);
        // Also need to check if this parent had children
        let children = graph.successors(p);
        let needsMore = false;
        children.forEach((c) => {
          // Check if these children are in the graph
          let childPosNode = posGraph.node(c);
          if (!childPosNode) needsMore = true;
        });
        if (!needsMore) {
          // We can remove this 'more' as all of the children for this node are now in the graph
          let posChildren = posGraph.successors(p);
          let more = find(posChildren, (i) => {
            if (i.includes("_more")) return true;
          });
          posGraph.removeNode(more);
        }
      } else {
        let parentMore = buildMore(id, "parent");
        if (opts.positionalData) {
          parentMore.x = opts.positionalData.x;
          parentMore.y = opts.positionalData.y - 4 * NODE_HEIGHT;
        }
        posGraph.setNode(`${id}_more_parent`, parentMore);
        posGraph.setEdge(`${id}_more_parent`, id);
      }
    });
    (children || []).forEach((c) => {
      // Check if this child is in the graph
      let posNode = posGraph.node(c);
      if (posNode) {
        // Link to this
        posGraph.setEdge(id, c);
        // We need to check this childs parents to see if we need to remove a more icon
        let parents = graph.predecessors(c);
        let needsMore = false;
        parents.forEach((p) => {
          // Check if these parents are in the graph
          let parentPosNode = posGraph.node(p);
          if (!parentPosNode) needsMore = true;
        });
        if (!needsMore) {
          // We can remove this 'more' as all of the parents for this node are now in the graph
          let posParents = posGraph.predecessors(c);
          let more = find(posParents, (i) => {
            if (i.includes("_more_parent")) return true;
          });
          posGraph.removeNode(more);
        }
      } else {
        // There is a child not visible in the graph. Add a more icon so they can add it later
        let childMore = buildMore(id);
        if (opts.positionalData) {
          childMore.x = opts.positionalData.x;
          childMore.y = opts.positionalData.y + 2 * NODE_HEIGHT;
        }
        posGraph.setNode(`${id}_more`, childMore);
        posGraph.setEdge(id, `${id}_more`);
      }
    });
  };

  const onElementClick = (event, element) => {
    if (!isNode(element)) return;
    const { data, type } = element;
    if (data === undefined) return;
    if (type === "more") {
      let newGraph = cloneDeep(positionalGraph);
      let posNode = newGraph.node(data.id);
      // Get the children or parents to expand
      let children = data.dir === "child" ? graph.successors(data.id) : graph.predecessors(data.id);
      let childPixelWidth = (NODE_WIDTH + 20) * children.length;
      children.forEach((c, index) => {
        insertNode(c, newGraph, {
          positionalData: {
            x: posNode.x - childPixelWidth / 4 + index * NODE_WIDTH,
            y: posNode.y + NODE_HEIGHT * (data.dir === "child" ? 2 : -2),
          },
        });
      });

      setPositionalGraph(newGraph);
    } else {
      // Change the right hand side to show the attribute info
      setCurrentNodeId(data.id);
    }
  };

  const onLayoutClick = () => {
    let newGraph = cloneDeep(positionalGraph);
    positionalGraph.nodes().forEach((n) => {
      let node = positionalGraph.node(n);
      delete node.x;
      delete node.y;
      newGraph.setNode(n, node);
    });
    dagre.layout(newGraph);

    setPositionalGraph(newGraph);
  };

  const onNodeDragStop = (event, node) => {
    // Update position in positionalGraph
    let posNode = positionalGraph.node(node.id);
    posNode.x = node.position.x;
    posNode.y = node.position.y;
    positionalGraph.setNode(node.id, posNode);
  };
  /*<SecondToolbar>
  <FilterBar></FilterBar>
</SecondToolbar>*/
  return (
    <GraphBox>
      <Resizable
        split="vertical"
        allowResize
        {...sidebarResizer}
      >
        <div style={{ height: "100%" }}>
          <ReactFlowProvider>
            <AutoFitGraph />
            <Toolbar>
              <GoalMenu
                graph={graph}
                includeInputs={true}
                placeHolder="Select an attribute to include"
                onGoalIdChange={menuClick}
                disabledNodes={visibleNodes}
              />
              <SearchRuleGraphNodes graph={positionalGraph} />
            </Toolbar>

            <StyledFlow
              nodesDraggable
              minZoom={0.01}
              nodesConnectable={false}
              elements={flowGraphElements}
              nodeTypes={nodeTypes}
              style={GRAPH_STYLES}
              onElementClick={onElementClick}
              onNodeDragStop={onNodeDragStop}
            >
              <StyledControls showInteractive={false}>
                <ControlButton onClick={onLayoutClick}>L</ControlButton>
              </StyledControls>
            </StyledFlow>
          </ReactFlowProvider>
        </div>
      </Resizable>
    </GraphBox>
  );
};
export default GraphExplorer;
//        <DebugSideBar showClose={false} showValues={false} node={node} graph={graph} />
