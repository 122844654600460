import React, { useCallback, useEffect, useState } from "react";
import { defaultPath, init, InterviewProvider, Session, SessionConfig, SessionInstance } from "@decisively-io/interview-sdk";
import { config } from "../../config";
import { useGetter } from "@common/hooks/HooksGeneral";
import { set } from "lodash";

function flattenPrevVisitableSteps(s: Session["steps"][0]): Array<typeof s> {
  const returnPrefix = s.skipped || s.visitable === false || (s.visited === false && s.current !== true)
    ? []
    : [s];
  if (s.steps === undefined || s.steps.length === 0) return returnPrefix;

  return returnPrefix.concat(...s.steps.map(it => flattenPrevVisitableSteps(it)));
}

const calculatePrev = (s: Session): Session["steps"][0] | null => {
  const { steps, screen: { id } } = s;

  const stepsInOrder = flattenPrevVisitableSteps({
    id: "",
    complete: false,
    context: { entity: "" },
    current: false,
    skipped: false,
    title: "",
    visitable: false,
    visited: false,
    steps: steps,
  });
  const maybeCurrentStep = stepsInOrder.find(it => it.id === id);
  if (maybeCurrentStep === undefined) return null;

  const maybeCurrentStepIndx = stepsInOrder.indexOf(maybeCurrentStep);
  const prevStepIndx = maybeCurrentStepIndx - 1;

  return stepsInOrder[prevStepIndx] || null;

};


const host = config.BACKEND_URL as string;
export const INTERVIEW_PROVIDER = init({
  host,
  path: defaultPath,
  overrides: {
    transformRequest: [(data, headers) => {
      const endpoint = config.AUTH_ENDPOINT;
      const clientId = config.AUTH_CLIENT_ID;
      const isB2C = config.AUTH_IS_B2C === "true";

      const key = isB2C ? `oidc.user:${endpoint}:${clientId}` : `oidc.user:${endpoint}/v2.0:${clientId}`;
      const user = JSON.parse(sessionStorage.getItem(key) ?? "{}");
      if (user && headers) {
        // eslint-disable-next-line no-param-reassign
        headers.Authorization = `Bearer ${isB2C ? user.id_token : user.access_token}`;
        const tenancy = localStorage.getItem("decisively.tenant");
        // eslint-disable-next-line no-param-reassign
        headers["X-TENANCY"] = tenancy || "";
      }

      console.log("transform request", data, headers);

      return JSON.stringify(data);
    }],
  },
  fileUtils: {
    // file operations overrides go here
  },
});

export interface InterviewHook {
  provider: InterviewProvider;
  session: SessionInstance | null,
  startSession: () => Promise<SessionInstance>;
  setSession: (s: SessionInstance) => void;
  reset: () => void;
  resetState: { type: "resetting" | "idle" },
}

export const useInterview = (project: string, config: SessionConfig): InterviewHook => {

  const [session, setSession] = useState<SessionInstance | null>(null);
  const [forceUpdate, setForceUpdate] = useState(0);

  /** HACK-y way to determine when our session is being recreated on BE */
  const [resetState, setResetState] = React.useState<InterviewHook["resetState"]>({ type: "idle" });

  const getProject = useGetter(project);
  const getConfig = useGetter(config);

  const startSession = useCallback(async () => {
    // console.log('getSession', project, config);
    const res = await INTERVIEW_PROVIDER.create(getProject(), getConfig(), () => {
      setForceUpdate(s => s + 1)
    });
    // console.log('getSession result', res);
    setSession(res);
    setResetState({ type: "idle" });
    return res;
  }, []);


  const reset = () => {
    // console.log('reset session');
    setSession(null);
    INTERVIEW_PROVIDER.finish();
    setResetState({ type: "resetting" });
    startSession();
  };

  return {
    provider: INTERVIEW_PROVIDER,
    session,
    startSession,
    setSession,
    reset,
    resetState,
  };
};

// required use cases
// create - no session, no interaction
// resume - session, no interaction (gives the default back)
// resume - session, interaction (user provides the interaction)

export const useInterviewInteraction = (project: string, sessionId: string, interactionId: string) => {
  const [session, setSession] = useState<SessionInstance>();
  const [error, setError] = useState<string>();

  const getProject = useGetter(project);
  const getSessionId = useGetter(sessionId);
  const getInteractionId = useGetter(interactionId);

  const loadSession = async () => {
    try {
      // @ts-ignore
      const res = await INTERVIEW_PROVIDER.load(getProject(), getSessionId(), getInteractionId());
      setSession(res);
    } catch (e: any) {
      console.error("Failed to load session", { sessionId, interactionId, error: e });
      if (e.response?.data.message) {
        setError(e.response.data.message);
      } else {
        setError((e as Error).message ?? "Failed to load session");
      }
    }
  };

  useEffect(() => {
    if (!session) {
      loadSession();
    }
  }, []);

  return {
    provider: INTERVIEW_PROVIDER,
    session,
    setSession,
    error,
  };
};
