import React, { useCallback, useEffect, useState } from 'react';
import { useGraphVisualisation } from "../../hooks/useGraphVisualisation";
import { mappingDataToTreeItem } from "./util";
import * as DottedTreeNS from "@components/DottedTree";
import styled from 'styled-components'
import Box from "@material-ui/core/Box";
import { Label } from '@components';
import Typography from "@material-ui/core/Typography";
import { ReportValue } from "@pages/decisions/DecisionDashboard/Reports/Report/Value";
import { MenuItem, IconButton, Popover } from '@material-ui/core';
import { remove } from 'lodash';
import { useFullRelease } from '@common/hooks_useFullRelease';
import { MenudotsIcon } from '@icons';
import { useDispatch } from 'react-redux';
import { showModal } from '@imminently/imminently_platform';

import { useEditAttributeModal }from '@pages/models/release/DataModel/route/Attributes/AttributeEditModal';
import { getSCTheme } from '@common';
import { SecondaryButton } from '@components/buttons';
import NodeValue from '../AttributeInfo/NodeValue';
import {
  gatherTwoPaneItems,
} from '@components/TwoPaneDottedTree/gatherTwoPaneItems';
import {
  TwoPaneDottedTree,
} from '@components/TwoPaneDottedTree/Comp';
import { __plusOneNestingLevelId } from '@components/TwoPaneDottedTree/Right';


const LabelCompMenuItem = styled(MenuItem)`
  padding: 0.25rem 1rem;

  .MuiTypography-root {
    font-weight: bold;
  }
`;

const Wrap = styled.div`
  /* padding: 3.5rem 2rem 0 2rem; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const showOnHoverClsnm = "showOnHover-rt9861q6";

const LabelTwoPaneWrap = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-left: 0.25rem;
  margin-bottom: 0.75rem;


  .${showOnHoverClsnm} {
    visibility: hidden;
    align-self: center;
  }

  :hover .${showOnHoverClsnm} {
    visibility: visible;
  }

  ${p => (p.isSelected ? ".MuiTypography-root { font-weight: bold; }" : "")};

  ${p => (p.greyedOut ? ".MuiTypography-root { color: grey; }" : "")};
`;

/** @type { import( '@components/TwoPaneDottedTree' ).TwoPaneDottedTreeProps[ 'LabelCompForRight' ] } */
const LabelTwoPane = ({ id }) => {
  const { selectedNodeId, switchView, graph, debug } = useGraphVisualisation();
  const openEditAttributeModal = useEditAttributeModal();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const dispatch = useDispatch();
  const node = graph.node(id);

  const openMenu = useCallback((e, event) => {
    setMenuAnchor({ top: e.clientY, left: e.clientX });
  }, []);
  const closeMenu = useCallback(() => setMenuAnchor(null), []);

  const { idToItem, getIconById, onSelect, selected } = DottedTreeNS.CtxNS.useDottedTreeCtx();
  /** @type { import( '@components/TwoPaneDottedTree/Right' ).Item } */
  const item = idToItem[id];

  const focusInView = (view) => {
    switchView(view, id);
  }
  const handleEditMeta = () => openEditAttributeModal(id);

  const lowercaseV = String(item.info.value).toLowerCase();


  //  <ReportValue value={item.info.value} />

  if (!item || id === __plusOneNestingLevelId) return null;
  return (
    <LabelTwoPaneWrap
      greyedOut={lowercaseV === "unknown" || lowercaseV === "uncertain"}
      isSelected={selectedNodeId === id}
    >
      <Box display='flex' gridGap='0.5rem' alignItems='center'>
        <Box
          onClick={() => onSelect(item)}
        // style={{minWidth: item.info.nodeId ? 400 : 0}}
        >
          <Typography>{item.info.label}</Typography>
          {/* {(() => {
            if(item.id === 'global' && item.info && item.info.value === 'Entity') return null;

            return node ? <NodeValue node={node} /> : item.info.value;
          })()} */}
          {
            Boolean(debug) === false
              ? <Label color="lightgray">{item.info.value}</Label>
              : (
                node
                  ? <NodeValue node={node} />
                  : item.info.value
              )
          }
        </Box>

        <>
          <IconButton onClick={openMenu} className={showOnHoverClsnm} >
            <MenudotsIcon />
          </IconButton>
          <Popover
            anchorReference="anchorPosition"
            anchorPosition={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={closeMenu}
          >
            <Box padding='0.5rem 0'>
              <LabelCompMenuItem>
                <Typography variant='caption' onClick={() => focusInView('classic')}>View in Classic</Typography>
              </LabelCompMenuItem>
              <LabelCompMenuItem>
                <Typography variant='caption' onClick={() => focusInView('nebula')}>View in Nebula</Typography>
              </LabelCompMenuItem>
              <LabelCompMenuItem>
                <Typography variant='caption' onClick={handleEditMeta}>Edit Metadata</Typography>
              </LabelCompMenuItem>
            </Box>
          </Popover>
        </>
      </Box>
      <DottedTreeNS.LabelComponentPartsNS.CollapseToggle id={id} />

    </LabelTwoPaneWrap>
  )
}
LabelTwoPane.displayName = 'components/GraphVisualisation/components/LabelTwoPane';

/** @type { import( '@components/TwoPaneDottedTree/gatherTwoPaneItems' ).GatherTwoPaneItemsArg[ 'generalItemToLeftItem' ] } */
const generalItemToLeftItem = it => ({
  id: it.id,
  info: { label: it.info?.text },
  children: [],
});

/** @type { import( '@components/TwoPaneDottedTree/gatherTwoPaneItems' ).GatherTwoPaneItemsArg[ 'generalItemToRightTreeItem' ] } */
const generalItemToRightTreeItem = it => ({
  id: it.id,
  info: { label: String(it.info?.text), value: it.info?.value },
  children: [],
});


const Data = () => {
  const { graph, debug, setSelectedNodeId, selectedNode } = useGraphVisualisation();
  const release = useFullRelease();

  /** @type { import( '@components/TwoPaneDottedTree' ).TwoPaneDottedTreeProps[ 'useGetNodeByIdForRight' ] } */
  const useGetNodeByIdForRight = React.useCallback(id => (
    graph.node(id) || null
  ), [graph]);

  const [expanded, setExpanded] = useState(['global'])
  /** @type { import( '@components/TwoPaneDottedTree' ).TwoPaneDottedTreeProps[ 'leftSelectedId' ] } */
  const defaultLeftSelectedId = null;
  const [leftSelectedId, leftSetSelectedId] = React.useState(defaultLeftSelectedId);

  const items = mappingDataToTreeItem(graph, debug, release);
  const twoPaneTreeItems = gatherTwoPaneItems({
    item: items,
    generalItemToLeftItem,
    generalItemToRightTreeItem,
  });
  const hideLeftPane = twoPaneTreeItems.leftItems[0]
    && twoPaneTreeItems.leftItems[0].children.length === 0;

  const [triedSelectingOnlyItemInLeftItems, setTriedSelectingOnlyItemInLeftItems] = React.useState(false);
  React.useEffect(() => {
    if (triedSelectingOnlyItemInLeftItems) return;

    setTriedSelectingOnlyItemInLeftItems(true);
    const [first] = twoPaneTreeItems.leftItems;
    if (first && first.children.length === 0) {
      leftSetSelectedId(first.id);
    }
  }, [
    triedSelectingOnlyItemInLeftItems,
    setTriedSelectingOnlyItemInLeftItems,
    twoPaneTreeItems,
    leftSetSelectedId,
  ]);

  if (!release) return null;


  // const onSelect = (item) => {
  //   if (item && item.info && item.info.nodeId) {
  //     const node = graph.node(item.info.nodeId);
  //     if (node) setSelectedNodeId(item.info.nodeId);
  //   }
  // }
  const onSelectTwoPane = (item) => {
    const node = graph.node(item.id);
    if (node) setSelectedNodeId(item.id);
  }

  useEffect(() => {
    if (!selectedNode) return;
    console.log('select', selectedNode, expanded)
    // We need to check if this node is visible (it may have come from another view)
    let viewPath;
    if (selectedNode.entity === 'global') viewPath = 'global';
    else {
      if (debug) {

      } else {
        // No instances/indexes in non debug mode
        viewPath = selectedNode.entity; // This doesn't support sub entities
      }
    }
    if (expanded.indexOf(viewPath) === -1) setExpanded([...expanded, viewPath]);
  }, [selectedNode]);

  // const expandAll = () => {
  //   const all = [];
  //   const traverseTree = (child) => {
  //     if (child.children.length > 0) {
  //       all.push(child.id);
  //       child.children.forEach(c => {
  //         traverseTree(c);
  //       })
  //     }
  //   }
  //   traverseTree(items);
  //   setExpanded(all);
  // }
  // const collapseAll = () => {
  //   setExpanded(['global'])
  // }
  // /**
  //  * TODO:
  //  *  - Add context menu
  //  *  - Add focus in other types
  //  */
  // const toggleExpanded = (id) => {
  //   const newExpanded = [...expanded];

  //   if (newExpanded.indexOf(id) > -1) remove(newExpanded, (i) => i === id);
  //   else newExpanded.push(id);
  //   setExpanded(newExpanded);
  // }
  return (
    <Wrap>
      {/* <Box display='flex' gridGap='1rem' padding='1rem 0rem' alignItems='center'>

        <CollapseControlsWrap>
          <SecondaryButton size='small' onClick={expandAll}>Expand All</SecondaryButton>
          <SecondaryButton size='small' onClick={collapseAll}>Collapse All</SecondaryButton>
        </CollapseControlsWrap>
      </Box> */}
      {/* <DottedTreeNS._
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        items={[items]}
        LabelComp={Label}
        onSelect={onSelect}
      /> */}
      <TwoPaneDottedTree
        leftItems={twoPaneTreeItems.leftItems}
        idToRightItems={twoPaneTreeItems.idToRightItems}
        useGetNodeByIdForRight={useGetNodeByIdForRight}
        LabelCompForRight={LabelTwoPane}
        onSelectRight={onSelectTwoPane}
        leftExpanded={expanded}
        leftSetExpanded={setExpanded}
        leftSelectedId={leftSelectedId}
        leftSetSelectedId={leftSetSelectedId}
        hideLeftPane={hideLeftPane}
      />
    </Wrap>
  )
}
Data.displayName = 'components/GraphVisualisation/components/Data';

export default Data;
