import React from "react";
import {
  DropdownMenu,
  Menu,
} from "@components";
import { useGraphVisualisation } from "../../../hooks/useGraphVisualisation";
import { scrollableMixin } from "@common/scrollbar";
import styled from "styled-components";

interface DepthSelectorProps {
}

const StyledMenu = styled(Menu)`
  .menu-items {
    ${scrollableMixin};

    li { flex-shrink: 0; }
  }
`;


const DepthSelector = (props: DepthSelectorProps) => {

  const {
    restrictViewToDepth,
    maxTreeDepthFromRestrictedViewRootPath: maxDepthFromRestrictedViewRootPath,  //!! we're traveling out in what's essentially a tree structure, not a graph, which is why we use this depth
    // maxDepthFromRestrictedViewRootPath, // if we were drawing a sub-graph, this would be the depth you'd want
    restrictedViewDepth,
  } = useGraphVisualisation();

  const options = maxDepthFromRestrictedViewRootPath ? Array.from(Array(maxDepthFromRestrictedViewRootPath).keys()).map((i) => ({
    name: (++i).toString(),
    value: i,
  })) : [];
  options.unshift({ name: "All", value: maxDepthFromRestrictedViewRootPath || null } as any);

  const isDisabled = !(maxDepthFromRestrictedViewRootPath !== null && maxDepthFromRestrictedViewRootPath >= 0) || maxDepthFromRestrictedViewRootPath === 0;

  const getControlLabel = () => {

    if (restrictedViewDepth === null || restrictedViewDepth === 0) {
      return "All";
    }

    return (`Max Depth ${restrictedViewDepth}`);
  }

  // --

  return(
    <DropdownMenu
      style={{ minWidth: "11rem", backgroundColor: "white" }}
      value={getControlLabel()}
      disabled={isDisabled}
    >
      {({ close }) => {
        const onClick = item => {
          close();
          restrictViewToDepth(item.value);
        };
        return (
          <StyledMenu
            {...{
              // search: "Search graph types...",
              items: options,
              width: "6rem",
              // height: "14rem",
              onOptionClick: onClick,
            } as any}
          />
        );
      }}
    </DropdownMenu>
  );
};

export default (DepthSelector);