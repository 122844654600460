import styled from "styled-components";
import Typography from "@material-ui/core/Typography";


export const DataLabel = styled(Typography)`
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.2;
  color: #A3A3A3;
`;
export const DataValue = styled(Typography)`
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
`;
