import { useGraphVisualisation } from "@components/GraphVisualisation/hooks/useGraphVisualisation";
import { getNodeDisplayData, NodeDisplayType } from "./attributes";
import { getNameFromFileAttributeRef } from '@decisively-io/interview-sdk/dist/types/core';
import { Tooltip } from "@components";
import styled from "styled-components";
import { DownloadFileButton } from "@components/GraphVisualisation/components/AttributeInfo/DownloadFileButton";
import { scrollableMixin } from "@common/scrollbar";
import React from "react";
import { GraphNode } from '@packages/commons';

const LabelSize = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
} as const;

type LabelSize = typeof LabelSize[keyof typeof LabelSize];

const Label = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  line-height: 1.5;
  font-weight: 600;
  padding: 0 0.5rem;
  white-space: nowrap;
  /* text-transform: capitalize; */
  flex-shrink: 1;
  cursor: default;

  &[data-tooltip="true"] {
    text-decoration: underline dashed;
  }

  &[data-size="sm"] {
    font-size: 0.5rem;
    height: 1rem;
  }

  &[data-size="md"] {
    font-size: 0.75rem;
    height: 1.5rem;
  }

  &[data-size="lg"] {
    font-size: 1rem;
    height: 2rem;
  }
`;

type LabelProps = {
  tooltip?: boolean;
  size?: LabelSize;
  color: string;
  backgroundColor: string;
} & React.HTMLAttributes<HTMLSpanElement>;

const StyledLabel = React.forwardRef<HTMLSpanElement, LabelProps>((props, ref) => {
  const { size = 'md', tooltip, color, backgroundColor, style, ...rest } = props;
  return <Label {...rest} data-tooltip={tooltip} data-size={size} ref={ref} style={{ ...style, color, backgroundColor }} />;
});

const StyledDownloadFileButton = styled(DownloadFileButton)`
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.375rem;
  margin-left: 0.5rem;
`;

/**
 * we need this to proerly style file values in right-hand sidebar, \
 * because as opposed to rule graph nodes, where it takes one whole \
 * line and other elements have somespacig, in right-hand sidebar we \
 * have 'Value' heading and a possible 'input' chip, which are really\
 * close one to another
 */
export const fileRefsWrapClsnm = 'file_refs_wrap_r7y3Ylr';
const FileRefsWrap = styled.div`
  display: flex;
  gap: 0.5rem;
  overflow: auto;
  max-width: 100%;

  ${scrollableMixin};
  &::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
  }
`;

export type AttributeValueProps = {
  node: GraphNode;
  size?: LabelSize;
  showType?: boolean;
  hideTemporal?: boolean;
  labelProps?: React.HTMLAttributes<HTMLSpanElement>;
};

export const AttributeValue = ({ node, size = "md", showType = false, hideTemporal = false, labelProps = {} }: AttributeValueProps) => {
  const { temporalSlice } = useGraphVisualisation();
  const { value, type, display, tooltip, color, backgroundColor } = getNodeDisplayData(node, temporalSlice);

  const renderFileLabel = (ref, index = 0) => {
    const match = ref.match(/^data:id=(.*);base64,([-A-Za-z0-9+/=]*)$/);
    const nameRaw = match ? getNameFromFileAttributeRef(ref) : value;
    const name = nameRaw.length <= 16 ? nameRaw : nameRaw.slice(0, 16) + '…';
    return (
      <Tooltip title={nameRaw || ''}>
        <StyledLabel size={size} color={color} backgroundColor={backgroundColor} style={{ marginLeft: 5, marginRight: 5 }} {...labelProps} key={index} >
          {String(name)}
          <StyledDownloadFileButton reference={ref} hideName={true} />
        </StyledLabel>
      </Tooltip>
    )
  };

  if (value?.fileRefs) {
    return (
      <FileRefsWrap className={fileRefsWrapClsnm}>
        {value.fileRefs.map(renderFileLabel)}
      </FileRefsWrap>
    );
  }

  if (type === NodeDisplayType.TEMPORAL) {
    if (hideTemporal) {
      return <StyledLabel size={size} color={color} backgroundColor={backgroundColor} {...labelProps}>Temporal</StyledLabel>
    }
    // is react node
    if (React.isValidElement(display)) {
      return display;
    }
    // else fall through
  }

  return (
    <>
      <Tooltip title={tooltip}>
        <StyledLabel size={size} color={color} backgroundColor={backgroundColor} tooltip={tooltip.length > 0} {...labelProps}>
          {display}
        </StyledLabel>
      </Tooltip>
      {showType && typeof node.input !== 'undefined' && <StyledLabel style={{ marginLeft: "0.5rem" }} color="black" backgroundColor="lightgray">Input</StyledLabel>}
    </>
  );
};